import React, { useState } from 'react';
import { IoChevronBackCircleOutline } from 'react-icons/io5';
import { CgNotes } from 'react-icons/cg';
import { IoSearch } from 'react-icons/io5';
import LanguagePicker, {
  LanguagesSpace,
} from '../LanguagePicker/LanguagePicker';
import './MenuAppBar.css';
import Form from 'react-bootstrap/Form';
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Switch } from '../../reusableComponents/Switch/Switch';
import { TextInput } from '../../reusableComponents/TextInput/TextInput';
import {
  ChevronLeft,
  ChevronLeftOutlined,
  ListAltRounded,
  Search,
} from '@mui/icons-material';

import logoPiattoQR from '../../../src/res/PiattoQR-LOGO.png';

type AppBarProps = {
  businessName: string;
  onChangeCanSelect: (state: boolean) => void;
  canOrder: boolean;
  goBack?: boolean;
  writeOrder?: boolean;
  language: string;
  languagesToRender: LanguagesSpace[];
  updateLanguage: (v: LanguagesSpace) => void;
  position: 'fixed' | 'absolute' | 'sticky' | 'static' | 'relative' | undefined;
};

const MenuAppBar = ({
  businessName,
  onChangeCanSelect,
  canOrder,
  goBack,
  writeOrder,
  language,
  languagesToRender,
  updateLanguage,
  position,
}: AppBarProps) => {
  const navigate = useNavigate();

  return (
    <AppBar
      position={position}
      variant={position == 'absolute' ? 'elevation' : 'outlined'}
      color="transparent"
      sx={{
        boxSizing: 'border-box',
        backgroundColor: position == 'absolute' ? 'rgb(0,0,0,0.0)' : 'white',
      }}
      elevation={0}
    >
      <Container maxWidth="xl">
        <Toolbar
          sx={{
            paddingLeft: '0px',
            paddingRight: '0px',
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'flex', sm: 'flex' },

              verticalAlign: 'center',
            }}
          >
            <Box sx={{ display: 'flex' }}>
              {goBack ? (
                <IconButton
                  sx={{
                    width: '45px',
                    height: '45px',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    marginRight: '10px',
                  }}
                  onClick={() => {
                    navigate('..', { relative: 'path' });
                  }}
                >
                  <ChevronLeftOutlined fontSize="large" />
                </IconButton>
              ) : null}

              {goBack ? (
                <Typography
                  fontSize={{
                    xs: 20,
                    md: 25,
                  }}
                  fontWeight={600}
                  lineHeight={'60px'}
                >
                  {businessName}
                </Typography>
              ) : null}

              {/*<img src={logoPiattoQR} width={'180px'} />*/}

              {writeOrder ? (
                true !== true ? (
                  <Switch
                    color="secondary"
                    onChange={(el) => {
                      onChangeCanSelect(el.target.checked);
                    }}
                    checked={canOrder}
                  />
                ) : null
              ) : null}

              {writeOrder ? (
                true !== true ? (
                  <IconButton>
                    <ListAltRounded fontSize="large" />
                  </IconButton>
                ) : null
              ) : null}
            </Box>
          </Box>

          <div>
            <LanguagePicker
              languageValue={language as LanguagesSpace}
              languagesToRender={languagesToRender as LanguagesSpace[]}
              updateLanguage={updateLanguage}
            />
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default MenuAppBar;

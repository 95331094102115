import React, { useEffect, useState } from 'react';
import { LoadingFeedback } from '../../Loaders/loading_feedback/LoadingFeedback';

import firebase from '../../../../services/firebase';
import { toast } from 'react-toastify';
import { Modal } from '../../../../reusableComponents/Modal/Modal';
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { TextInput } from '../../../../reusableComponents/TextInput/TextInput';
import { Button } from '../../../../reusableComponents/Button/Button';
import { Add, Clear } from '@mui/icons-material';
import { loadingAtom } from '../../../../state/loading';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

interface ConfigurationProps {
  businessData: any;
  close: any;
  userData: any;
}

export const Configuration = ({ businessData, close }: ConfigurationProps) => {
  const { t } = useTranslation();
  const [emailInput, setEmailInput] = useState('');
  const [feedback, setFeedback] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');

  const [isLoading, setIsLoading] = useRecoilState(loadingAtom);

  return (
    <Modal
      open={true}
      onClose={() => {
        close();
      }}
    >
      <>
        <Typography variant="h4" textAlign={'center'}>
          {t('configurations')}
        </Typography>

        <Typography variant="h5" mt={3}>
          {t('emails')}
        </Typography>

        <TextInput
          fullWidth
          value={emailInput}
          onChange={(e) => {
            setEmailInput(e.currentTarget.value);
          }}
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={() => {
                  if (
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                      emailInput
                    )
                  ) {
                    setFeedback(t('adding_email'));

                    firebase
                      .firestore()
                      .collection('business')
                      .doc(businessData.id)
                      .update({
                        contactEmails:
                          firebase.firestore.FieldValue.arrayUnion(emailInput),
                        update: new Date(),
                      })
                      .then(() => {
                        setFeedback('');
                        setEmailInput('');

                        toast.info(t('email_added'));
                      })
                      .catch((e: any) => {
                        console.log(e);
                        setFeedback('');

                        toast.error(t('something_went_wrong'));
                      });
                  } else {
                    toast.error(t('invalid_email'));
                  }
                }}
              >
                <Add />
              </IconButton>
            ),
          }}
        />

        <Typography variant="body1" mt={2}>
          {t('the_orders_will_be_sent_to_these_emails')}
        </Typography>

        <List>
          {businessData.contactEmails !== undefined ? (
            businessData.contactEmails.length < 1 ? (
              <p className="mb-0">{t('no_emails_added')}</p>
            ) : (
              businessData.contactEmails.map((e: any, i: number) => {
                return (
                  <ListItem key={i}>
                    <ListItemButton>
                      <ListItemText>{e}</ListItemText>

                      <ListItemIcon>
                        <Clear />
                      </ListItemIcon>
                    </ListItemButton>

                    <Button
                      className="btn btn-clear"
                      endIcon={<Clear />}
                      onClick={() => {
                        setFeedback(t('deleting_email'));

                        let emails = [...businessData.contactEmails];

                        emails.splice(i, 1);

                        firebase
                          .firestore()
                          .collection('business')
                          .doc(businessData.id)
                          .update({
                            contactEmails: emails,
                            updated: new Date(),
                          })
                          .then(() => {
                            setFeedback('');

                            toast.info(t('email_removed'));
                          })
                          .catch((e: any) => {
                            console.log(e);
                            toast.error(t('something_went_wrong'));

                            setFeedback('');
                          });
                      }}
                    ></Button>
                  </ListItem>
                );
              })
            )
          ) : (
            <p className="mb-0">No emails added</p>
          )}
        </List>

        <Typography variant="h5" mt={3}>
         {t('contacts')}
        </Typography>

        <Stack spacing={1} mt={2}>
          <TextInput
            label="Name"
            value={name}
            onChange={(el) => {
              setName(el.currentTarget.value);
            }}
          />

          <TextInput
            label="Phone"
            value={phone}
            onChange={(el) => {
              setPhone(el.currentTarget.value);
            }}
          />

          <Box>
            <Button
              fullWidth
              sx={{
                mt: 2,
              }}
              variant="outlined"
              endIcon={<Add />}
              onClick={() => {
                setIsLoading(true);

                firebase
                  .firestore()
                  .collection('business')
                  .doc(businessData.id)
                  .update({
                    contacts: firebase.firestore.FieldValue.arrayUnion({
                      name: name,
                      phone: phone,
                    }),
                    update: new Date(),
                  })
                  .then(() => {
                    setIsLoading(false);
                    setName('');
                    setPhone('');

                    toast.info(t('email_added'));
                  })
                  .catch((e: any) => {
                    console.log(e);
                    toast.error(t('something_went_wrong'));

                    setIsLoading(false);
                  });
              }}
            >
              {t('add')}
            </Button>
          </Box>
        </Stack>

        <div className="flex-left">
          <button
            className="btn btn-custom-2"
            onClick={() => {
              setFeedback(t('adding_contact'));

              firebase
                .firestore()
                .collection('business')
                .doc(businessData.id)
                .update({
                  contacts: firebase.firestore.FieldValue.arrayUnion({
                    name: name,
                    phone: phone,
                  }),
                  update: new Date(),
                })
                .then(() => {
                  setFeedback('');
                  setName('');
                  setPhone('');

                  toast.info(t('email_added'));
                })
                .catch((e: any) => {
                  console.log(e);
                  toast.error(t('something_went_wrong'));

                  setFeedback('');
                });
            }}
          >
            <span className="mr-2">Add</span>
            <i className="material-icons align-middle">add</i>
          </button>
        </div>

        <div className="mt-3 flex-left">
          {businessData.contacts !== undefined ? (
            businessData.contacts.length < 1 ? (
              <p className="mb-0">{t('no_contacts')}</p>
            ) : (
              businessData.contacts.map((e: any, i: number) => {
                return (
                  <div className="contact-card m-2" key={i}>
                    <p className="mb-0 name">{e.name}</p>

                    <p className="mt-2 phone mb-0">{e.phone}</p>

                    <button
                      className="btn btn-clear"
                      onClick={() => {
                        setFeedback(t('removing_contact'));

                        let contacts = [...businessData.contacts];

                        contacts.splice(i, 1);

                        firebase
                          .firestore()
                          .collection('business')
                          .doc(businessData.id)
                          .update({
                            contacts: contacts,
                            updated: new Date(),
                          })
                          .then(() => {
                            setFeedback('');

                            toast.info(t('contact_removed'));
                          })
                          .catch((e: any) => {
                            console.log(e);
                            toast.error(t('something_went_wrong'));

                            setFeedback('');
                          });
                      }}
                    >
                      <i className="material-icons align-middle">clear</i>
                    </button>
                  </div>
                );
              })
            )
          ) : (
            <p className="mb-0">{t('no_contacts')}</p>
          )}
        </div>
      </>
    </Modal>
  );
};

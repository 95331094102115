import React from 'react';
import { Stack } from '@mui/material';
import { ScheduleItem } from './ScheduleItem/ScheduleItem';

interface ScheduleProps {
  schedule: Array<ScheduleItem>;
  spacing?: number;
}

type ValuePiece = Date | string | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

interface ScheduleItem {
  dayName: string;
  onChange?: ((value: Value) => void) | undefined;
  value?: Value;
  check?: boolean;
  onSwitchChange?: ((v: boolean) => void) | undefined;
}

export const Schedule = ({ spacing = 1, schedule }: ScheduleProps) => {
  return (
    <Stack spacing={spacing}>
      {schedule.map((e, i: number) => {
        return (
          <ScheduleItem
            dayName={e.dayName}
            key={i}
            onChange={e.onChange}
            value={e.value as string}
            check={e.check}
            onSwitchChange={e.onSwitchChange}
          />
        );
      })}
    </Stack>
  );
};

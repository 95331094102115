export const currencyList = [
  { value: 'USD', text: 'USD - US Dollar', symbol: '$' },
  { value: 'EUR', text: 'EUR - Euro', symbol: '€' },
  { value: 'GBP', text: 'GBP - British Pound Sterling', symbol: '£' },
  { value: 'INR', text: 'INR - Indian Rupee', symbol: '₹' },
  { value: 'AUD', text: 'AUD - Australian Dollar', symbol: '$' },
  { value: 'CAD', text: 'CAD - Canadian Dollar', symbol: '$' },
  { value: 'SGD', text: 'SGD - Singapore Dollar', symbol: '$' },
  { value: 'CHF', text: 'CHF - Swiss Franc', symbol: 'CHf' },
  { value: 'MYR', text: 'MYR - Malaysian Ringgit', symbol: 'RM' },
  { value: 'JPY', text: 'JPY - Japanese Yen', symbol: '¥' },
  { value: 'CNY', text: 'CNY - Chinese Yuan', symbol: '¥' },
  { value: 'NZD', text: 'NZD - New Zealand Dollar', symbol: '$' },
  { value: 'ZAR', text: 'ZAR - South African Rand', symbol: 'R' },
  { value: 'SEK', text: 'SEK - Swedish Krona', symbol: 'kr' },
  { value: 'NOK', text: 'NOK - Norwegian Krone', symbol: 'kr' },
  { value: 'MXN', text: 'MXN - Mexican Peso', symbol: '$' },
  { value: 'BRL', text: 'BRL - Brazilian Real', symbol: 'R$' },
  { value: 'RUB', text: 'RUB - Russian Ruble', symbol: '₽' },
  { value: 'HKD', text: 'HKD - Hong Kong Dollar', symbol: '$' },
  { value: 'ISK', text: 'ISK - Icelandic Króna', symbol: 'kr' },
  { value: 'PHP', text: 'PHP - Philippine Peso', symbol: '₱' },
  { value: 'PLN', text: 'PLN - Polish Zloty', symbol: 'zł' },
  { value: 'THB', text: 'THB - Thai Baht', symbol: '฿' },
  { value: 'VEF', text: 'VEF - Venezuelan Bolivar', symbol: 'Bs' },
];

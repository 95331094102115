import React from 'react';
import { useRecoilState } from 'recoil';
import { adminAtom } from '../../../../state/adminAtom';
import { Box, Typography } from '@mui/material';
import { WorkingHour } from '../../../../models/business';
import { Schedule as ScheduleComponent } from '../../Pieces/Schedule/Schedule';
import { useTranslation } from 'react-i18next';

export const Schedule = () => {
  const { t } = useTranslation();
  const [adminState, setAdminState] = useRecoilState(adminAtom);

  const translate = (day: string | number) => {
    if (day == '0') {
      return t('monday');
    }

    if (day == '1') {
      return t('tuesday');
    }

    if (day == '2') {
      return t('Wednesday');
    }

    if (day == '3') {
      return t('Thursday');
    }

    if (day == '4') {
      return t('Friday');
    }

    if (day == '5') {
      return t('Saturday');
    }

    if (day == '6') {
      return t('Sunday');
    }
  };
  return (
    <Box
      sx={{
        pt: '64px',
      }}
    >
      <Typography textAlign={'center'} variant="h4">
        {t('schedule')}
      </Typography>

      <Box mt={5}>
        {adminState.businessData?.workingHours !== undefined &&
        adminState.businessData?.workingHours !== null ? (
          <ScheduleComponent
            spacing={3}
            schedule={
              adminState.businessData?.workingHours.map(
                (e: WorkingHour, i: number) => {
                  return {
                    dayName: translate(String(e.day)),
                    onChange: (v: any) => {
                      let base = [
                        ...(JSON.parse(JSON.stringify(adminState.businessData))
                          ?.workingHours as WorkingHour[]),
                      ];
                      base[i].startHour = Number(String(v[0]).split(':')[0]);
                      base[i].startMinute = Number(String(v[0]).split(':')[1]);

                      base[i].endHour = Number(String(v[1]).split(':')[0]);
                      base[i].endMinute = Number(String(v[1]).split(':')[1]);

                      setAdminState((state) => ({
                        ...state,
                        businessData: {
                          ...state.businessData,
                          workingHours: base,
                        } as any,
                        toUpdate: true,
                      }));
                    },
                    value: [
                      `${e.startHour}:${e.startMinute}`,
                      `${e.endHour}:${e.endMinute}`,
                    ],
                    check: e.open,
                    onSwitchChange: (v: boolean) => {
                      let base = [
                        ...(JSON.parse(JSON.stringify(adminState.businessData))
                          ?.workingHours as WorkingHour[]),
                      ];
                      base[i].open = v;

                      setAdminState((state) => ({
                        ...state,
                        businessData: {
                          ...state.businessData,
                          workingHours: base,
                        } as any,
                        toUpdate: true,
                      }));
                    },
                  };
                }
              ) as any
            }
          />
        ) : (
          []
        )}
      </Box>
    </Box>
  );
};

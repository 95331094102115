import * as React from 'react';
import { Draggable } from '@hello-pangea/dnd';

import { Item } from './typings';
import {
  Avatar,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import { Inbox } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  draggingListItem: {
    background: 'rgb(235,235,235)',
  },
}) as any;

export type DraggableListItemProps = {
  id: string;
  index: number;
  key: string;
  children: React.ReactNode;
};

const DraggableListItem = ({
  id,
  index,
  key,
  children,
}: DraggableListItemProps) => {
  const classes = useStyles();

  return (
    <Draggable draggableId={id} index={index} key={key}>
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={snapshot.isDragging ? classes.draggingListItem : ''}
          sx={{
            borderRadius: 'var(--border-radius)',
            overflow: 'hidden',
          }}
        >
          {children}
        </Box>
      )}
    </Draggable>
  );
};

export default DraggableListItem;

import './App.css';
import './styles/App.scss';

import React, { useCallback, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { Dialog } from './reusableComponents/Dialog/Dialog';

import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';
import { Register } from './pages/Admin/Register/Register';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import { extendedTheme } from './providers/customTheme';

import { Backdrop, CircularProgress } from '@mui/material';
import { loadingAtom } from './state/loading';
import { ToastContainer } from 'react-toastify';
import { dialogAtom } from './state/dialogAtom';
import { RestaurantRenderer } from './pages/RestaurantRenderer/RestaurantRenderer';
import { Admin } from './pages/Admin/Admin';
import { SuccessSubscription } from './pages/Admin/Register/SucessSubscription/SuccessSubscription';
import { FailedCheckout } from './pages/Admin/Register/FailedCheckout/FailedCheckout';

function App() {
  let _businessData: any;

  const [isLoading, setIsLoading] = useRecoilState(loadingAtom);

  const [dialogState, setDialogState] = useRecoilState(dialogAtom);

  useEffect(() => {
    console.log(process.env);
  }, []);

  const getRoutes = useCallback(() => {
    return createBrowserRouter([
      {
        path: '/',
        element: <Navigate to={'/admin'} />,
      },
      {
        path: '/admin',
        element: <Admin />,
      },
      {
        path: '/admin/*',
        element: <Admin />,
      },

      {
        path: ':restaurantId',
        element: <RestaurantRenderer />,
      },
      {
        path: ':restaurantId/*',
        element: <RestaurantRenderer />,
      },
      {
        path: '/register',
        element: <Register />,
      },
      {
        path: '/success',
        element: <SuccessSubscription />,
      },
      {
        path: '/cancel',
        element: <FailedCheckout />,
      },
    ]);
  }, []);

  const routes = getRoutes();

  return (
    <div className="App">
      <CssVarsProvider theme={extendedTheme}>
        <RouterProvider router={routes} />
        <Dialog {...dialogState} />

        <Backdrop
          sx={{
            color: '#fff',
            zIndex: (theme: any) => theme.zIndex.drawer + 99999,
          }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <ToastContainer />
      </CssVarsProvider>
    </div>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import MenuAppBar from '../../components/MenuAppBar/MenuAppBar';
import './Menu.css';
import { CategorySlider } from '../../components/CategorySlider/CategorySlider';
import { CategoryHeader } from '../../components/CategoryHeader/CategoryHeader';
import { Product } from '../../components/Product/Product';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { LanguageGeneric, Languages } from '../../models/languages';
import { chooseMultilanguageValue } from '../../services/multiLanguague';
import { BO } from 'country-flag-icons/react/3x2';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { menuAtom } from '../../state/menuAtom';
import { useParams } from 'react-router-dom';
import { LanguagesSpace } from '../../components/LanguagePicker/LanguagePicker';
import { PDFContainer } from '../Admin/Modals/PDFVisualizer/PDFContainer';

interface MenuProps {
  businessName?: string;
  products?: any[];
  sections: LanguageGeneric<string>[];
  categories: (LanguageGeneric<string> & { id: string })[];
}

export const Menu = () => {
  const [canOrder, setCanOrder] = useState(false);

  const [menuState, setMenuState] = useRecoilState(menuAtom);

  const navigate = useNavigate();

  const params = useParams();

  const onChangeCanOrder = (value: boolean) => {
    setCanOrder(value);
  };
  return (
    <>
      <MenuAppBar
        position="sticky"
        businessName={menuState.businessData?.businessName!}
        canOrder={canOrder}
        onChangeCanSelect={onChangeCanOrder}
        goBack
        writeOrder
        language={
          menuState.businessData?.defaultLanguage ||
          (menuState.language as LanguagesSpace)
        }
        updateLanguage={(v) => setMenuState((s) => ({ ...s, language: v }))}
        languagesToRender={
          Object.keys(menuState.businessData?.languages as any).filter(
            (v: any) =>
              (menuState.businessData?.languages as Languages)[
                v as LanguagesSpace
              ].state === true
          ) as LanguagesSpace[]
        }
      />
      {menuState.menus?.filter((v) => v.id === params.sectionId)[0].type ===
      'native' ? (
        <Box
          p={2}
          sx={{
            maxWidth: '500px',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <Stack direction={'row'} justifyContent={'center'} mt={1}>
            <CategorySlider
              categories={(
                menuState.menus?.filter((v) => v.id === params.sectionId)[0]
                  .sections as any[]
              ).filter((s) => {
                if (
                  (
                    menuState.menus?.filter((v) => v.id === params.sectionId)[0]
                      .categories as any[]
                  ).filter((v) => v.linkedSection == s.id).length > 0
                ) {
                  return true;
                } else {
                  return false;
                }
              })}
            />
          </Stack>

          {(
            menuState.menus?.filter((v) => v.id === params.sectionId)[0]
              .sections as any[]
          ).map((section: any, i) => {
            return (
              menuState.menus?.filter((v) => v.id === params.sectionId)[0]
                .categories as any[]
            ).filter((v) => v.linkedSection == section.id).length > 0 ? (
              <>
                <Stack direction={'column'} mt={0} key={i} id={section.id}>
                  <Divider>
                    <Typography
                      variant="h5"
                      sx={{
                        px: '20px',
                      }}
                      textAlign={'center'}
                      fontWeight={'bold'}
                    >
                      {chooseMultilanguageValue(
                        section,
                        menuState.language as LanguagesSpace,
                        menuState.language as LanguagesSpace
                      )}
                    </Typography>
                  </Divider>
                  {(
                    menuState.menus?.filter((v) => v.id === params.sectionId)[0]
                      .categories as any[]
                  )
                    .filter((v) => v.linkedSection == section.id)
                    .map((e, i) => {
                      return (
                        (
                          menuState.menus?.filter(
                            (v) => v.id === params.sectionId
                          )[0] as any
                        ).products as any[]
                      )
                        ?.filter((value) => value.category === (e as any).id)
                        .filter((v) => v.available === true).length > 0 ? (
                        <Stack
                          mt={3}
                          spacing={2}
                          key={i}
                          sx={{
                            width: '100%',
                          }}
                          direction={'column'}
                        >
                          {' '}
                          {/*<Typography
                            variant="h5"
                            sx={{
                              px: '20px',
                              fontWeight: 'bold',
                            }}
                          >
                            {chooseMultilanguageValue(
                              e,
                              menuState.language as LanguagesSpace,
                              menuState.language as LanguagesSpace
                            )}
                          </Typography>*/}
                          {(
                            (
                              menuState.menus?.filter(
                                (v) => v.id === params.sectionId
                              )[0] as any
                            ).products as any[]
                          )
                            ?.filter(
                              (value) => value.category === (e as any).id
                            )
                            .map((e2, i2) => {
                              return e2.available ? (
                                <Product
                                  canOrder={canOrder}
                                  productName={e2.productName}
                                  price={e2.price}
                                  image={e2.imageUrl}
                                  productDescription={e2.productDescription}
                                  key={i2}
                                  allergens={e2.allergens}
                                  tags={e2.tags}
                                  click={() => {
                                    setMenuState((state) => ({
                                      ...state,
                                      productData: e2,
                                    }));
                                    navigate(e2.id);
                                  }}
                                />
                              ) : null;
                            })}
                        </Stack>
                      ) : null;
                    })}
                </Stack>
                {/* <Divider
                  sx={{
                    mt: 3,
                    backgroundColor: 'lightgrey',
                  }}
                /> */}
              </>
            ) : null;
          })}
        </Box>
      ) : (
        <Box
          sx={{
            maxWidth: '500px',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <PDFContainer
            url={chooseMultilanguageValue(
              menuState.menus?.filter((v) => v.id === params.sectionId)[0]
                .sourceUrl as LanguageGeneric<string>,
              menuState.language as LanguagesSpace,
              menuState.language as LanguagesSpace
            )}
          />
        </Box>
      )}
    </>
  );
};

import { LanguageGeneric } from '../models/languages';

export function distributeValue<T>(value: T) {
  let obj: LanguageGeneric<T> = {
    'ES-C': value,
    'ES-V': value,
    DE: value,
    EN: value,
    ES: value,
    IT: value,
  };

  return obj;
}

export function updateMultilanguageObj<T>(
  obj: LanguageGeneric<T>,
  value: T,
  lang: keyof LanguageGeneric<T>
) {
  return {
    ...obj,
    [lang]: value,
  };
}

export function setMultilanguageValue<T>(
  value: T,
  setter: React.Dispatch<LanguageGeneric<T>>,
  lang: keyof LanguageGeneric<T> | 'all'
) {
  setter(((prevState: any) => {
    if (lang === 'all') {
      return {
        ...prevState,
        'ES-C': value,
        'ES-V': value,
        DE: value,
        EN: value,
        ES: value,
        IT: value,
      };
    } else {
      return {
        ...prevState,
        [lang]: value,
      };
    }
  }) as unknown as LanguageGeneric<T>);
}

export function getMultilanguageValue<T>(
  field: LanguageGeneric<T>,
  lang: keyof LanguageGeneric<T>
) {
  return field[lang];
}

export function chooseMultilanguageValue<T>(
  obj: LanguageGeneric<T>,
  intendedLang: keyof LanguageGeneric<T>,
  defaultLang: keyof LanguageGeneric<T>
) {
  if (
    !(
      obj[intendedLang] === '' ||
      obj[intendedLang] === undefined ||
      obj[intendedLang] === null
    )
  ) {
    return obj[intendedLang];
  }

  if (
    !(
      obj[defaultLang] === '' ||
      obj[defaultLang] === undefined ||
      obj[defaultLang] === null
    )
  ) {
    return obj[intendedLang];
  }

  let result: any = '';
  Object.keys(obj).forEach((key: string) => {
    if (
      !(
        obj[key as keyof LanguageGeneric<T>] === '' ||
        obj[key as keyof LanguageGeneric<T>] === undefined ||
        obj[key as keyof LanguageGeneric<T>] === null ||
        key === 'id' ||
        key === 'linkedSection'
      )
    ) {
      result = obj[key as keyof LanguageGeneric<T>];
    }
  });

  return result;
}

export function checkIfEmptyMultilanguageValue(obj: LanguageGeneric<string>) {
  return (
    Object.keys(obj).filter(
      (key: string) => obj[key as keyof LanguageGeneric<string>] !== ''
    ).length === 0
  );
}

export function checkIfNullMultilanguageValue(obj: LanguageGeneric<any>) {
  return (
    Object.keys(obj).filter(
      (key: string) => obj[key as keyof LanguageGeneric<any>] !== null
    ).length === 0
  );
}

export function returnArrayOfValidMulilanguageValues(
  obj: LanguageGeneric<string>
) {
  let arr: Array<string> = [];

  Object.keys(obj).forEach((key: string) => {
    if (obj[key as keyof LanguageGeneric<string>] !== '') {
      arr.push(obj[key as keyof LanguageGeneric<string>]);
    }
  });

  return arr;
}

export function numberWithCommas(x: any) {
  if (x !== undefined) {
    return addDecimals(x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
  } else {
    return '';
  }
}

export function addDecimals(n: number) {
  let num = String(n);

  if (num.includes('.')) {
    let index = num.indexOf('.');

    let parcialNum = num.slice(index + 1, num.length);

    if (parcialNum.length < 2) {
      num = num + '0';
    }
  } else {
    num = num + '.00';
  }

  return num;
}

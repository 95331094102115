import * as Yup from 'yup';
import { Languages } from './languages';

export interface BusinessCreate {
  businessName: string;
  name: string;
  lastName: string;
  phone: string;
  email: string;
  password: string;
  businessUrl: string;
  freeTrial?: boolean;
  plan?: string;
}

export const businessFormFields = {
  businessName: Yup.string().required('Business name is required'),
  type: Yup.string().required('Type is required'),
  branches: Yup.number()
    .required('Number of branches is required')
    .min(1, 'At least one branch is required'),
  name: Yup.string()
    .required('Name is required')
    .min(2, 'Name must be at least 2 characters'),
  lastName: Yup.string()
    .required('Last name is required')
    .min(2, 'Last name must be at least 2 characters'),
  phone: Yup.string()
    .required('Phone number is required')
    .matches(/^[0-9]+$/, 'Phone number must be numeric'),
  email: Yup.string()
    .required('Email is required')
    .email('Invalid email format'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters'),
  lat: Yup.number().required('Latitude is required'),
  lng: Yup.number().required('Longitude is required'),
  address: Yup.string().required('Address is required'),
};

export interface WorkingHour {
  open: boolean;
  startHour: number;
  startMinute: number;
  endHour: number;
  endMinute: number;
  day: number;
}

export interface BusinessData {
  temporallyClosed: boolean;
  priority: number;
  businessAddress: { lat: number; lng: number; name: string }; // Assuming the type returned by doc.data().businessAddress is string
  businessCategory: string; // Assuming the type returned by doc.data().businessCategory is string
  email: string;
  businessCoverImageUrl: string;
  businessImageUrl: string;
  businessName: string; // Assuming the type returned by doc.data().businessName is string
  businessDescription: string;
  changes: any; // Replace 'any' with a more specific type if possible
  created: Date;
  phone: string; // Assuming the type returned by doc.data().ownerPhoneNumber is string
  keywords: string[]; // Assuming KeywordGeneration.generateKeywords returns an array of strings
  owner: string; // Assuming u.uid is of type string
  sections: any[]; // Replace 'any' with a more specific type if possible
  categories: any[];
  updated: Date;
  live: boolean;
  workingHours: WorkingHour[];
  businessUrl: string;
  percentage?: number;
  languages: Languages;
  plan: null | string | any;
  currency: string;
  id?: string;
  visits?: number;
  menus?: number;
  defaultLanguage?: string;
  completed?: boolean;
  additionalDetails: {
    wifi: {
      name: string;
      pass: string;
    };
    social: {
      facebook: string;
      instagram: string;
      xTwitter: string;
    };
  };
}

import { MoreHorizOutlined } from '@mui/icons-material';
import {
  Box,
  CardContent,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { numberWithCommas } from '../../../../services/numberHandling';
import { Switch } from '../../../../reusableComponents/Switch/Switch';
import { ProductBase, ProductOption } from '../../../../models/product';
import { CardHeader } from 'react-bootstrap';
import { useRecoilState } from 'recoil';
import { adminAtom } from '../../../../state/adminAtom';
import { chooseMultilanguageValue } from '../../../../services/multiLanguague';
import { LanguageGeneric, Languages } from '../../../../models/languages';
import placeholder_menu from '../../../../res/placeholder_menu.png';
import { Card } from '../../../../reusableComponents/Card/Card';
import { currencyList } from '../../../../services/currency';

import ProductPlaceHolder from '../../../../res/item-placeholder.png';
import { t } from 'i18next';

export interface ProductEditableProps {
  id: string;
  productName: LanguageGeneric<string>;
  productDescription: LanguageGeneric<string>;
  imageUrl: string;
  price: number;
  available: boolean;
  live: boolean;
  options: ProductOption[];
  setProductData?: () => void;
  setAddProduct?: (addProduct: boolean) => void;
  numberWithCommas?: (number: number) => string;
  handleDelete?: () => void;
  handleAvailableChange?: (available: boolean) => void;
  handleLiveChange?: (live: boolean) => void;
}

export const ProductEditable = ({
  setProductData,
  setAddProduct,
  handleDelete,
  handleAvailableChange,
  handleLiveChange,
  productDescription,
  productName,
  imageUrl,
  options,
  price,
  available,
  live,
}: ProductEditableProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const [adminState, setAdminState] = useRecoilState(adminAtom);
  return (
    <Card
      sx={{
        backgroundColor: 'white',
        width: '100%',
        maxWidth: '100%',
        textAlign: 'left',
        transition: '0.3s',
        border: '1.5px solid rgb(231, 231, 231)',
        ':hover': {
          backgroundColor: 'white',
          border: '1.5px solid rgb(231, 231, 231)',
        },
        ':active': {
          backgroundColor: 'white',
          border: '1.5px solid rgb(231, 231, 231)',
        },
      }}
    >
      <CardContent>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Box>
            <Switch
              size="medium"
              checked={available}
              onChange={(event) => {
                if (handleAvailableChange) {
                  handleAvailableChange(event.target.checked);
                }
              }}
            />
          </Box>
          <Box>
            <IconButton onClick={openMenu} size="small">
              <MoreHorizOutlined />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={closeMenu}
            >
              <MenuItem
                onClick={() => {
                  if (setProductData) {
                    setProductData();
                  }
                  if (setAddProduct) {
                    setAddProduct(true);
                  }
                  closeMenu();
                }}
              >
                {t('edit')}
              </MenuItem>
              <MenuItem onClick={() => (handleDelete ? handleDelete() : ' ')}>
              {t('delete')}
              </MenuItem>
            </Menu>
          </Box>
        </Stack>

        <Divider />

        <Stack direction={'row'} spacing={2} mt={1}>
          {/* Image */}
          <Box>
            <div
              onClick={() => window.open(imageUrl)}
              style={{
                backgroundImage: `url(${imageUrl === '' ? ProductPlaceHolder : imageUrl})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundColor: 'lightgrey',
                cursor: 'pointer',
                maxHeight: '100px', // Adjust as needed
                maxWidth: '100px',
                width: '80px',
                height: '80px',
                borderRadius: 'var(--border-radius)',
              }}
            />
          </Box>

          {/* Description and Options */}
          <Box>
            <Typography variant="h6">
              {chooseMultilanguageValue(
                productName,
                adminState.language as keyof Languages,
                adminState.language as keyof Languages
              )}
            </Typography>
            <Typography>
              {chooseMultilanguageValue(
                productDescription,
                adminState.language as keyof Languages,
                adminState.language as keyof Languages
              )}
            </Typography>
            {options.length > 0 && (
              <>
                <Typography variant="subtitle2">Options</Typography>
                <List>
                  {options.map((option: ProductOption, optionIndex: number) => (
                    <ListItem key={optionIndex}>
                      <Typography>{`${chooseMultilanguageValue(
                        option.name,
                        adminState.language as keyof Languages,
                        adminState.language as keyof Languages
                      )} - ${
                        currencyList.filter(
                          (v) => v.value === adminState.businessData?.currency
                        )[0].symbol
                      } ${numberWithCommas(option.price)}`}</Typography>
                    </ListItem>
                  ))}
                </List>
              </>
            )}

            <Typography variant="h6">
              {
                currencyList.filter(
                  (v) => v.value === adminState.businessData?.currency
                )[0].symbol
              }{' '}
              {numberWithCommas(price)}
            </Typography>
          </Box>

          {/* Switches for Available and Live */}
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            {true !== true ? (
              <div>
                <Typography>Visible</Typography>
                <Switch
                  checked={live}
                  onChange={(event) => {
                    if (handleLiveChange) {
                      handleLiveChange(event.target.checked);
                    }
                  }}
                />
              </div>
            ) : null}
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};

import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import Flag from 'react-world-flags';
import './LanguagePicker.css';
import { useRecoilState } from 'recoil';
import { adminAtom } from '../../state/adminAtom';
import { Box } from '@mui/material';

import valencianFlag from '../../res/valencian-flag.png';
import barcelonaFlag from '../../res/barcelona-flag.png';

export type LanguagesSpace = 'ES' | 'ES-V' | 'ES-C' | 'EN' | 'IT' | 'DE';

interface LanguagePickerProps {
  languageValue: 'ES' | 'ES-V' | 'ES-C' | 'EN' | 'IT' | 'DE';
  languagesToRender: LanguagesSpace[];
  updateLanguage: (v: LanguagesSpace) => void;
}

const LanguagePicker = ({
  languageValue,
  updateLanguage,
  languagesToRender,
}: LanguagePickerProps) => {
  const [language, setLanguage] = useState(languageValue);
  const [adminState, setAdminState] = useRecoilState(adminAtom);

  const onMenuOptionPressed = () => {
    console.log('MenuOptionPressed');
  };

  function countryCode(lang: string) {
    if (lang == 'EN') {
      return 'us';
    } else if (lang == 'IT') {
      return 'it';
    } else if (lang == 'ES') {
      return 'es';
    } else if (lang == 'ES-V') {
      return 'es-v';
    } else if (lang == 'ES-C') {
      return 'es-c';
    } else if (lang === 'DE') {
      return 'de';
    }
  }

  const mapName = (lang: LanguagesSpace) => {
    if (lang == 'EN') {
      return 'English';
    } else if (lang == 'IT') {
      return 'Italian';
    } else if (lang == 'ES') {
      return 'Español';
    } else if (lang == 'ES-V') {
      return 'Valenciano';
    } else if (lang == 'ES-C') {
      return 'Catalan';
    } else if (lang === 'DE') {
      return 'German';
    }
  };

  return (
    <Dropdown
      className="language-dropdown mt-0"
      onSelect={(value) => {
        console.log(`Option selected: ${value}`);
        setLanguage(value as LanguagesSpace);
        updateLanguage(value as LanguagesSpace);
      }}
    >
      <Dropdown.Toggle
        className="dropdown-white d-flex mt-0"
        variant="secondary"
        id="dropdown-basic"
      >
        <div className="flag-container me-2">
          {countryCode(language) === 'es-v' ? (
            <Box
              sx={{
                width: '50px',
                height: '33.33px',
                backgroundImage: `url(${valencianFlag})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                borderRadius: '.5rem',
              }}
            ></Box>
          ) : countryCode(language) === 'es-c' ? (
            <Box
              sx={{
                width: '50px',
                height: '33.33px',
                backgroundImage: `url(${barcelonaFlag})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                borderRadius: '.5rem',
              }}
            ></Box>
          ) : (
            <Flag className="flag" code={countryCode(language)} />
          )}
        </div>
        {language}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {languagesToRender.map((l) => (
          <Dropdown.Item className="dropdown-white d-flex" eventKey={l}>
            <div className="flag-container me-2">
              {l === 'ES-V' ? (
                <Box
                  sx={{
                    width: '50px',
                    height: '33.33px',
                    backgroundImage: `url(${valencianFlag})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    borderRadius: '.5rem',
                  }}
                ></Box>
              ) : l === 'ES-C' ? (
                <Box
                  sx={{
                    width: '50px',
                    height: '33.33px',
                    backgroundImage: `url(${barcelonaFlag})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    borderRadius: '.5rem',
                  }}
                ></Box>
              ) : (
                <Flag className="flag" code={countryCode(l)} />
              )}
            </div>
            {mapName(l)}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguagePicker;

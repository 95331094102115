import React from 'react';
import { useRecoilState } from 'recoil';
import { adminAtom } from '../../../../state/adminAtom';
import {
  Box,
  CardContent,
  CircularProgress,
  Grid,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import { TextInput } from '../../../../reusableComponents/TextInput/TextInput';
import { Button } from '../../../../reusableComponents/Button/Button';
import { Cancel, Edit } from '@mui/icons-material';
import { Dropzone } from '../../../../reusableComponents/Dropzone/Dropzone';
import classNames from 'classnames';
import styles from '../Panel.module.css';
import { Cropper } from '../../Cropper/Cropper';

import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import PlacesAutocomplete from 'react-places-autocomplete';

import { FaFacebookF } from 'react-icons/fa';
import { FiInstagram } from 'react-icons/fi';

import { FaXTwitter } from 'react-icons/fa6';

//@ts-ignore
import LocationPicker from 'react-location-picker';
import { Card } from '../../../../reusableComponents/Card/Card';
import { Select } from '../../../../reusableComponents/Select/Select';
import { currencyList } from '../../../../services/currency';
import { BusinessData } from '../../../../models/business';
import { useTranslation } from 'react-i18next';
import countryOptions from '../../../../reusableComponents/PhoneInput/data.json';
import PhoneInput from '../../../../reusableComponents/PhoneInput/PhoneInput';

export const Presentation = () => {
  const { t } = useTranslation();
  const [adminState, setAdminState] = useRecoilState(adminAtom);

  const handleChange = (address: string) => {
    setAdminState((state) => ({
      ...state,
      businessData: {
        ...state.businessData,
        businessAddress: {
          ...state.businessData?.businessAddress,
          name: address,
        },
      } as any,
    }));
  };

  const handleSelect = (address: string) => {
    geocodeByAddress(address)
      .then(async (results) => {
        setAdminState((state) => ({
          ...state,
          businessData: {
            ...state.businessData,
            businessAddress: {
              ...state.businessData?.businessAddress,
              name: results[0].formatted_address,
            },
          } as any,
          toUpdate: true,
        }));
        return getLatLng(results[0]);
      })
      .then((latLng) =>
        setAdminState((state) => ({
          ...state,
          businessData: {
            ...state.businessData,
            businessAddress: {
              ...state.businessData?.businessAddress,
              ...latLng,
            },
          } as any,
          toUpdate: true,
        }))
      )
      .catch((error) => console.error('Error', error));
  };

  const handleLocationChange = ({ position, address, places }: any) => {
    setAdminState((state) => ({
      ...state,
      businessData: {
        ...state.businessData,
        businessAddress: {
          ...state.businessData?.businessAddress,
          ...position,
        },
      } as any,
      toUpdate: true,
    }));
  };

  return adminState.businessData ? (
    <Box
      sx={{
        pt: '64px',
      }}
    >
      {adminState.src !== null ? (
        <Cropper
          mount={(image: any) => {
            if (adminState.coverEdit) {
              setAdminState((state) => ({
                ...state,
                businessCover: image,
                coverEdit: false,
                toUpdate: true,
              }));
            }

            if (adminState.logoEdit) {
              setAdminState((state) => ({
                ...state,
                businessImage: image,
                logoEdit: false,
                toUpdate: true,
              }));
            }
          }}
          src={adminState.src}
          aspect={adminState.aspect}
          close={() => {
            setAdminState((state) => ({
              ...state,
              src: null,
            }));
          }}
        />
      ) : null}

      <Typography textAlign={'center'} variant="h4" fontWeight={'bold'}>
        {t('presentation')}
      </Typography>

      <Card mt={4}>
        <CardContent>
          <Typography variant="h5" fontWeight={'bold'}>
            {t('cover_and_logo')}
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} mt={2}>
              <Stack>
                <Typography mb={2}>{t('image_cover') + ' (1000 x 500)'}</Typography>

                {(adminState.businessCover ||
                  adminState.businessData?.businessCoverImageUrl !== '') &&
                adminState.coverEdit === false ? (
                  <Stack spacing={2}>
                    <Box
                      sx={{
                        height: {
                          xs: '75px',
                          sm: '75px',
                          md: '150px',
                          lg: '150px',
                        },
                        width: {
                          xs: '220px',
                          sm: '220px',
                          md: '440px',
                          lg: '440px',
                        },
                        maxWidth: '100%',
                        backgroundImage: `url(${
                          adminState.businessCover
                            ? URL.createObjectURL(adminState.businessCover)
                            : adminState.businessData?.businessCoverImageUrl
                        })`,
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        borderRadius: 'var(--border-radius)',
                      }}
                    ></Box>

                    {adminState.businessData?.businessCoverImageUrl !== '' ? (
                      <Box>
                        <Button
                          endIcon={<Edit />}
                          onClick={() => {
                            setAdminState((state) => ({
                              ...state,
                              businessCover: null,
                              coverEdit: true,
                            }));
                          }}
                          variant="outlined"
                        >
                          {t('edit')}
                        </Button>
                      </Box>
                    ) : null}
                  </Stack>
                ) : null}

                {(!adminState.businessCover && adminState.coverEdit) ||
                (adminState.businessData?.businessCoverImageUrl === '' &&
                  !adminState.businessCover) ? (
                  <Stack spacing={2}>
                    <Box
                      sx={{
                        height: {
                          xs: '75px',
                          sm: '75px',
                          md: '150px',
                          lg: '150px',
                        },
                        width: {
                          xs: '220px',
                          sm: '220px',
                          md: '440px',
                          lg: '440px',
                        },

                        '& .MuiDropzoneArea-root': {
                          p: '5px',
                          paddingTop: '1rem important',

                          '.MuiTypography-h5': {
                            marginTop: '0 !important',
                          },
                        },
                      }}
                    >
                      <Dropzone
                        acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                        showPreviewsInDropzone={false}
                        dropzoneClass={classNames(styles['cover-dropzone'])}
                        dropzoneText={t('drag_and_drop')}
                        onChange={(f) => {
                          if (f.length > 0) {
                            setAdminState((state) => ({
                              ...state,
                              src: URL.createObjectURL((f as any)[0]),
                              aspect: 2.22 / 0.75,
                              coverEdit: true,
                              logoEdit: false,
                            }));
                          }
                        }}
                        filesLimit={1}
                        showPreviews={false}
                      />
                    </Box>

                    {adminState.coverEdit ? (
                      <Box>
                        <Button
                          variant="outlined"
                          endIcon={<Cancel />}
                          onClick={() => {
                            setAdminState((state) => ({
                              ...state,
                              coverEdit: false,
                              businessCover: null,
                            }));
                          }}
                        >
                          {t('cancel')}
                        </Button>
                      </Box>
                    ) : null}
                  </Stack>
                ) : null}
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} mt={2}>
              <Stack>
                <Box>
                  <Typography mb={2} textAlign={'left'}>
                    {t('logo_image') + ' (250 x 250)'}
                  </Typography>
                </Box>

                {(adminState.businessImage ||
                  adminState.businessData?.businessImageUrl !== '') &&
                adminState.logoEdit === false ? (
                  <Stack spacing={2}>
                    <Box
                      sx={{
                        height: {
                          xs: '75px',
                          sm: '75px',
                          md: '150px',
                          lg: '150px',
                        },
                        width: {
                          xs: '75px',
                          sm: '75px',
                          md: '150px',
                          lg: '150px',
                        },
                        backgroundImage: `url(${
                          adminState.businessImage
                            ? URL.createObjectURL(adminState.businessImage)
                            : adminState.businessData?.businessImageUrl
                        })`,
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        borderRadius: 'var(--border-radius)',
                      }}
                    ></Box>

                    {adminState.businessData?.businessImageUrl !== '' ? (
                      <Box>
                        <Button
                          endIcon={<Edit />}
                          variant="outlined"
                          onClick={() => {
                            setAdminState((state) => ({
                              ...state,
                              businessImage: null,
                              logoEdit: true,
                            }));
                          }}
                        >
                          {t('edit')}
                        </Button>
                      </Box>
                    ) : null}
                  </Stack>
                ) : null}

                {(!adminState.businessImage && adminState.logoEdit) ||
                (adminState.businessData?.businessImageUrl === '' &&
                  !adminState.businessImage) ? (
                  <Stack spacing={2}>
                    <Box
                      sx={{
                        height: {
                          xs: '150px',
                          sm: '150px',
                          md: '200px',
                          lg: '200px',
                        },
                        width: {
                          xs: '150px',
                          sm: '150px',
                          md: '200px',
                          lg: '200px',
                        },

                        '& .MuiDropzoneArea-root': {
                          p: '5px',
                          paddingTop: '1rem important',

                          '.MuiTypography-h5': {
                            marginTop: '0 !important',
                          },
                        },
                      }}
                    >
                      <Dropzone
                        acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                        showPreviewsInDropzone={false}
                        dropzoneClass={classNames(styles['logo-dropzone'])}
                        dropzoneText="Drop it"
                        onChange={(f) => {
                          if (f.length > 0) {
                            setAdminState((state) => ({
                              ...state,
                              src: URL.createObjectURL((f as any)[0]),
                              aspect: 1 / 1,
                              logoEdit: true,
                              coverEdit: false,
                            }));
                          }
                        }}
                        filesLimit={1}
                        showPreviews={false}
                      />
                    </Box>

                    {adminState.logoEdit ? (
                      <Box>
                        <Button
                          endIcon={<Cancel />}
                          variant="outlined"
                          onClick={() => {
                            setAdminState((state) => ({
                              ...state,
                              businessImage: null,
                              logoEdit: false,
                            }));
                          }}
                        >
                          {t('cancel')}
                        </Button>
                      </Box>
                    ) : null}
                  </Stack>
                ) : null}
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card mt={4}>
        <CardContent>
          <Typography variant="h5" fontWeight={'bold'}>
            {t('name_and_url')}
          </Typography>

          <Box
            sx={{
              boxSizing: 'border-box',
            }}
          >
            <Grid container spacing={4}>
              <Grid xs={12} sm={12} md={6} lg={6} xl={6} item mt={2}>
                <Stack spacing={2}>
                  <TextInput
                    fullWidth
                    sx={{
                      textAlign: 'left',
                    }}
                    value={adminState.businessData?.businessName}
                    labelText={t('business_name')}
                    label={t('business_name')}
                    onChange={(e) => {
                      setAdminState((state) => ({
                        ...state,
                        businessData: {
                          ...state.businessData,
                          businessName: e.currentTarget.value,
                        } as any,
                        toUpdate: true,
                      }));
                    }}
                  />

                  <TextInput
                    label={t('business_description')}
                    multiline
                    value={adminState.businessData?.businessDescription}
                    placeholder={t('write_the_description_of_your_business')}
                    rows={3}
                    sx={{
                      textAlign: 'left',
                    }}
                    onChange={(e) => {
                      setAdminState((state) => ({
                        ...state,
                        businessData: {
                          ...state.businessData,
                          businessDescription: e.currentTarget.value,
                        } as any,
                        toUpdate: true,
                      }));
                    }}
                  />
                </Stack>
              </Grid>

              <Grid xs={12} sm={12} md={6} lg={6} xl={6} item mt={2}>
                <Stack spacing={2}>
                  <PhoneInput
                    value={adminState.businessData?.phone as string}
                    label={t('phone')}
                    countryOptions={countryOptions}
                    onChange={(e: any) => {
                      setAdminState((state) => ({
                        ...state,
                        businessData: {
                          ...state.businessData,
                          phone: e,
                        } as any,
                        toUpdate: true,
                      }));
                    }}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>

      <Card mt={4}>
        <Typography variant="h5" fontWeight={'bold'} mb={3}>
          {t('address_and_position')}
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Stack direction={'row'}>
              <Box
                sx={{
                  borderRadius: 'var(--border-radius)',
                  overflow: 'hidden',
                  width: '100%',
                }}
              >
                {adminState.businessData?.businessAddress
                  ? (() => {
                      return (
                        <LocationPicker
                          containerElement={<div />}
                          mapElement={
                            <Box
                              sx={{
                                width: {
                                  xs: '100%',
                                  sm: '100%',
                                  md: '100%',
                                  lg: '100%',
                                  xl: '100%',
                                },
                              }}
                              style={{
                                height: '300px',
                              }}
                            />
                          }
                          defaultPosition={{
                            lat: adminState.businessData?.businessAddress?.lat,
                            lng: adminState.businessData?.businessAddress?.lng,
                          }}
                          onChange={handleLocationChange}
                        />
                      );
                    })()
                  : null}
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <PlacesAutocomplete
              value={adminState.businessData?.businessAddress?.name}
              onChange={handleChange}
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <Box
                  sx={{
                    position: 'relative',
                  }}
                >
                  <TextInput
                    {...getInputProps({
                      label: t('address'),
                      className: 'location-search-input',
                      sx: {
                        textAlign: 'left',
                        minWidth: '300px',
                      },
                    })}
                    placeholder={t('write_the_adress_of_your_business')}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      endAdornment: loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null,
                    }}
                  />
                  <Box
                    sx={{
                      mt: 1,
                      position: 'absolute',
                      zIndex: 99,
                      width: '100%',
                      backgroundColor: 'white',
                      borderBottomLeftRadius: 'var(--border-radius)',
                      borderBottomRightRadius: 'var(--border-radius)',
                      overflow: 'hidden',
                      boxShadow: '0 8px 8px 0 rgba(0,0,0,0.2)',
                    }}
                  >
                    {loading && <CircularProgress />}
                    {suggestions.map((suggestion) => {
                      const style = suggestion.active
                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                      return (
                        <Box
                          {...getSuggestionItemProps(suggestion, { style })}
                          sx={{
                            p: 1,
                            // borderBottom: '1px solid #ddd',
                            backgroundColor: style.backgroundColor,
                            '&:hover': {
                              backgroundColor: '#f2f2f2',
                            },
                          }}
                        >
                          {suggestion.description}
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              )}
            </PlacesAutocomplete>
          </Grid>
        </Grid>
      </Card>

      <Card mt={4}>
        <Typography variant="h5" fontWeight={'bold'}>
          {t('currency')}
        </Typography>

        <Box mt={3}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Select
                labelText={t('currency')}
                placeholder={t('select_currency')}
                value={adminState.businessData?.currency}
                options={currencyList.map((e, i) => ({
                  value: e.value,
                  text: e.text + ' - ' + e.symbol,
                  key: i,
                }))}
                onChange={(e) => {
                  setAdminState((state) => ({
                    ...state,
                    toUpdate: true,
                    businessData: {
                      ...state.businessData,
                      currency: e.target.value,
                    } as BusinessData,
                  }));
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Card>

      <Card mt={4}>
        <Typography variant="h5" fontWeight={'bold'}>
          {t('wifi_details')}
        </Typography>

        <Box
          mt={3}
          sx={{
            boxSizing: 'border-box',
          }}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextInput
                label={t('wifi_name')}
                value={adminState.businessData?.additionalDetails.wifi.name}
                sx={{
                  textAlign: 'left',
                }}
                fullWidth
                placeholder={t('write_the_wifi_name_if_any')}
                onChange={(el) => {
                  setAdminState((state) => ({
                    ...state,
                    businessData: {
                      ...state.businessData,
                      additionalDetails: {
                        ...state.businessData?.additionalDetails,
                        wifi: {
                          ...state.businessData?.additionalDetails.wifi,
                          name: el.currentTarget.value,
                        },
                      },
                    } as any,
                    toUpdate: true,
                  }));
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextInput
                label={t('wifi_password')}
                value={adminState.businessData?.additionalDetails.wifi.pass}
                placeholder={t('write_the_wifi_password_if_any')}
                sx={{
                  textAlign: 'left',
                }}
                fullWidth
                onChange={(el) => {
                  setAdminState((state) => ({
                    ...state,
                    businessData: {
                      ...state.businessData,
                      additionalDetails: {
                        ...state.businessData?.additionalDetails,
                        wifi: {
                          ...state.businessData?.additionalDetails.wifi,
                          pass: el.currentTarget.value,
                        },
                      },
                    } as any,
                    toUpdate: true,
                  }));
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Card>

      <Card mt={4}>
        <Typography variant="h5" fontWeight={'bold'}>
          {t('additional_information')}
        </Typography>

        <Box
          mt={3}
          sx={{
            boxSizing: 'border-box',
          }}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Stack spacing={2}>
                <TextInput
                  label={t('facebook')}
                  placeholder="e.g: https://www.facebook.com/john.doe"
                  sx={{
                    textAlign: 'left',
                  }}
                  InputProps={{
                    startAdornment: <FaFacebookF />,
                  }}
                  value={
                    adminState.businessData?.additionalDetails.social.facebook
                  }
                  onChange={(el) => {
                    setAdminState((state) => ({
                      ...state,
                      businessData: {
                        ...state.businessData,
                        additionalDetails: {
                          ...state.businessData?.additionalDetails,
                          social: {
                            ...state.businessData?.additionalDetails.social,
                            facebook: el.currentTarget.value,
                          },
                        },
                      } as any,
                      toUpdate: true,
                    }));
                  }}
                />
                <TextInput
                  label={t('instagram')}
                  placeholder="e.g: https://www.instagram.com/johndoe"
                  sx={{
                    textAlign: 'left',
                  }}
                  InputProps={{
                    startAdornment: <FiInstagram />,
                  }}
                  value={
                    adminState.businessData?.additionalDetails.social.instagram
                  }
                  onChange={(el) => {
                    setAdminState((state) => ({
                      ...state,
                      businessData: {
                        ...state.businessData,
                        additionalDetails: {
                          ...state.businessData?.additionalDetails,
                          social: {
                            ...state.businessData?.additionalDetails.social,
                            instagram: el.currentTarget.value,
                          },
                        },
                      } as any,
                      toUpdate: true,
                    }));
                  }}
                />
                <TextInput
                  label="X"
                  placeholder="e.g: https://twitter.com/johndoe"
                  sx={{
                    textAlign: 'left',
                  }}
                  InputProps={{
                    startAdornment: <FaXTwitter />,
                  }}
                  value={
                    adminState.businessData?.additionalDetails.social.xTwitter
                  }
                  onChange={(el) => {
                    setAdminState((state) => ({
                      ...state,
                      businessData: {
                        ...state.businessData,
                        additionalDetails: {
                          ...state.businessData?.additionalDetails,
                          social: {
                            ...state.businessData?.additionalDetails.social,
                            xTwitter: el.currentTarget.value,
                          },
                        },
                      } as any,
                      toUpdate: true,
                    }));
                  }}
                />
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Box>
  ) : null;
};

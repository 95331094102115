import { Box, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Modal } from '../../../reusableComponents/Modal/Modal';
import { Button } from '../../../reusableComponents/Button/Button';
import { useTranslation } from 'react-i18next';

interface CropperProps {
  aspect?: any;
  src?: string;
  close?: Function;
  mount?: Function;
}

export function Cropper({ src, close, aspect, mount }: CropperProps) {
  const { t } = useTranslation();

  const [used, setUsed] = useState(false); // Verifies if the crop as been resized before applying

  const [crop, setCrop] = useState<Crop>({
    unit: '%', // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 50,
    height: 50,
  });

  const getCroppedImg = (
    image: HTMLImageElement,
    crop: any,
    fileName: string
  ) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    if (ctx) {
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );
    }

    // As a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          resolve(blob);
        },
        'image/png',
        0.5
      );
    });
  };

  return (
    <Modal
      open={true}
      onClose={(event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
        if (close) {
          close();
        }
      }}
      sx={{
        overflow: 'hidden',
      }}
    >
      <>
        <Typography textAlign={'center'} variant="h5" mb={3}>
          {t('crop_your_image')}
        </Typography>
        <ReactCrop
          crop={crop}
          onChange={(c) => setCrop(c)}
          aspect={aspect}
          onComplete={() => {
            setUsed(true);
          }}
        >
          <img
            src={src}
            id="image-rendered"
            width={'100%'}
            style={{
              margin: 'auto',
            }}
          />
        </ReactCrop>

        <Stack direction={'row'} justifyContent={'center'} spacing={2} mt={3}>
          <Button
            variant="text"
            onClick={() => {
              if (close) {
                close();
              }
            }}
          >
            {t('cancel')}
          </Button>
          <Button
            disabled={!used}
            onClick={async () => {
              let image = await getCroppedImg(
                document.getElementById('image-rendered') as HTMLImageElement,
                crop,
                'croppedImage'
              );

              if (mount) {
                mount(image);
              }
              if (close) {
                close();
              }
            }}
          >
            {t('apply')}
          </Button>
        </Stack>
      </>
    </Modal>
  );
}

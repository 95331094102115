import * as React from 'react';
import Tabs, { TabsProps } from '@mui/material/Tabs';
import Tab, { TabProps } from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { IconButton, Stack } from '@mui/material';
import { Edit } from '@mui/icons-material';

interface TabPanelProps {
  children?: React.ReactNode;
  index?: number;
  value?: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.displayName = 'TabPanel';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface TabPropsExtension extends TabProps {}

type CustomTabsProps = {
  tabs?: Array<TabPropsExtension & { onEdit?: Function }>;
  children: React.ReactNode;
  mode?: 'default' | 'router';
  muiProps?: TabsProps;
  editableTabs?: boolean;
  listenIndexChange?: (index: number) => void;
  defaultValue?: number;
};

export function TabsContainer({
  tabs,
  children,
  mode = 'default',
  muiProps,
  editableTabs,
  listenIndexChange,
  defaultValue,
}: CustomTabsProps) {
  const [value, setValue] = React.useState(defaultValue ? defaultValue : 0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (listenIndexChange) {
      listenIndexChange(newValue);
    }
  };

  const mappedChildren = (children: React.ReactNode) =>
    React.Children.map(children, (child: any, i: number) => {
      if (React.isValidElement(child as any)) {
        if (child.type.displayName === 'TabPanel') {
          return React.cloneElement(child, {
            value: value,
            index: i,
          });
        } else {
          throw new Error('Childrens must be CustomTabs.TabPanel');
        }
      }
      return child;
    });

  const EditableTab = ({
    onEdit,
    ...rest
  }: TabPropsExtension & {
    onEdit?: Function;
    index: number;
  }) => {
    return (
      <Box display="flex" alignItems="center" sx={{ flexShrink: 0 }}>
        <Tab label={rest.label} {...rest} sx={{ flexShrink: 0 }} />
        {onEdit ? (
          <IconButton onClick={onEdit as any}>
            <Edit />
          </IconButton>
        ) : null}
      </Box>
    );
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          {...muiProps}
          aria-label="Tabs"
          scrollButtons={true}
          variant="scrollable"
          allowScrollButtonsMobile
          //  centered
        >
          {tabs?.map((e, i) =>
            editableTabs ? (
              <EditableTab
                index={0}
                disableRipple
                {...e}
                {...a11yProps(i)}
                onEdit={e.onEdit as Function}
              />
            ) : (
              <Tab
                disableRipple
                {...e}
                {...a11yProps(i)}
                iconPosition="start"
              />
            )
          )}
        </Tabs>
      </Box>
      {mode == 'default' ? mappedChildren(children) : children}
    </Box>
  );
}

TabsContainer.TabPanel = TabPanel;

import React, { useEffect, useState } from 'react';
import { Modal } from '../../../../reusableComponents/Modal/Modal';
import { Box, Stack, Typography } from '@mui/material';
import { LanguageGeneric, Languages } from '../../../../models/languages';
import { TabsContainer } from '../../../../reusableComponents/Tabs/Tabs';
import { TextInput } from '../../../../reusableComponents/TextInput/TextInput';
import { Button } from '../../../../reusableComponents/Button/Button';
import { useRecoilState } from 'recoil';
import { loadingAtom } from '../../../../state/loading';
import firebase from '../../../../services/firebase';
import { adminAtom } from '../../../../state/adminAtom';
import { toast } from 'react-toastify';
import { BusinessData } from '../../../../models/business';
import { Delete, Star } from '@mui/icons-material';
import { dialogAtom } from '../../../../state/dialogAtom';
import { Switch } from '../../../../reusableComponents/Switch/Switch';
import {
  getMultilanguageValue,
  setMultilanguageValue,
} from '../../../../services/multiLanguague';
import { getTranslationApi } from '../../../../api/translation';
import { useTranslation } from 'react-i18next';

interface AddSectionProps {
  close: Function;
}

export function AddSection({ close }: AddSectionProps) {
  const { t } = useTranslation();
  const [adminState, setAdminState] = useRecoilState(adminAtom);

  const [dialogState, setDialogState] = useRecoilState(dialogAtom);

  const [automaticTranslation, setAutomaticTranslation] = useState(false);

  const [languagesToTranslate, setLanguagesToTranslate] = useState({
    DE: {
      name: 'German',
      state: true,
    },
    ES: {
      name: 'Spanish',
      state: true,
    },
    'ES-V': {
      name: 'Valencian',
      state: true,
    },
    'ES-C': {
      name: 'Catalan',
      state: true,
    },
    IT: {
      name: 'Italian',
      state: true,
    },
    EN: {
      name: 'English',
      state: true,
    },
  } as Languages);

  const [indexes, setIndexes] = useState(
    Object.keys((adminState.businessData as BusinessData).languages)

      .filter(
        (key) =>
          adminState.businessData?.languages[key as keyof Languages].state ===
          true
      )
      .sort()
      .map((key, i) => ({ lang: key, i }))
  );

  const [sectionName, setSectionName] = useState<any>(
    Object.keys((adminState.businessData as BusinessData).languages).reduce(
      (obj: any, key: any) => {
        obj[key] = '';

        return obj;
      },
      {}
    )
  );

  const [isLoading, setIsLoading] = useRecoilState(loadingAtom);

  useEffect(() => {
    if (adminState.sectionData) {
      setSectionName(adminState.sectionData);
    }
  }, [adminState.sectionData]);

  const createSection = () => {
    setIsLoading(true);

    let errorCheck = 0; //Verify that at least in one language there is text
    let messages: String[] = []; //Acum of messages
    let collectedNames = [];

    Object.keys(sectionName).forEach((key: string) => {
      if (String(sectionName[key]).trim() === '') {
      } else {
        collectedNames.push(sectionName[key]);
      }

      if (String(sectionName[key]).trim().length > 50) {
        errorCheck = 1;
        messages.push(`${t('text_too_large_on_language')} ${key}`);
      }
    });

    if (collectedNames.length === 0) {
      errorCheck = 1;
      messages.push(t('section_name_cant_be_empty'));
    }

    if (errorCheck === 0 && collectedNames.length > 0) {
      firebase
        .firestore()
        .collection('business')
        .doc(adminState.businessData?.id)
        .collection('menus')
        .doc(adminState.menuData?.id)
        .update({
          sections: firebase.firestore.FieldValue.arrayUnion({
            ...sectionName,
            id: firebase.firestore().collection('sections').doc().id,
          }),
        })
        .then(() => {
          setIsLoading(false);
          toast.success(t('section_created'));
          close();
        })
        .catch((e) => {
          console.log(e);
          toast.error(t('something_went_wrong'));
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);

      for (let mss of messages) {
        toast.info(mss);
      }
    }
  };

  const updateSection = () => {
    setIsLoading(true);

    let errorCheck = 0; //Verify that at least in one language there is text
    let messages: String[] = []; //Acum of messages
    let collectedNames = [];

    Object.keys(sectionName).forEach((key: string) => {
      if (String(sectionName[key]).trim() === '') {
      } else {
        collectedNames.push(sectionName[key]);
      }

      if (String(sectionName[key]).trim().length > 50) {
        errorCheck = 1;
        messages.push(`${t('text_too_large_on_language')} ${key}`);
      }
    });

    if (collectedNames.length === 0) {
      errorCheck = 1;
      messages.push(t('section_name_cant_be_empty'));
    }

    if (errorCheck === 0 && collectedNames.length > 0) {
      const sections = [
        ...(adminState.menuData?.sections as (LanguageGeneric<string> & {
          id: string;
        })[]),
      ];

      for (let i = 0; i < sections.length; i++) {
        if (sections[i].id === adminState.menuData?.id) {
          sections[i] = {
            ...sections[i],
            ...sectionName,
          };
        }
      }
      firebase
        .firestore()
        .collection('business')
        .doc(adminState.businessData?.id)
        .collection('menus')
        .doc(adminState.menuData?.id)
        .update({
          sections: sections,
        })
        .then(() => {
          setIsLoading(false);
          toast.success(t('section_updated'));
          close();
        })
        .catch((e) => {
          console.log(e);
          toast.error(t('something_went_wrong'));
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);

      for (let mss of messages) {
        toast.info(mss);
      }
    }
  };

  const deleteSection = () => {
    setDialogState((state) => ({
      ...state,
      open: true,
      setOpen: (o: boolean) => {
        setDialogState((s) => ({
          ...s,
          open: o,
        }));
      },
      onConfirm: async () => {
        setIsLoading(true);

        firebase
          .firestore()
          .collection('products')
          .where('section', '==', adminState.sectionData?.id)
          .get()
          .then((snap) => {
            const categoriesLinked = adminState.menuData?.categories?.filter(
              (v) => v.linkedSection === adminState.sectionData?.id
            );
            if (snap.empty && categoriesLinked?.length === 0) {
              const sections: (LanguageGeneric<string> & { id: string })[] = [
                ...(adminState.menuData
                  ?.sections as (LanguageGeneric<string> & {
                  id: string;
                })[]),
              ];

              for (let i = 0; i < sections.length; i++) {
                if (sections[i].id === adminState.sectionData?.id) {
                  sections.splice(i, 1);
                }
              }

              firebase
                .firestore()
                .collection('business')
                .doc(adminState.businessData?.id)
                .collection('menus')
                .doc(adminState.menuData?.id)
                .update({
                  sections: sections,
                })
                .then(() => {
                  setIsLoading(false);
                  toast.success(t('category_deleted'));
                  close();
                })
                .catch((e) => {
                  console.log(e);
                  toast.error(t('something_went_wrong'));
                  setIsLoading(false);
                });
            } else {
              setIsLoading(false);

              if (categoriesLinked) {
                if (categoriesLinked.length > 0) {
                  toast.error(
                    t('cant_delete_because_it_has_categories_attached')
                  );
                }
              }
              toast.error(t('cant_delete_because_some_produtcs_use_it'));
            }
          })
          .catch((e) => {
            console.log(e);
            toast.error(t('something_went_wrong'));
            setIsLoading(false);
          });
      },
      children: <Box>Sure you want to delete this section?</Box>,
      sentiment: 'error',
      title: t('delete_category'),
    }));
  };

  const getAutomaticTranslationBasedOnIndex = (i: number) => {
    if (
      adminState.planData?.translation &&
      languagesToTranslate[indexes[i].lang as keyof Languages].state === false
    ) {
      if (indexes[i].lang !== adminState.businessData?.defaultLanguage) {
        if (automaticTranslation) {
          let sectionNameVal: string = getMultilanguageValue(
            sectionName,
            adminState.businessData?.defaultLanguage as keyof Languages
          );

          const valuesToTranslate = [];

          if (sectionNameVal.trim() !== '') {
            valuesToTranslate.push(sectionNameVal);
          }

          if (valuesToTranslate.length > 0) {
            getTranslationApi(
              valuesToTranslate,
              adminState.languagesISO[indexes[i].lang as keyof Languages]
            )
              .then((r) => {
                if (r[0]) {
                  setMultilanguageValue(
                    r[0],
                    setSectionName,
                    indexes[i].lang as keyof Languages
                  );
                }

                let langObj: Languages = JSON.parse(
                  JSON.stringify(languagesToTranslate)
                );

                langObj[indexes[i].lang as keyof Languages].state = true;
                setLanguagesToTranslate(langObj);
              })
              .catch((e) => {
                console.log(e);
              });
          }
        }
      }
    }
  };

  return (
    <Modal
      open={true}
      onClose={() => {
        if (close) {
          close();
        }
      }}
    >
      <>
        <Typography variant="h4" textAlign={'center'}>
          {adminState.sectionData ? t('update_section') : t('create_section')}
        </Typography>

        <TabsContainer
          muiProps={{
            sx: {
              mt: 2,
            },
          }}
          listenIndexChange={getAutomaticTranslationBasedOnIndex}
          defaultValue={
            indexes.filter(
              (v) => v.lang === adminState.businessData?.defaultLanguage
            )[0].i
          }
          tabs={Object.keys((adminState.businessData as BusinessData).languages)
            .filter(
              (key) =>
                adminState.businessData?.languages[key as keyof Languages]
                  .state === true
            )
            .sort()
            .map((key: string, i: number) => ({
              label:
                adminState.businessData?.languages[key as keyof Languages].name,
              key: i,
              icon:
                key === adminState.businessData?.defaultLanguage ? (
                  <Star />
                ) : undefined,
            }))}
        >
          {Object.keys((adminState.businessData as BusinessData).languages)
            .filter(
              (key) =>
                adminState.businessData?.languages[key as keyof Languages]
                  .state === true
            )
            .sort()
            .map((key: string, i: number) => {
              return (
                <TabsContainer.TabPanel key={i}>
                  {adminState.businessData?.defaultLanguage ? (
                    key !== adminState.businessData?.defaultLanguage ? (
                      <Stack direction={'row'} justifyContent={'end'}>
                        <Switch
                          labelText={t('automatic_translation')}
                          checked={automaticTranslation}
                          onChange={(e) => {
                            setAutomaticTranslation(e.target.checked);
                          }}
                        />
                      </Stack>
                    ) : null
                  ) : null}

                  <TextInput
                    fullWidth
                    label={t('section_name')}
                    value={sectionName[key]}
                    placeholder={t('enter_section_name')}
                    inputProps={{
                      maxLength: 50,
                    }}
                    onChange={(el) => {
                      const value = el.currentTarget.value;
                      setSectionName((state: any) => ({
                        ...state,
                        [key]: value,
                      }));

                      let langObj: Languages = JSON.parse(
                        JSON.stringify(languagesToTranslate)
                      );

                      Object.keys(langObj).forEach((key: any) => {
                        langObj[key as keyof Languages].state = false;
                      });

                      setLanguagesToTranslate(langObj);
                    }}
                  />
                </TabsContainer.TabPanel>
              );
            })}
        </TabsContainer>

        {adminState.sectionData ? (
          <Stack spacing={1}>
            <Button
              fullWidth
              onClick={() => {
                updateSection();
              }}
            >
              {t('update')}
            </Button>
            <Stack justifyContent={'center'} direction={'row'}>
              <Button
                color="error"
                onClick={() => {
                  deleteSection();
                }}
              >
                <Delete />
              </Button>
            </Stack>
          </Stack>
        ) : (
          <Button
            fullWidth
            onClick={() => {
              createSection();
            }}
          >
            {t('create')}
          </Button>
        )}
      </>
    </Modal>
  );
}

import React, { ReactNode, useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';
import './CategorySlider.css';
import { LanguageGeneric } from '../../models/languages';
import { chooseMultilanguageValue } from '../../services/multiLanguague';
import { useRecoilState } from 'recoil';
import { menuAtom } from '../../state/menuAtom';
import { LanguagesSpace } from '../LanguagePicker/LanguagePicker';
import { Link } from '@mui/material';

interface CategorySliderProps {
  categories: (LanguageGeneric<string> & { id: string })[];
}

export const CategorySlider = ({ categories }: CategorySliderProps) => {
  const categoryList = categories;
  const [selectorElements, setSelectorElements] = useState(
    Array<ReactNode>(categoryList.length)
  );

  const [menuState, setMenuState] = useRecoilState(menuAtom);

  useEffect(() => {}, []);
  useEffect(() => {
    // document.querySelectorAll('.category-item').forEach((item) => {
    //   item.addEventListener('click', (event: Event) => {
    //     // Assuming each .item is an anchor <a> element
    //     const anchor = event.currentTarget as HTMLAnchorElement;
    //     const targetId = anchor.getAttribute('href');

    //     // Check if the target ID is valid
    //     if (targetId && targetId.startsWith('#')) {
    //       // Delay the scroll adjustment to allow for page navigation
    //       setTimeout(() => {
    //         const container = document.getElementById(
    //           'container-categories'
    //         ) as HTMLElement;
    //         const targetElement = document.querySelector(
    //           targetId
    //         ) as HTMLElement;

    //         if (container && targetElement) {
    //           const scrollTo = targetElement.offsetLeft;
    //           container.scrollLeft = scrollTo;
    //           container.scrollTo({ left: scrollTo, behavior: 'smooth' });
    //         }
    //       }, 100); // Adjust the timeout as needed
    //     }
    //   });
    // });
    window.addEventListener('scroll', isSticky);

    let listResult = [];
    for (let i = 0; i < categoryList.length; i++) {
      if (i == 0) {
        listResult.push(
          <Link
            className="mx-1 category-item is-selected"
            id={`selector-${categoryList[i].id}`}
            href={`#${categoryList[i].id}`}
            sx={{
              textDecoration: 'none',
            }}
          >
            <div
              style={{
                whiteSpace: 'nowrap',
              }}
            >
              {chooseMultilanguageValue(
                categoryList[i],
                menuState.language as LanguagesSpace,
                menuState.language as LanguagesSpace
              )}
            </div>
          </Link>
        );
      } else {
        listResult.push(
          <Link
            className="mx-1 category-item"
            id={`selector-${categoryList[i].id}`}
            href={`#${categoryList[i].id}`}
            sx={{
              textDecoration: 'none',
            }}
          >
            <div
              style={{
                whiteSpace: 'nowrap',
              }}
            >
              {chooseMultilanguageValue(
                categoryList[i],
                menuState.language as LanguagesSpace,
                menuState.language as LanguagesSpace
              )}
            </div>
          </Link>
        );
      }
    }

    setSelectorElements(listResult);

    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  }, []);

  const isSticky = (e: Event) => {
    let activeCategoryFound = false;

    categories.forEach((category) => {
      const section = document.getElementById(category.id) as HTMLElement;
      const navbarItem = document.querySelector(
        `#selector-${category.id}`
      ) as HTMLElement;

      if (section && navbarItem) {
        const sectionTop = section.getBoundingClientRect().top;
        const sectionBottom = section.getBoundingClientRect().bottom;

        // Adjust these values based on your requirements
        const isInView =
          sectionTop < window.innerHeight && sectionBottom - 150 >= 0;

        if (isInView && !activeCategoryFound) {
          navbarItem.classList.add('is-selected');
          activeCategoryFound = true; // Mark that an active category has been found

          const container = document.getElementById(
            'container-categories'
          ) as HTMLElement;

          setTimeout(() => {
            if (container) {
              const scrollTo = navbarItem.offsetLeft;

              container.scrollTo({ left: scrollTo, behavior: 'smooth' });
            }
          }, 500);
        } else {
          navbarItem.classList.remove('is-selected');
        }
      }
    });

    // If no active category is found (e.g., user is at the top of the page), remove 'active' from all
    if (!activeCategoryFound) {
      categories.forEach((category) => {
        const navbarItem = document.querySelector(
          `#selector-${category.id}`
        ) as HTMLElement;
        if (navbarItem) {
          navbarItem.classList.remove('is-selected');
        }
      });
    }
    const header = document.querySelector('.category-section');
    const scrollTop = window.scrollY;
    scrollTop >= 140
      ? header!.classList.add('is-sticky')
      : header!.classList.remove('is-sticky');

    for (let i = 0; i < categoryList.length; i++) {
      const element = document.getElementById(categoryList[i].id);
      let nextElement = null;
      if (i < categoryList.length - 1) {
        nextElement = document.getElementById(categoryList[i].id);
      }
      if (element == null) {
        continue;
      }
    }

    //   const elementToChange = document.getElementById(
    //     `selector-${categoryList[i].id}`
    //   );

    //   if (i == 0) {
    //     if (nextElement != null) {
    //       if (scrollTop <= nextElement!.offsetTop) {
    //         elementToChange?.classList.add('is-selected');
    //       } else {
    //         elementToChange?.classList.remove('is-selected');
    //       }
    //     } else {
    //       if (scrollTop >= element!.offsetTop) {
    //         elementToChange?.classList.add('is-selected');
    //       } else {
    //         elementToChange?.classList.remove('is-selected');
    //       }
    //     }
    //   } else {
    //     if (nextElement != null) {
    //       if (
    //         scrollTop >= element!.offsetTop &&
    //         scrollTop <= nextElement!.offsetTop
    //       ) {
    //         elementToChange?.classList.add('is-selected');
    //       } else {
    //         elementToChange?.classList.remove('is-selected');
    //       }
    //     } else {
    //       if (scrollTop >= element!.offsetTop) {
    //         elementToChange?.classList.add('is-selected');
    //       } else {
    //         elementToChange?.classList.remove('is-selected');
    //       }
    //     }
    //   }
    // }
  };

  return (
    <div
      className="category-section"
      id="container-categories"
      style={{
        zIndex: 99,
      }}
    >
      {selectorElements}
    </div>
  );
};

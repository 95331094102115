import { Box, Container, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import voidIllustration from '../../res/undraw_void_-3-ggu.svg';
import { BusinessData } from '../../models/business';
import { useRecoilState } from 'recoil';
import { loadingAtom } from '../../state/loading';
import { BrowserRouter, Route, Routes, useParams } from 'react-router-dom';
import firebase from '../../services/firebase';
import { toast } from 'react-toastify';
import Home from '../Home/Home';
import { Menu } from '../Menu/Menu';
import { ProductBase } from '../../models/product';
import ProductPage from '../ProductPage/ProductPage';
import { menuAtom } from '../../state/menuAtom';
import { LanguagesSpace } from '../../components/LanguagePicker/LanguagePicker';
import { registerVisitApi } from '../../api/customer';
import { MenuCreate } from '../../models/menu';
import { useTranslation } from 'react-i18next';
import { Plan } from '../../models/plan';


interface RestaurantRendererProps { }
export const RestaurantRenderer = (props: RestaurantRendererProps) => {
  const { t } = useTranslation();
  const [businessData, setBusinessData] = useState<null | BusinessData>(null);
  const [products, setProducts] = useState<null | ProductBase[]>(null);
  const [isLoading, setIsLoading] = useRecoilState(loadingAtom);

  const [menuState, setMenuState] = useRecoilState(menuAtom);

  const params = useParams();

  useEffect(() => {
    loadBusiness();
  }, []);

  const registerVisit = () => {
    const visited = localStorage.getItem('visited');

    if (!visited) {


      registerVisitApi(params.restaurantId as string).then(() => {
        localStorage.setItem(
          'visited',
          JSON.stringify({
            date: new Date(),
          })
        );
      }).catch((e) => {
        console.log(`An error has ocurred registering visit: ${e}`);
      });


    }
  };

  const loadProducts = (id: string) => {
    firebase
      .firestore()
      .collection('products')
      .where('businessID', '==', id)
      .orderBy('index', 'asc')
      .get()
      .then((snap) => {
        let products: any[] = [];

        snap.forEach((doc) => {
          let data = doc.data();
          data.id = doc.id;

          products.push(data);
        });

        setProducts(products);
      })
      .catch((e) => {
        console.log(e);
        toast.error(t('something_went_wrong_loading_the_menu'));
      });
  };

  const loadBusiness = async () => {
    setIsLoading(true);

    try {
      let snap = await firebase
        .firestore()
        .collection('business')
        .where('businessUrl', '==', params.restaurantId)
        .limit(1)
        .get();

      if (!snap.empty) {
        let menus = await firebase
          .firestore()
          .collection('business')
          .doc(snap.docs[0].id)
          .collection('menus')
          .orderBy('index')
          .get();

        let menusData: Array<MenuCreate & { products: Array<any> }> = [];
        if (!menus.empty) {
          menusData = JSON.parse(
            JSON.stringify(menus.docs.map((e) => ({ ...e.data(), id: e.id })))
          );

          for (let i = 0; i < menusData.length; i++) {
            if (menusData[i].type === 'native') {
              menusData[i].products = (
                await firebase
                  .firestore()
                  .collection('business')
                  .doc(snap.docs[0].id)
                  .collection('menus')
                  .doc(menusData[i].id)
                  .collection('products')
                  .orderBy('index')
                  .get()
              ).docs.map((e) => ({ ...e.data(), id: e.id }));
            }
          }
        }

        let data = snap.docs[0].data();
        setBusinessData(data as BusinessData);
        registerVisit();
        setIsLoading(false);

        if (data.plan) {
          if (data.plan.cancel_at_period_end === false) {
            getPlanData(data.plan.items[0]['product_id']);
          } else {
            setMenuState((state) => ({
              ...state,
              planData: {
                menuPDF: false,
                key: 'free',
                menus: 1,
                multilanguage: false,
                planName: 'Free',
                productImages: false,
                productVariations: false,
                translation: false,
                visits: 300,
              },
            }));
          }
        }

        setMenuState((state) => ({
          ...state,
          businessData: data as BusinessData,
          menus: menusData,
          language: (data as BusinessData).defaultLanguage as LanguagesSpace,
          // language: (() => {
          //   let language = '';

          //   for (let key of Object.keys(data.languages)) {
          //     if (data.languages[key].state === true) {
          //       language = key;
          //       break;
          //     }
          //   }

          //   return language;
          // })() as LanguagesSpace,
        }));
      } else {
        setIsLoading(false);
      }
    } catch (e) {
      console.log(e);
      toast.error(t('something_went_wrong'));
    }
  };

  const getPlanData = (id: string) => {
    firebase
      .firestore()
      .collection('planSpecs')
      .doc(id)
      .get()
      .then((d) => {
        if (d.exists) {
          setMenuState((state) => ({
            ...state,
            planData: d.data() as Plan,
          }));
        }
      })
      .catch((e) => {
        console.log(e);

        toast.error(t('something_went_wrong'));
      });
  };

  return (
    <Box>
      {!isLoading && businessData === null ? (
        <Stack alignItems={'center'} spacing={3}>
          <Typography variant="h4">Nothing here</Typography>
          <img
            src={voidIllustration}
            style={{
              maxWidth: '100%',
              width: '300px',
            }}
          />
        </Stack>
      ) : null}

      {businessData !== null ? (
        <Routes>
          <Route
            path="/"
            element={
              <Home
                sections={businessData.sections}
                cover={businessData.businessCoverImageUrl}
              />
            }
          />

          <Route path="/:sectionId" element={<Menu />} />

          <Route
            path="/:sectionId/:productId"
            element={<ProductPage product={menuState.productData} />}
          />
        </Routes>
      ) : null}
    </Box>
  );
};

import React, { useState } from 'react';
import './Product.css';
import { Box, CardActionArea, Chip, Typography } from '@mui/material';
import { Button } from '../../reusableComponents/Button/Button';
import { chooseMultilanguageValue } from '../../services/multiLanguague';
import { useRecoilState } from 'recoil';
import { menuAtom } from '../../state/menuAtom';
import { currencyList } from '../../services/currency';

import clam from '../../res/icons/clam.png';
import compound from '../../res/icons/compound.png';
import condiments from '../../res/icons/condiments.png';
import crab from '../../res/icons/crab-with-two-claws.png';
import fish from '../../res/icons/fish.png';
import egg from '../../res/icons/fried-egg.png';
import nut from '../../res/icons/hazelnut.png';
import leaf from '../../res/icons/leaf.png';
import milk from '../../res/icons/milk-bottle.png';
import peanut from '../../res/icons/peanut-butter.png';
import seed1 from '../../res/icons/seed-bag.png';
import seed2 from '../../res/icons/seed-bag-2.png';
import soybean from '../../res/icons/soybean.png';
import wheat from '../../res/icons/wheat-grain.png';

import vegetarian from '../../res/icons/vegetarian-food.png';
import vegan from '../../res/icons/vegan-food.png';
import spicy from '../../res/icons/spicy-food.png';
import gluten_free from '../../res/icons/gluten-free.png';
import bag from '../../res/icons/bag.png';
import { t } from 'i18next';
import { adminAtom } from '../../state/adminAtom';
import { LanguageGeneric, Languages } from '../../models/languages';
import { IoGitMerge } from 'react-icons/io5';

type ProductProp = {
  canOrder: boolean;
  productName: LanguageGeneric<string>;
  price: number;
  image: string;
  productDescription: LanguageGeneric<string>;
  allergens: string[];
  tags: string[];
  click?: Function;
};

export const Product = ({
  canOrder,
  productName,
  price,
  image,
  productDescription,
  allergens,
  tags,
  click,
}: ProductProp) => {
  const [quantity, setQuantity] = useState(0);
  const [menuState, setMenuState] = useRecoilState(menuAtom);

  const allergensData = [
    {
      label: t('celery'),
      icon: <img src={leaf} width={'15px'} color="#7c7c7c" />,
      value: 'celery',
    },
    {
      label: t('crustaceans'),
      value: 'crustaceans',
      icon: <img src={crab} width={'15px'} color="#7c7c7c" />,
    },
    {
      label: t('dairy'),
      value: 'dairy',
      icon: <img src={milk} width={'15px'} color="#7c7c7c" />,
    },
    {
      label: t('eggs'),
      value: 'eggs',
      icon: <img src={egg} width={'15px'} color="#7c7c7c" />,
    },
    {
      label: t('fish'),
      value: 'fish',
      icon: <img src={fish} width={'15px'} color="#7c7c7c" />,
    },
    {
      label: t('gluten'),
      value: 'gluten',
      icon: <img src={wheat} width={'15px'} color="#7c7c7c" />,
    },
    {
      label: t('lupins'),
      value: 'lupins',
      icon: <img src={seed1} width={'15px'} color="#7c7c7c" />,
    },
    {
      label: t('mollusks'),
      value: 'mollusks',
      icon: <img src={clam} width={'15px'} color="#7c7c7c" />,
    },
    {
      label: t('mustard'),
      value: 'mustard',
      icon: <img src={condiments} width={'15px'} color="#7c7c7c" />,
    },
    {
      label: t('peanuts'),
      value: 'peanuts',
      icon: <img src={peanut} width={'15px'} color="#7c7c7c" />,
    },
    {
      label: t('sesame'),
      value: 'sesame',
      icon: <img src={seed2} width={'15px'} color="#7c7c7c" />,
    },
    {
      label: t('nuts'),
      value: 'nuts',
      icon: <img src={nut} width={'15px'} color="#7c7c7c" />,
    },
    {
      label: t('soy'),
      value: 'soy',
      icon: <img src={soybean} width={'15px'} color="#7c7c7c" />,
    },
    {
      label: t('sulfites'),
      value: 'sulfites',
      icon: <img src={compound} width={'15px'} color="#7c7c7c" />,
    },
  ];

  const tagsData = [
    {
      label: t('vegetarian'),
      icon: <img src={vegetarian} width={'15px'} />,
      value: 'vegetarian',
    },
    {
      label: t('vegan'),
      icon: <img src={vegan} width={'15px'} />,
      value: 'vegan',
    },
    {
      label: t('spicy'),
      icon: <img src={spicy} width={'15px'} />,
      value: 'spicy',
    },
    {
      label: t('gluten_free'),
      icon: <img src={gluten_free} width={'15px'} />,
      value: 'gluten_free',
    },
    {
      label: t('ecological'),
      icon: <img src={bag} width={'15px'} />,
      value: 'ecological',
    },
  ];

  const AllergyChips = ({
    selectedAllergens,
  }: {
    selectedAllergens: string[];
  }) => {
    return (
      <Typography my={'auto'} mx={1} color={'#7c7c7c'} fontSize={'12px'}>
        {allergensData
          .filter((v) => selectedAllergens.includes(v.value))
          .map((item: any, index: number) => {
            return (
              item.label +
              (index <
              allergensData.filter((v) => selectedAllergens.includes(v.value))
                .length -
                1
                ? ', '
                : '.')
            );
          })}
      </Typography>
    );
  };

  const TagsChips = ({ selectedTags }: { selectedTags: string[] }) => {
    return (
      <Typography my={'auto'} mx={1} color={'#58a34e'} fontSize={'12px'}>
        {tagsData
          .filter((v) => selectedTags.includes(v.value))
          .map((item: any, index: number) => {
            return (
              item.label +
              (index <
              tagsData.filter((v) => selectedTags.includes(v.value)).length - 1
                ? ', '
                : '.')
            );
          })}
      </Typography>
    );
  };

  const onAddClicked = () => {
    setQuantity(quantity + 1);
  };

  const onAdd = () => {
    setQuantity(quantity + 1);
  };

  const onRemove = () => {
    if (quantity > 0) {
      setQuantity(quantity - 1);
    }
  };

  return (
    <Box
      sx={{
        borderRadius: '5px',
        overflow: 'hidden',
        mt: 1,
        width: '100%',
        maxWidth: '500px',
        border: '1px solid',
        borderColor: 'rgb(230, 230, 230)',
      }}
    >
      <CardActionArea
        disableRipple
        onClick={() => {
          if (click) {
            click();
          }
        }}
      >
        <Box
          display={'flex'}
          sx={{
            width: '100%',

            paddingTop: '20px',
            paddingLeft: '20px',
            paddingRight: '20px',

            justifyContent: 'space-between',
          }}
        >
          <div>
            <h2 className="title">
              {chooseMultilanguageValue(
                productName,
                menuState.language as keyof Languages,
                menuState.language as keyof Languages
              )}
            </h2>

            <p className="description">
              {chooseMultilanguageValue(
                productDescription,
                menuState.language as keyof Languages,
                menuState.language as keyof Languages
              )}
            </p>

            <Box display={'flex'}>
              <Typography
                sx={{
                  fontWeight: '700',
                  textAlign: 'start',
                  fontSize: '20px',
                }}
              >
                {
                  currencyList.filter(
                    (v) => v.value === menuState.businessData?.currency
                  )[0].symbol
                }{' '}
                {quantity > 0 ? price * quantity : price}
              </Typography>

              {quantity > 0 ? (
                <div className="unit-price-container">
                  <p className="unit-price">
                    {Intl.NumberFormat('es', { currency: '$' }).format(price)}{' '}
                    {'(per person)'}
                  </p>
                </div>
              ) : (
                <></>
              )}
            </Box>
          </div>

          {image !== '' ? (
            <div
              className="product-image"
              style={{
                backgroundImage: `url(${image})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                borderRadius: 'var(--border-radius)',
              }}
              onClick={(e) => e.preventDefault()}
            ></div>
          ) : null}
        </Box>

        {/*<div className="product-container-tools d-flex">
          {canOrder ? (
            quantity == 0 ? (
              <Button
                variant="outlined"
                className="add-to-cart-button px-3"
                onClick={onAddClicked}
                size="small"
              >
                Add
              </Button>
            ) : (
              <CounterController
                quantity={quantity}
                onAdd={onAdd}
                onRemove={onRemove}
              />
            )
          ) : null}
        </div>*/}

        <Box mb={0.5} ml={1} mr={1} mt={0.5}>
          <AllergyChips selectedAllergens={allergens} />
        </Box>

        <Box mb={2} ml={1} mr={1}>
          <TagsChips selectedTags={tags} />
        </Box>
      </CardActionArea>
    </Box>
  );
};

type CounterProp = {
  quantity: number;
  onAdd: () => void;
  onRemove: () => void;
};

const CounterController = ({ quantity, onAdd, onRemove }: CounterProp) => {
  return (
    <div className="d-flex w-100 justify-content-between tool-container">
      <Button
        variant="outlined"
        size="small"
        className="box-button"
        onClick={onRemove}
      >
        -
      </Button>

      <h2 className="quantity-text">{quantity}</h2>
      <Button
        variant="outlined"
        size="small"
        className="box-button"
        onClick={onAdd}
      >
        +
      </Button>
    </div>
  );
};

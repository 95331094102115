import { atom } from 'recoil';
import { BusinessData } from '../models/business';
import { UserData } from '../models/user';
import { ProductBase } from '../models/product';
import { LanguageGeneric, Languages } from '../models/languages';
import { Plan } from '../models/plan';
import { MenuCreate } from '../models/menu';

interface AdminAtomProps {
  businessData: BusinessData | null;
  userData: UserData | null;
  auth: boolean;
  language: string;
  languages: Languages;
  languagesISO: LanguageGeneric<string>;
  addSection: boolean;
  addCategory: boolean;
  products: ProductBase[];
  productData: ProductBase | null;
  addProduct: boolean;
  productsToUpdate: string[];
  menusToUpdate: MenuCreate[] | null;
  toUpdate: boolean;
  automaticCategory: string;
  automaticSection: string;
  businessCover: null | File;
  businessImage: null | File;
  src: null | string;
  aspect: number;
  coverEdit: boolean;
  logoEdit: boolean;
  sectionData: (LanguageGeneric<string> & { id: string }) | null;
  categoryData:
    | (LanguageGeneric<string> & { id: string; linkedSection: string })
    | null;
  planData: null | Plan;
  menuData:
    | null
    | (MenuCreate & { id?: string; pdfs?: LanguageGeneric<null | File> });
  plansViewer: boolean;
}

export const adminAtom = atom<AdminAtomProps>({
  key: 'adminState',
  default: {
    businessData: null,
    userData: null,
    auth: false,
    language: 'ES',
    addSection: false,
    addCategory: false,
    addProduct: false,
    products: [],
    productData: null,
    productsToUpdate: [],
    menusToUpdate: null,
    toUpdate: false,
    automaticCategory: '',
    automaticSection: '',
    businessCover: null,
    businessImage: null,
    src: null,
    coverEdit: false,
    logoEdit: false,
    aspect: 1 / 1,
    menuData: null,
    plansViewer: false,
    languages: {
      ES: {
        name: 'Spanish',
        state: false,
      },
      'ES-V': {
        name: 'Valencian',
        state: false,
      },
      'ES-C': {
        name: 'Catalan',
        state: false,
      },
      EN: {
        name: 'English',
        state: false,
      },
      IT: {
        name: 'Italian',
        state: false,
      },
      DE: {
        name: 'German',
        state: false,
      },
    },
    sectionData: null,
    categoryData: null,
    planData: null,
    languagesISO: {
      ES: 'es',
      EN: 'en',
      'ES-C': 'ca',
      'ES-V': 'ca',
      DE: 'de',
      IT: 'it',
    },
  },
});

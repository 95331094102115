import React, { ReactNode } from 'react';
import {
  FormControl,
  InputLabel,
  ListSubheader,
  ListSubheaderProps,
  MenuItem,
  MenuItemProps,
  Select as SelectMUI,
  SelectProps,
} from '@mui/material';
import { styled } from '@mui/material';

export type SelectGroupItem = {
  isGroup: boolean;
  name: string;
};

const CustomSelectStyled = styled(SelectMUI)(({ theme, error }) => {
  let colorBorder = error
    ? theme.palette.error.main
    : theme.palette.primary.main;
  return {
    '& .MuiInputBase-root': {
      border: 'none',
      marginTop: '0.4rem',
    },

    '& .MuiFormLabel-root': {
      display: 'block',
      position: 'relative',
      transform: 'translate(0,0)',
      color: theme.palette.text.primary,
      fontSize: '0.8rem',
    },

    '& .MuiInputBase-input': {
      outline: 'none',
      paddingLeft: '1.5rem',
      paddingRight: '1.5rem',
      borderRadius: 'var(--border-radius)',
      transition: '0.3s',
      border: `1.5px solid ${theme.palette.background.default}`,

      '&:focus': {
        border: `1.5px solid ${colorBorder}`,
        borderRadius: 'var(--border-radius)',
      },
    },

    '& .MuiFormControl-root': {
      border: 'none',
    },

    '& fieldset': { border: 'none' },

    '&:has(> input:-webkit-autofill)': {
      backgroundColor: 'red',
    },

    '& .MuiFormHelperText-root': {
      marginLeft: '0',
    },
  };
});

const CustomFormControlStyled: any = styled(FormControl)(({ theme }) => ({
  '& .MuiFormLabel-root': {
    display: 'block',
    position: 'relative',
    transform: 'translate(0,0)',
    color: theme.palette.text.primary,
    fontSize: '0.8rem',
    marginBottom: '0.4rem',
  },
}));

type CustomSelectProps = {
  labelText?: string;
  placeholder?: string;
  options?: Array<MenuItemProps & CustomMenuItemProps>;
};

type CustomMenuItemProps = {
  text?: string;
  isGroup?: boolean;
};

function MyListSubheader(
  props: ListSubheaderProps & { muiSkipListHighlight: boolean }
) {
  const { muiSkipListHighlight, ...other } = props;
  return <ListSubheader {...other} />;
}

export const Select: React.FC<CustomSelectProps & SelectProps> = ({
  labelText,
  placeholder,
  options,
  ...props
}) => {
  return (
    <CustomFormControlStyled fullWidth>
      <InputLabel>{labelText}</InputLabel>
      <CustomSelectStyled
        sx={{
          '& .MuiSelect-select .notranslate::after': placeholder
            ? {
                content: `"${placeholder}"`,
                opacity: 0.42,
              }
            : {},
        }}
        size="small"
        {...props}
      >
        {placeholder ? (
          <MenuItem selected disabled value="">
            <em>{placeholder}</em>
          </MenuItem>
        ) : null}

        {options?.map((e, i) => {
          if (e.isGroup) {
            return (
              <MyListSubheader key={i} muiSkipListHighlight>
                {e.text}
              </MyListSubheader>
            );
          }
          return (
            <MenuItem {...e} key={e.key} value={e.value} data-testid="some">
              {e.text}
            </MenuItem>
          );
        })}
      </CustomSelectStyled>
    </CustomFormControlStyled>
  );
};

import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { adminAtom } from '../../../../state/adminAtom';
import { TextInput } from '../../../../reusableComponents/TextInput/TextInput';
import { Modal } from '../../../../reusableComponents/Modal/Modal';
import {
  Clear,
  Menu,
  PictureAsPdf,
  Star,
  ViewList,
  ViewModule,
  ViewQuilt,
  Visibility,
} from '@mui/icons-material';
import { Button } from '../../../../reusableComponents/Button/Button';
import { Dropzone } from '../../../../reusableComponents/Dropzone/Dropzone';
import classNames from 'classnames';
import styles from './AddMenu.module.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import { PDFVisualizer } from '../PDFVisualizer/PDFVisualizer';
import { loadingAtom } from '../../../../state/loading';
import { toast } from 'react-toastify';
import { uploadToStorage } from '../../../../services/uploadToStorage';
import firebase from '../../../../services/firebase';
import { MenuCreate } from '../../../../models/menu';
import { Card } from '../../../../reusableComponents/Card/Card';
import {
  checkIfEmptyMultilanguageValue,
  checkIfNullMultilanguageValue,
  distributeValue,
  getMultilanguageValue,
  setMultilanguageValue,
} from '../../../../services/multiLanguague';
import { TabsContainer } from '../../../../reusableComponents/Tabs/Tabs';
import { BusinessData } from '../../../../models/business';
import { LanguageGeneric, Languages } from '../../../../models/languages';
import { Switch } from '../../../../reusableComponents/Switch/Switch';
import { getTranslationApi } from '../../../../api/translation';
import { useTranslation } from 'react-i18next';

// Correctly setting the workerSrc to a specific version
pdfjs.GlobalWorkerOptions.workerSrc =
  'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js';

interface AddMenuProps {
  close: Function;
  reload: Function;
}

export function AddMenu({ close, reload }: AddMenuProps) {
  const { t } = useTranslation();
  const [adminState, setAdminState] = useRecoilState(adminAtom);

  const [automaticTranslation, setAutomaticTranslation] = useState(false);

  const [defaultLangChanged, setDefaultLanguageChanged] = useState(false);

  const [languagesToTranslate, setLanguagesToTranslate] = useState({
    DE: {
      name: 'German',
      state: true,
    },
    ES: {
      name: 'Spanish',
      state: true,
    },
    'ES-V': {
      name: 'Valencian',
      state: true,
    },
    'ES-C': {
      name: 'Catalan',
      state: true,
    },
    IT: {
      name: 'Italian',
      state: true,
    },
    EN: {
      name: 'English',
      state: true,
    },
  } as Languages);

  const [modificationsOnMainLanguage, setModificationsOnMainLanguage] =
    useState(false);

  const [menuName, setMenuName] = useState(distributeValue<string>(''));
  const [menuDescription, setMenuDescription] = useState(
    distributeValue<string>('')
  );
  const [type, setType] = React.useState<'native' | 'pdf'>('native');
  const [pdf, setPdf] = React.useState<LanguageGeneric<File | null>>(
    distributeValue<null | File>(null)
  );
  const [isLoading, setIsLoading] = useRecoilState(loadingAtom);

  const [pdfVisualizer, setPdfVisualizer] = useState<boolean>(false);

  const [indexes, setIndexes] = useState(
    Object.keys((adminState.businessData as BusinessData).languages)

      .filter(
        (key) =>
          adminState.businessData?.languages[key as keyof Languages].state ===
          true
      )
      .sort()
      .map((key, i) => ({ lang: key, i }))
  );

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    nextView: 'native' | 'pdf'
  ) => {
    if (nextView !== null) {
      setType(nextView);
    }
  };

  const createMenu = async () => {
    let errorCheck = 0;
    let messages = [];

    if (checkIfEmptyMultilanguageValue(menuName)) {
      errorCheck = 1;
      messages.push(t('menu_name_cannot_be_blank'));
    }

    if (type === 'pdf' && checkIfNullMultilanguageValue(pdf)) {
      errorCheck = 1;
      messages.push(t('you_should_select_your_menu'));
    }

    if (errorCheck == 0) {
      try {
        setIsLoading(true);

        let sourceUrl: LanguageGeneric<string> = distributeValue<string>('');

        const id = firebase
          .firestore()
          .collection(`business`)
          .doc(adminState.businessData?.id)
          .collection('menus')
          .doc().id;

        if (type === 'pdf') {
          for (let key of Object.keys(pdf)) {
            if (pdf[key as keyof Languages] !== null) {
              sourceUrl[key as keyof Languages] = (await uploadToStorage(
                getMultilanguageValue(pdf, key as keyof Languages) as Blob,
                `/business/${adminState.businessData?.id}/menus/${id}`,
                setIsLoading,
                toast.info
              )) as string;
            }
          }
        }

        let obj: MenuCreate = {
          active: true,
          description: menuDescription,
          name: menuName,
          sourceUrl: sourceUrl,
          type: type,
          created: firebase.firestore.Timestamp.now(),
          updated: firebase.firestore.Timestamp.now(),
          sections: [],
          categories: [],
          index: 0,
        };

        if (sourceUrl === undefined) {
          delete obj['sourceUrl'];
        }

        let batch = firebase.firestore().batch();

        batch.update(
          firebase
            .firestore()
            .collection('business')
            .doc(adminState.businessData?.id),
          {
            menus: firebase.firestore.FieldValue.increment(1),
          }
        );

        batch.set(
          firebase
            .firestore()
            .collection('business')
            .doc(adminState.businessData?.id)
            .collection('menus')
            .doc(id),
          obj
        );

        await batch.commit();

        toast.success(t('menu_created'));
        close();
        reload();

        setIsLoading(false);
      } catch (e) {
        console.log(e);
        toast.error(t('something_went_wrong'));
        setIsLoading(false);
      }
    } else {
      for (let message of messages) {
        toast.error(message);
      }
    }
  };

  const getAutomaticTranslationBasedOnIndex = (i: number) => {
    if (
      adminState.planData?.translation &&
      languagesToTranslate[indexes[i].lang as keyof Languages].state === false
    ) {
      if (indexes[i].lang !== adminState.businessData?.defaultLanguage) {
        if (automaticTranslation) {
          let menuNameVal = getMultilanguageValue(
            menuName,
            adminState.businessData?.defaultLanguage as keyof Languages
          );

          let descriptionVal = getMultilanguageValue(
            menuDescription,
            adminState.businessData?.defaultLanguage as keyof Languages
          );

          let valuesToTranslate = [];

          if (menuNameVal.trim() !== '') {
            valuesToTranslate.push(menuNameVal);
          }

          if (descriptionVal.trim() !== '') {
            valuesToTranslate.push(descriptionVal);
          }

          if (valuesToTranslate.length > 0) {
            getTranslationApi(
              valuesToTranslate,
              adminState.languagesISO[indexes[i].lang as keyof Languages]
            )
              .then((r) => {
                if (r[0]) {
                  setMultilanguageValue(
                    r[0],
                    setMenuName,
                    indexes[i].lang as keyof Languages
                  );
                }

                if (r[1]) {
                  setMultilanguageValue(
                    r[1],
                    setMenuDescription,
                    indexes[i].lang as keyof Languages
                  );
                }

                let langObj: Languages = JSON.parse(
                  JSON.stringify(languagesToTranslate)
                );

                langObj[indexes[i].lang as keyof Languages].state = true;
                setLanguagesToTranslate(langObj);
              })
              .catch((e) => {
                console.log(e);
              });
          }
        }
      }
    }
  };

  return (
    <Modal
      open={true}
      onClose={() => {
        if (close) {
          close();
        }
      }}
    >
      <>
        <Typography variant="h4" textAlign={'center'}>
          {t('create_menu')}
        </Typography>

        <Typography mt={2} mb={1} variant="body2">
          {t('type')}
        </Typography>
        <ToggleButtonGroup
          orientation="horizontal"
          value={type}
          exclusive
          onChange={handleChange}
        >
          <ToggleButton value="native" aria-label="native" disableRipple>
            <Stack direction={'row'} spacing={1}>
              <Typography>{t('elements')}</Typography>
              <Menu />
            </Stack>
          </ToggleButton>
          <ToggleButton value="pdf" aria-label="pdf" disableRipple>
            <Stack direction={'row'} spacing={1}>
              <Typography>PDF</Typography>
              <PictureAsPdf />
            </Stack>
          </ToggleButton>
        </ToggleButtonGroup>

        <TabsContainer
          muiProps={{
            sx: {
              mt: 2,
            },
          }}
          defaultValue={
            indexes.filter(
              (v) => v.lang === adminState.businessData?.defaultLanguage
            )[0]?.i
          }
          listenIndexChange={getAutomaticTranslationBasedOnIndex}
          tabs={Object.keys((adminState.businessData as BusinessData).languages)

            .filter(
              (key) =>
                adminState.businessData?.languages[key as keyof Languages]
                  .state === true
            )
            .sort()
            .map((key: string, i: number) => ({
              label:
                adminState.businessData?.languages[key as keyof Languages].name,
              key: i,
              icon:
                key === adminState.businessData?.defaultLanguage ? (
                  <Star />
                ) : undefined,
            }))}
        >
          {Object.keys((adminState.businessData as BusinessData).languages)
            .filter(
              (key) =>
                adminState.businessData?.languages[key as keyof Languages]
                  .state === true
            )
            .sort()
            .map((lang: string, i: number) => {
              return (
                <TabsContainer.TabPanel key={i}>
                  <PDFVisualizer
                    url={URL.createObjectURL(
                      getMultilanguageValue(pdf, lang as keyof Languages)
                        ? (getMultilanguageValue(
                            pdf,
                            lang as keyof Languages
                          ) as Blob | MediaSource)
                        : new Blob()
                    )}
                    open={pdfVisualizer}
                    close={() => {
                      setPdfVisualizer(false);
                    }}
                  />
                  {adminState.businessData?.defaultLanguage ? (
                    lang !== adminState.businessData?.defaultLanguage ? (
                      <Stack direction={'row'} justifyContent={'end'}>
                        <Switch
                          labelText={t('automatic_translation')}
                          checked={automaticTranslation}
                          onChange={(e) => {
                            setAutomaticTranslation(e.target.checked);
                          }}
                        />
                      </Stack>
                    ) : null
                  ) : null}

                  <Stack mt={1} spacing={2}>
                    <TextInput
                      fullWidth
                      label={t('name')}
                      placeholder={t('write_the_name_of_the_menu')}
                      value={getMultilanguageValue(
                        menuName,
                        lang as keyof Languages
                      )}
                      type="text"
                      onChange={(el) => {
                        setMultilanguageValue(
                          el.currentTarget.value,
                          setMenuName,
                          lang as keyof Languages
                        );

                        let langObj: Languages = JSON.parse(
                          JSON.stringify(languagesToTranslate)
                        );

                        Object.keys(langObj).forEach((key: any) => {
                          langObj[key as keyof Languages].state = false;
                        });

                        setLanguagesToTranslate(langObj);
                      }}
                      inputProps={{
                        maxLength: 20,
                      }}
                    />

                    <TextInput
                      fullWidth
                      multiline
                      rows={3}
                      label={t('description')}
                      placeholder={t('write_the_description_of_the_menu')}
                      value={getMultilanguageValue(
                        menuDescription,
                        lang as keyof Languages
                      )}
                      onChange={(el) => {
                        setMultilanguageValue(
                          el.currentTarget.value,
                          setMenuDescription,
                          lang as keyof Languages
                        );

                        let langObj: Languages = JSON.parse(
                          JSON.stringify(languagesToTranslate)
                        );

                        Object.keys(langObj).forEach((key: any) => {
                          langObj[key as keyof Languages].state = false;
                        });

                        setLanguagesToTranslate(langObj);
                      }}
                      inputProps={{
                        maxLength: 200,
                      }}
                    />
                  </Stack>

                  {type === 'pdf' ? (
                    <>
                      <Typography mt={2} color={'GrayText'}>
                        {t(
                          'if_you_already_have_your_menu_in_a_pdf_just_upload_the_file'
                        )}
                      </Typography>

                      <Box mt={1}>
                        {getMultilanguageValue(pdf, lang as keyof Languages) ===
                        null ? (
                          <Dropzone
                            acceptedFiles={['application/pdf']}
                            showPreviewsInDropzone={true}
                            dropzoneClass={classNames(
                              styles['add-menu-dropzone']
                            )}
                            dropzoneText="Drag & Drop"
                            onChange={(f) => {
                              if (f.length > 0) {
                                setMultilanguageValue(
                                  f[0],
                                  setPdf,
                                  lang as keyof Languages
                                );
                                setPdfVisualizer(true);
                              }
                            }}
                            filesLimit={0}
                            showPreviews={false}
                          />
                        ) : (
                          <Stack direction={'row'} spacing={1}>
                            <Button
                              variant="outlined"
                              onClick={() => {
                                setPdfVisualizer(true);
                              }}
                              endIcon={<Visibility />}
                            >
                              {
                                getMultilanguageValue(
                                  pdf,
                                  lang as keyof Languages
                                )?.name
                              }
                            </Button>

                            <IconButton
                              disableRipple
                              onClick={() => {
                                setMultilanguageValue(
                                  null,
                                  setPdf,
                                  lang as keyof Languages
                                );
                              }}
                            >
                              <Clear />
                            </IconButton>
                          </Stack>
                        )}
                      </Box>
                    </>
                  ) : null}
                  {/* {getMultilanguageValue(pdf, lang as keyof Languages) !==
                  null ? (
                    <Stack direction={'row'} justifyContent={'center'} mt={1}>
                      <Button
                        variant="outlined"
                        endIcon={<Visibility />}
                        onClick={() => {
                          setPdfVisualizer(true);
                        }}
                      >
                        Preview
                      </Button>
                    </Stack>
                  ) : null} */}
                </TabsContainer.TabPanel>
              );
            })}
        </TabsContainer>

        <Button
          fullWidth
          size="large"
          onClick={() => {
            createMenu();
          }}
          sx={{
            mt: 3,
          }}
        >
          {t('create')}
        </Button>
      </>
    </Modal>
  );
}

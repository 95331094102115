import { Add, Close, Delete } from '@mui/icons-material';
import { Box, Drawer, IconButton, Stack, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MultiInputTimeRangeField } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import React, { useEffect, useState } from 'react';
import { Button } from '../../../../../reusableComponents/Button/Button';
import { useTranslation } from 'react-i18next';

interface ScheduleDrawerProps {
  open: boolean;
  weekDay: string;
  close: Function;
}

export const ScheduleDrawer = ({
  open,
  weekDay,
  close,
}: ScheduleDrawerProps) => {
  const { t } = useTranslation();
  const [hours, setHours] = useState<[unknown, unknown][]>([]);

  useEffect(() => {
    console.log(hours);
  }, [hours]);

  return (
    <Drawer
      open={open}
      anchor="right"
      elevation={0}
      sx={{
        '& .MuiDrawer-paper': {
          borderTopLeftRadius: 'var(--border-radius)',
          borderBottomLeftRadius: 'var(--border-radius)',
          xs: {
            minWidth: '100%',
            borderTopLeftRadius: '0',
            borderBottomLeftRadius: '0',
          },
          sm: {
            minWidth: '70%',
            borderTopLeftRadius: 'var(--border-radius)',
            borderBottomLeftRadius: 'var(--border-radius)',
          },
          md: {
            minWidth: '30%',
            borderTopLeftRadius: 'var(--border-radius)',
            borderBottomLeftRadius: 'var(--border-radius)',
          },
          lg: {
            minWidth: '350px',
            borderTopLeftRadius: 'var(--border-radius)',
            borderBottomLeftRadius: 'var(--border-radius)',
          },
          xl: {
            minWidth: '400px',
            borderTopLeftRadius: 'var(--border-radius)',
            borderBottomLeftRadius: 'var(--border-radius)',
          },
        },
      }}
      onClose={() => {
        close();
      }}
    >
      <Box
        sx={{
          p: 5,
        }}
      >
        <IconButton
          aria-label="close"
          onClick={() => {
            close();
          }}
          sx={{
            position: 'absolute',
            right: 10,
            top: 10,
            zIndex: 99,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <Typography variant="h4">{weekDay}</Typography>

        <Stack mt={3} spacing={2}>
          {hours.map((e, i) => {
            return (
              <Stack
                spacing={1}
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs} key={i}>
                  <DemoContainer
                    components={[
                      'MultiInputTimeRangeField',
                      'SingleInputTimeRangeField',
                    ]}
                  >
                    <MultiInputTimeRangeField
                      sx={{
                        '&.MuiMultiInputTimeRangeField-root': {
                          alignItems: 'center',
                        },
                      }}
                      value={e}
                      onChange={(value) => {
                        const hoursArr = [...hours];
                        hoursArr[i] = value;
                        setHours(hoursArr);
                      }}
                      slotProps={{
                        textField: ({ position }) => ({
                          label: position === 'start' ? 'From' : 'To',
                          sx: {
                            textAlign: 'left',
                            '& .MuiInputBase-root': {
                              marginTop: '0.4rem',
                              borderRadius: 'var(--border-radius)',
                              //  backgroundColor: theme.palette.background.default,
                              border: `1.5px solid var(--mui-palette-primary-main)`,
                              transition: '0.3s',
                              paddingRight: '0.5rem',
                              paddingLeft: '0.5rem',

                              textarea: {
                                padding: 0,
                                paddingLeft: '1rem',
                                paddingRight: '1rem',
                              },

                              '&:hover': {
                                borderColor: 'var(--mui-palette-primary-main)',
                              },

                              '& fieldset': {
                                border: 'none',
                              },
                            },

                            ':-webkit-autofill': {
                              WebkitBoxShadow: '0 0 0 1000px white inset',
                            },

                            '& .MuiFormLabel-root': {
                              display: 'block',
                              position: 'relative',
                              transform: 'translate(0,0)',
                              color: 'var(--mui-palette-primary-main)',
                              fontSize: '0.8rem',
                            },

                            '& .MuiInputBase-input': {
                              paddingLeft: 'var(--border-radius)',
                              paddingRight: 'var(--border-radius)',
                              borderRadius: 'var(--border-radius)',

                              ':-webkit-autofill': {
                                backgroundColor:
                                  'var(--mui-palette-primary-main)',
                              },
                            },

                            '&:has(> input:-webkit-autofill)': {
                              backgroundColor: 'red',
                            },

                            '& .MuiFormHelperText-root': {
                              marginLeft: '0',
                            },
                          },
                          size: 'small',
                        }),
                        separator: undefined,
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>

                <IconButton
                  onClick={() => {
                    const hoursArr = [...hours];
                    hoursArr.splice(i, 1);
                    setHours(hoursArr);
                  }}
                >
                  <Delete />
                </IconButton>
              </Stack>
            );
          })}
        </Stack>

        <Stack direction={'row'} justifyContent={'center'} mt={3}>
          <Button
            variant="outlined"
            endIcon={<Add />}
            onClick={() => {
              const hoursArr = [...hours];
              hoursArr.push([null, null]);
              setHours(hoursArr);
            }}
          >
            {t('add')}
          </Button>
        </Stack>
      </Box>
    </Drawer>
  );
};

import {
  Box,
  CardContent,
  Chip,
  Grid,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Card } from '../../../../reusableComponents/Card/Card';
import { CardFooter, CardHeader } from 'react-bootstrap';
import { useRecoilState } from 'recoil';
import { adminAtom } from '../../../../state/adminAtom';
import { Button } from '../../../../reusableComponents/Button/Button';
import {
  CardMembershipOutlined,
  Done,
  StyleOutlined,
} from '@mui/icons-material';
import { TextInput } from '../../../../reusableComponents/TextInput/TextInput';
import {
  customerPortalApi,
  subscribeApi,
  updateSubscriptionApi,
} from '../../../../api/pricing';
import { toast } from 'react-toastify';
import { loadingAtom } from '../../../../state/loading';
import firebase from '../../../../services/firebase';
import { useTranslation } from 'react-i18next';
import { PlansViewer } from '../../PlansViewer/PlansViewer';

export const MyAccount = () => {
  const { t } = useTranslation();
  const [adminState, setAdminState] = useRecoilState(adminAtom);
  const [isLoading, setIsloading] = useRecoilState(loadingAtom);
  const [plans, setPlans] = useState(false);
  return adminState.businessData ? (
    <Box
      sx={{
        pt: '64px',
      }}
    >
      {
        <PlansViewer
          open={plans}
          currentPlan={adminState.planData?.key}
          selectPlan={(plan_key: string) => {
            if (
              adminState.businessData?.plan.status !== 'canceled' &&
              adminState.planData?.key !== 'free'
            ) {
              setIsloading(true);

              updateSubscriptionApi(plan_key)
                .then(() => {
                  setIsloading(false);
                })
                .catch((e) => {
                  console.log(e);
                  setIsloading(false);
                  toast.error(t('something_went_wrong'));
                });
            } else {
              setIsloading(true);

              subscribeApi(plan_key as any)
                .then(() => {
                  setIsloading(false);
                })
                .catch((e) => {
                  console.log(e);
                  setIsloading(false);
                });
            }
          }}
          onClose={() => {
            setPlans(false);
          }}
        />
      }
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Card>
            <CardHeader>
              <Typography textAlign={'center'} variant="h5">
                {t('info')}
              </Typography>
            </CardHeader>

            <CardContent>
              <Stack spacing={2}>
                <TextInput
                  label={t('name')}
                  placeholder={t('write_your_first_name')}
                  sx={{
                    textAlign: 'left',
                  }}
                />

                <TextInput
                  label={t('last_name')}
                  placeholder={t('write_your_last_name')}
                  sx={{
                    textAlign: 'left',
                  }}
                />

                <TextInput
                  label={t('email')}
                  disabled
                  placeholder="something@gmail.com"
                  value={firebase.auth().currentUser?.email}
                  sx={{
                    textAlign: 'left',
                  }}
                />

                <Button
                  variant="outlined"
                  onClick={() => {
                    setIsloading(true);
                    firebase
                      .auth()
                      .sendPasswordResetEmail(
                        firebase.auth().currentUser?.email as string
                      )
                      .then(() => {
                        setIsloading(false);
                        toast.info(t('password_reset_was_sent_to_your_email'));
                      })
                      .catch((e) => {
                        console.log(e);
                        toast.error(t('something_went_wrong'));
                        setIsloading(false);
                      });
                  }}
                >
                  {t('reset_password')}
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Card>
            <CardHeader>
              <Typography textAlign={'center'} variant="h5">
                Plan{' '}
                <Chip
                  label={
                    adminState.planData ? adminState.planData?.planName : 'Free'
                  }
                  size="medium"
                  color="secondary"
                  sx={{
                    fontSize: '1.2rem',
                  }}
                />
              </Typography>
            </CardHeader>
            {adminState.planData ? (
              <CardContent>
                <Stack spacing={2}>
                  <Box>
                    <Typography>
                      {t('visits')}:{' '}
                      <b>
                        {adminState.businessData?.visits} /{' '}
                        {adminState.planData !== null
                          ? adminState.planData?.visits > 9999999
                            ? `${t('infinite')}`
                            : adminState.planData?.visits
                          : ''}
                      </b>{' '}
                      | {t('month')}
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      value={
                        (adminState.businessData?.visits as number) /
                        (adminState.planData?.visits as number)
                      }
                      sx={{ mt: 1 }}
                    />
                  </Box>

                  <Box>
                    <Typography>
                      {t('menus')}:{' '}
                      <b>
                        {Number(
                          adminState.businessData?.menus
                            ? adminState.businessData?.menus
                            : 0
                        )}{' '}
                        /{' '}
                        {adminState.planData !== null
                          ? adminState.planData?.menus > 9999999
                            ? `${t('infinite')}`
                            : adminState.planData?.menus
                          : ''}
                      </b>{' '}
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      value={
                        (Number(
                          adminState.businessData?.menus
                            ? adminState.businessData?.menus
                            : 0
                        ) /
                          Number(adminState.planData?.menus)) *
                        100
                      }
                      sx={{ mt: 1 }}
                    />
                  </Box>

                  {adminState.planData?.menuPDF ? (
                    <Box>
                      <Typography>
                        {t('Menu PDF')} <Done color="success" />
                      </Typography>
                    </Box>
                  ) : null}

                  {adminState.planData?.productImages ? (
                    <Box>
                      <Typography>
                        {t('product_images')} <Done color="success" />
                      </Typography>
                    </Box>
                  ) : null}

                  {adminState.planData?.productVariations ? (
                    <Box>
                      <Typography>
                        {t('product_variations')} <Done color="success" />
                      </Typography>
                    </Box>
                  ) : null}

                  {adminState.planData?.multilanguage ? (
                    <Box>
                      <Typography>
                        {t('multilanguage')} <Done color="success" />
                      </Typography>
                    </Box>
                  ) : null}

                  {adminState.planData?.translation ? (
                    <Box>
                      <Typography>
                        {t('translation')} <Done color="success" />
                      </Typography>
                    </Box>
                  ) : null}
                </Stack>
              </CardContent>
            ) : null}

            <CardFooter>
              <Stack justifyContent={'center'} spacing={1}>
                <Button
                  size="large"
                  variant="outlined"
                  startIcon={<CardMembershipOutlined />}
                  onClick={() => {
                    setIsloading(true);
                    customerPortalApi()
                      .then((d) => {
                        window.location.href = d.data.url;
                        setIsloading(false);
                      })
                      .catch((e) => {
                        console.log(e);
                        toast.error(t('something_went_wrong'));
                        setIsloading(false);
                      });
                  }}
                >
                  {t('manage_subscription')}
                </Button>
                <Button
                  size="large"
                  startIcon={<StyleOutlined />}
                  onClick={() => {
                    setPlans(true);
                  }}
                >
                  {t('see_plans')}
                </Button>
              </Stack>
            </CardFooter>
          </Card>
        </Grid>
      </Grid>
    </Box>
  ) : null;
};

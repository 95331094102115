import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import firebase from '../../services/firebase';
import { toast } from 'react-toastify';
import { BusinessData } from '../../models/business';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Auth } from './Auth/Auth';
import { Panel } from './Panel/Panel';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { adminAtom } from '../../state/adminAtom';
import { useLocation } from 'react-router-dom';
import { Plan } from '../../models/plan';
import { useTranslation } from 'react-i18next';

interface AdminProps {}

export function Admin(props: AdminProps) {
  const { t } = useTranslation();
  let _authListener: undefined | firebase.Unsubscribe = undefined;
  let _businessData: any;

  const [adminState, setAdminState] = useRecoilState(adminAtom);

  const [auth, setAuth] = useState(false);
  const [userData, setUserData] = useState(null);
  const [businessData, setBusinessData] = useState<null | BusinessData>(null);
  const [loaded, setLoaded] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();

  const loadData = (u: firebase.User) => {
    firebase
      .firestore()
      .collection('affiliates')
      .doc(u.uid)
      .get()
      .then((doc: any) => {
        if (doc.exists) {
          let data = doc.data();
          data.id = doc.id;

          firebase
            .firestore()
            .collection('business')
            .doc(data.businessID)
            .get()
            .then((doc2: any) => {
              let data2 = doc2.data();
              data2.id = doc2.id;

              mountBusinessData(data2.id);

              setAdminState((state) => ({
                ...state,
                userData: data,
                auth: true,
                businessData: data2,
              }));
            })
            .catch((e) => {
              console.log(e);
              toast.error(t('something_went_wrong'));
            });
        } else {
          toast.error(t('user_doesnt_exist'));
          firebase.auth().signOut();
          setLoaded(true);
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error(t('something_went_wrong'));
      });
  };

  const mountBusinessData = (id: string) => {
    if (_businessData !== undefined) {
      _businessData(); // Clean up the listener
    }

    _businessData = firebase
      .firestore()
      .collection('business')
      .doc(id)
      .onSnapshot(
        (doc: any) => {
          let data = doc.data();
          data.id = doc.id;

          if (data.plan === null || data.plan === undefined) {
            navigate('/register');
          }

          if (data.plan !== null) {
            if (
              data.plan.cancel_at_period_end === false &&
              data.plan.status !== 'canceled'
            ) {
              getPlanData(data.plan.items[0]['product_id']);
            } else {
              setAdminState((state) => ({
                ...state,
                planData: {
                  menuPDF: false,
                  key: 'free',
                  menus: 1,
                  multilanguage: false,
                  planName: 'Free',
                  productImages: false,
                  productVariations: false,
                  translation: false,
                  visits: 300,
                },
              }));
            }
          }
          setBusinessData(data);
          setAdminState((state) => ({
            ...state,
            businessData: data,
            language: data.defaultLanguage,
            auth: true,
          }));
          setLoaded(true);
        },
        (e) => {
          console.log(e);
          toast.error(t('something_went_wrong'));
        }
      );
  };

  const getPlanData = (id: string) => {
    const planData = firebase
      .firestore()
      .collection('planSpecs')
      .doc(id)
      .get()
      .then((d) => {
        if (d.exists) {
          setAdminState((state) => ({
            ...state,
            planData: d.data() as Plan,
          }));
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error(t('something_went_wrong'));
      });
  };

  useEffect(() => {
    _authListener = firebase.auth().onAuthStateChanged(
      (u) => {
        if (u) {
          loadData(u);
        } else {
          setAdminState((state) => ({
            ...state,
            userData: null,
            businessData: null,
            auth: false,
          }));
          setAuth(false);
          setLoaded(true);
          navigate('/admin/login');

          if (_businessData !== undefined) {
            _businessData();
          }
        }
      },
      (e) => {
        console.log(e);
        toast.error(t('something_went_wrong'));
      }
    );

    return () => {
      if (_authListener !== undefined) {
        _authListener();
      }

      if (_businessData !== undefined) {
        _businessData();
      }
    };
  }, []);

  useEffect(() => {
    if (adminState.auth && adminState.businessData && loaded) {
      if (!location.pathname.includes('/admin/panel')) {
        navigate('/admin/panel/menu');
      }
    }

    if (adminState.auth) {
      if (adminState.auth !== auth) {
        setAuth(adminState.auth);
      }
    }
  }, [adminState.businessData, adminState.auth, loaded]);

  return loaded ? (
    <>
      <Routes>
        <Route
          path=""
          element={
            adminState.auth ? (
              <Navigate to={'/admin/panel/menu'} />
            ) : (
              <Navigate to={'/admin/login'} />
            )
          }
        />
        <Route path="/login" element={<Auth />} />
        <Route
          path="/panel"
          element={
            auth ? (
              <Panel
                openAlert={() => {}}
                userData={adminState.userData}
                addToast={() => {}}
                placeholder={''}
                setCoords={() => {}}
              />
            ) : (
              <Navigate to={'/admin/login'} />
            )
          }
        />
        <Route
          path="/panel/*"
          element={
            auth ? (
              <Panel
                openAlert={() => {}}
                userData={adminState.userData}
                addToast={() => {}}
                placeholder={''}
                setCoords={() => {}}
              />
            ) : (
              <Navigate to={'/admin/login'} />
            )
          }
        />
      </Routes>
    </>
  ) : null;
}

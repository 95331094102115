import {
  Grid,
  IconButton,
  Menu,
  Stack,
  Typography,
  MenuItem as MenuItemMUI,
  Box,
} from '@mui/material';
import React, { useState } from 'react';
import { Card } from '../../../../../reusableComponents/Card/Card';
import { MoreVertOutlined, Preview, Restaurant } from '@mui/icons-material';
import { Switch } from '../../../../../reusableComponents/Switch/Switch';
import { useTranslation } from 'react-i18next';

interface MenuItemProps {
  name: string;
  active: boolean;
  handleChangeActive: Function;
  handleDelete?: Function;
  openPdfVisualizer?: Function;
  navigate?: Function;
}

export function MenuItem({
  name,
  active,
  handleDelete,
  openPdfVisualizer,
  navigate,
  handleChangeActive,
}: MenuItemProps) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);
  return (
    <Card
      sx={{
        p: 4,
        py: 2,
        border: '1px solid lightgrey',
      }}
    
    >
      <Grid container spacing={1}>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <Stack
            direction={'row'}
            spacing={1}
            height={'100%'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <Restaurant />
              <Typography>{name}</Typography>
            </Stack>

            {openPdfVisualizer ? (
              <IconButton
                onClick={() => {
                  if (openPdfVisualizer) {
                    openPdfVisualizer();
                  }
                }}
              >
                <Preview />
              </IconButton>
            ) : null}
          </Stack>
        </Grid>
        {/* <Grid item xs={4} sm={4} md={4} lg={4} xl={4}></Grid> */}
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <Stack direction={'row'} justifyContent={'space-between'} spacing={1}>
            <Switch
              labelText={
                true === true ? '' : active ? t('active') : t('disabled')
              }
              checked={active}
              onChange={(el) => {
                handleChangeActive(el.currentTarget.checked);
              }}
            />

            <Box>
              <IconButton onClick={openMenu} size="small">
                <MoreVertOutlined />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={closeMenu}
              >
                <MenuItemMUI
                  onClick={() => {
                    //   if (setProductData) {
                    //     setProductData();
                    //   }
                    //   if (setAddProduct) {
                    //     setAddProduct(true);
                    //   }
                    //   closeMenu();
                    if (navigate) {
                      navigate();
                    }
                  }}
                >
                  {t('edit')}
                </MenuItemMUI>
                <MenuItemMUI
                  onClick={() => (handleDelete ? handleDelete() : ' ')}
                >
                  {t('delete')}
                </MenuItemMUI>
              </Menu>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
}

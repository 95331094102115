import React, { useEffect, useRef, useState } from 'react';
import { Box, Drawer } from '@mui/material';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';

// Correctly setting the workerSrc to a specific version
pdfjs.GlobalWorkerOptions.workerSrc =
  'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js';

// pdfjs.GlobalWorkerOptions.workerSrc =
//   'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js';

interface PDFContainerProps {
  url: string;
}

export function PDFContainer({ url }: PDFContainerProps) {
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState<null | number>(null);
  const parentRef = useRef<any>(null); // Reference to the parent container
  const [maxWidth, setMaxWidth] = useState(0); // State to hold the max width for the child

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  const updateMaxWidth = () => {
    // Assuming parentRef.current is directly dependent on window size
    if (parentRef.current) {
      const newMaxWidth = parentRef.current.offsetWidth; // Adjust 20 as needed for margins, etc.
      setMaxWidth(newMaxWidth);
    }
  };

  useEffect(() => {
    // Update maxWidth on mount to ensure correct initial value
    updateMaxWidth();

    // Add event listener for window resize
    window.addEventListener('resize', updateMaxWidth);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', updateMaxWidth);
  }, []); // Empty dependency array ensures this effect runs only once on mount

  useEffect(() => {
    if (parentRef.current !== null) {
      updateMaxWidth();
    }
  }, [parentRef.current]);
  return (
    <Box
      style={{ width: '100%' }}
      ref={parentRef}
      sx={{
        '& .MuiBox-root': {
          width: '100% !important',
          height: '100% !important',
          position: 'relative',
        },

        '& .react-pdf__Page__textContent': {
          display: 'none',
        },
      }}
    >
      <Document
        file={url}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={
          <Box
            sx={{
              width: '100%',
              height: '100vh',
              backgroundColor: 'lightgrey',
            }}
          ></Box>
        }
      >
        {Array.from({ length: numPages as number }).map((e, i) => {
          return <Page pageNumber={i + 1} width={maxWidth} />;
        })}
        {/* <Page pageNumber={pageNumber} width={maxWidth} /> */}
      </Document>
    </Box>
  );
}

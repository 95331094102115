import {
  Box,
  Container,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import PricingCard from '../Register/PricingCard/PricingCard';
import { Close } from '@mui/icons-material';
import { Switch } from '../../../reusableComponents/Switch/Switch';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { loadingAtom } from '../../../state/loading';
import { subscribeApi } from '../../../api/pricing';

interface PlansViewerProps {
  open: boolean;
  onClose: Function;
  selectPlan: Function;
  currentPlan?: string;
}

export function PlansViewer({
  open,
  onClose,
  selectPlan,
  currentPlan,
}: PlansViewerProps) {
  const { t } = useTranslation();
  const [yearly, setYearly] = useState<boolean>(false);

  return (
    <Drawer
      open={open}
      onClose={() => {
        onClose();
      }}
      sx={{
        width: '100vw',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: '100vw',
          boxSizing: 'border-box',
          p: { xs: 3, sm: 3, md: 4, lg: 5, xl: 5 },
        },
      }}
    >
      <div
        style={{
          position: 'relative',
        }}
      >
        <IconButton
          aria-label="close"
          onClick={() => {
            onClose();
          }}
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            zIndex: 99,
            //  transform: 'translate(40%, -40%)',
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </div>
      <Typography variant="h4" align="center" component="h2">
        PRICING
      </Typography>

      <Stack
        sx={{
          mt: 5,
        }}
        direction={'row'}
        justifyContent={'center'}
      >
        <Switch
          labelText={t('yearly')}
          checked={yearly}
          color="secondary"
          onChange={(el) => {
            setYearly(el.target.checked as boolean);
          }}
        />
      </Stack>

      {yearly ? (
        <Typography mt={2} align="center" variant="h5" color={'grey'}>
          15% {t('discount')}
        </Typography>
      ) : null}

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
          gap: 3,
          mt: 5,
          mx: 'auto',
          maxWidth: '100%',
        }}
      >
        <PricingCard
          title={t('always_free')}
          features={[`1 ${t('menu')}`, `300 ${t('visits')} / ${t('month')}`]}
          description=""
          price={t('free')}
          label={t('free_uppercase')}
          buttonText={currentPlan == 'free' ? '' : t('choose_uppercase')}
          headerColor="white"
          onClick={() => {
            selectPlan('free');
            onClose();
          }}
        />

        <PricingCard
          title={t('basic')}
          features={[
            `3 ${t('menus')}`,
            `2000 ${t('visits')} / ${t('month')}`,
            t('product_images_and_variations'),
            t('basic_support'),
          ]}
          description=""
          price={yearly ? '59.99' : '5.99'}
          label={t('starter')}
          headerColor="white"
          buttonText={
            currentPlan == 'basic-yearly' || currentPlan == 'basic-monthly'
              ? ''
              : t('choose_uppercase')
          }
          onClick={() => {
            selectPlan('basic' + '-' + (yearly ? 'yearly' : 'monthly'));
            onClose();
          }}
          frequency={yearly ? 'year' : 'month'}
        />

        <PricingCard
          title={t('pro')}
          features={[
            `15 ${t('menus')}`,
            t('unlimited_visits'),
            t('product_images_and_variations'),
            t('multilanguage'),
            t('pdf_menus'),
            t('customized_support'),
          ]}
          description=""
          price={yearly ? '99.99' : '9.99'}
          previousPrice={yearly ? '120.00' : '12.00'}
          label={t('most_common')}
          buttonText={
            currentPlan == 'pro-yearly' || currentPlan == 'pro-monthly'
              ? ''
              : t('choose_uppercase')
          }
          onClick={() => {
            selectPlan('pro' + '-' + (yearly ? 'yearly' : 'monthly'));
            onClose();
          }}
          headerColor="secondary.main"
          frequency={yearly ? 'year' : 'month'}
        />
      </Box>
    </Drawer>
  );
}

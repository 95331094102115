import { Box, Chip, Grid } from '@mui/material';
import React, { useState } from 'react';
import { Switch } from '../../../../../reusableComponents/Switch/Switch';
import TimeRangePicker from '@wojtekmaj/react-timerange-picker';
import '@wojtekmaj/react-timerange-picker/dist/TimeRangePicker.css';
import 'react-clock/dist/Clock.css';
import styles from './ScheduleItem.module.css';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MultiInputTimeRangeField } from '@mui/x-date-pickers-pro/MultiInputTimeRangeField';
import { SingleInputTimeRangeField } from '@mui/x-date-pickers-pro/SingleInputTimeRangeField';
import { ScheduleDrawer } from '../ScheduleDrawer/ScheduleDrawer';
import { Button } from '../../../../../reusableComponents/Button/Button';
import { useTranslation } from 'react-i18next';

interface ScheduleProps {
  dayName: string;
  onChange?: ((value: Value) => void) | undefined;
  onSwitchChange?: ((v: boolean) => void) | undefined;
  value?: Value;
  check?: boolean;
}
type ValuePiece = Date | string | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

export const ScheduleItem = ({
  dayName,
  onChange,
  value,
  check,
  onSwitchChange,
}: ScheduleProps) => {
  const { t } = useTranslation();
  const [startTime, setStartTime] = React.useState(null);
  const [endTime, setEndTime] = React.useState(null);

  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <>
      <ScheduleDrawer
        open={openDrawer}
        weekDay={dayName}
        close={() => {
          setOpenDrawer(false);
        }}
      />
      <Grid container>
        <Grid item md={4} xs={12}>
          <Chip
            label={dayName}
            variant="filled"
            size="medium"
            sx={{
              fontSize: '1.2rem',
              p: 2,
            }}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Switch
            checked={check}
            onChange={(el) => {
              if (onSwitchChange) {
                onSwitchChange(el.target.checked);
              }
            }}
            labelText={check ? 'Open' : 'Closed'}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          {true !== true ? (
            <TimeRangePicker
              onChange={onChange}
              value={value}
              className={styles['timeRangePicker']}
            />
          ) : null}
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              setOpenDrawer(true);
            }}
          >
            {t('time')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

import { atom } from 'recoil';
import { BusinessData } from '../models/business';
import { UserData } from '../models/user';
import { ProductBase } from '../models/product';
import { LanguagesSpace } from '../components/LanguagePicker/LanguagePicker';
import { MenuCreate } from '../models/menu';
import { Plan } from '../models/plan';

interface MenuAtomProps {
  productData: ProductBase | null;
  language: LanguagesSpace | null;
  businessData: BusinessData | null;
  menus: MenuCreate[] | null;
  planData: null | Plan;
}

export const menuAtom = atom<MenuAtomProps>({
  key: 'menuState',
  default: {
    productData: null,
    language: null,
    businessData: null,
    menus: null,
    planData: null,
  },
});

import React, { useEffect, useState } from 'react';

import { LoadingFeedback } from '../../Loaders/loading_feedback/LoadingFeedback';

import firebase from '../../../../services/firebase';
import KeywordGeneration from '../../../../services/keywords';

import { Cropper } from '../../Cropper/Cropper';
import { Modal } from '../../../../reusableComponents/Modal/Modal';
import { toast } from 'react-toastify';
import {
  Box,
  Chip,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  SvgIcon,
  Typography,
} from '@mui/material';
import { TabsContainer } from '../../../../reusableComponents/Tabs/Tabs';
import { useRecoilState } from 'recoil';
import { loadingAtom } from '../../../../state/loading';
import { Dropzone } from '../../../../reusableComponents/Dropzone/Dropzone';
import { TextInput } from '../../../../reusableComponents/TextInput/TextInput';
import { Select } from '../../../../reusableComponents/Select/Select';
import { Button } from '../../../../reusableComponents/Button/Button';
import {
  Add,
  AttachMoney,
  ChangeCircle,
  ChangeCircleOutlined,
  Delete,
  Star,
} from '@mui/icons-material';
import { Switch } from '../../../../reusableComponents/Switch/Switch';
import { Card } from '../../../../reusableComponents/Card/Card';
import { adminAtom } from '../../../../state/adminAtom';
import { BusinessData } from '../../../../models/business';
import { LanguageGeneric, Languages } from '../../../../models/languages';
import {
  checkIfEmptyMultilanguageValue,
  chooseMultilanguageValue,
  distributeValue,
  getMultilanguageValue,
  returnArrayOfValidMulilanguageValues,
  setMultilanguageValue,
  updateMultilanguageObj,
} from '../../../../services/multiLanguague';
import { uploadToStorage } from '../../../../services/uploadToStorage';
import classNames from 'classnames';
import styles from './AddProduct.module.css';

//ICONS
import clam from '../../../../res/icons/clam.png';
import compound from '../../../../res/icons/compound.png';
import condiments from '../../../../res/icons/condiments.png';
import crab from '../../../../res/icons/crab-with-two-claws.png';
import fish from '../../../../res/icons/fish.png';
import egg from '../../../../res/icons/fried-egg.png';
import nut from '../../../../res/icons/hazelnut.png';
import leaf from '../../../../res/icons/leaf.png';
import milk from '../../../../res/icons/milk-bottle.png';
import peanut from '../../../../res/icons/peanut-butter.png';
import seed1 from '../../../../res/icons/seed-bag.png';
import seed2 from '../../../../res/icons/seed-bag-2.png';
import soybean from '../../../../res/icons/soybean.png';
import wheat from '../../../../res/icons/wheat-grain.png';

import vegetarian from '../../../../res/icons/vegetarian-food.png';
import vegan from '../../../../res/icons/vegan-food.png';
import spicy from '../../../../res/icons/spicy-food.png';
import gluten_free from '../../../../res/icons/gluten-free.png';
import bag from '../../../../res/icons/bag.png';
import { getTranslationApi } from '../../../../api/translation';
import { useTranslation } from 'react-i18next';

interface ItemModalProps {
  itemData: any;
  data: any;
  businessData: any;
  close: Function;
  reload: Function;
  categories: any[];
  automaticSection?: string;
  automaticCategory?: string;
}

export const AddProduct = (props: ItemModalProps) => {
  const { t } = useTranslation();
  const [automaticTranslation, setAutomaticTranslation] = useState(false);

  const [prevImage, setPrevImage] = useState<string | null>('');
  const [image, setImage] = useState<Blob | null>(null);
  const [imageEdit, setImageEdit] = useState(true);
  const [name, setName] = useState(distributeValue<string>(''));
  const [description, setDescription] = useState(distributeValue<string>(''));
  const [section, setSection] = useState('');
  const [category, setCategory] = useState('');
  const [type, setType] = useState('simple');
  const [makingTime, setMakingTime] = useState('');
  const [variation, setVariation] = useState<any[]>([]);
  const [extras, setExtras] = useState<any[]>([]);
  const [normalPrice, setNormalPrice] = useState('');
  const [active, setActive] = useState(false);
  const [update, setUpdate] = useState(false);
  const [src, setSrc] = useState(null);
  const [callback, setCallback] = useState(() => { });
  const [branchList, setBranchList] = useState<any[]>([
    {
      e: '',
    },
  ]);
  const [branchesElements, setBranchesElements] = useState([]);
  const [allBranches, setAllBranches] = useState(true);
  const [pendingCategories, setPendingCategories] = useState(false);
  const [sections, setSections] = useState([]);
  const [resetting, setResetting] = useState(false);

  const [adminState, setAdminState] = useRecoilState(adminAtom);
  const [isLoading, setIsLoading] = useRecoilState(loadingAtom);

  const [selectedAllergens, setSelectedAllergens] = useState<any[]>([]);

  const [selectedTags, setSelectedTags] = useState<any[]>([]);

  const [indexes, setIndexes] = useState(
    Object.keys((adminState.businessData as BusinessData).languages)

      .filter(
        (key) =>
          adminState.businessData?.languages[key as keyof Languages].state ===
          true
      )
      .sort()
      .map((key, i) => ({ lang: key, i }))
  );

  const [languagesToTranslate, setLanguagesToTranslate] = useState({
    DE: {
      name: 'German',
      state: true,
    },
    ES: {
      name: 'Spanish',
      state: true,
    },
    'ES-V': {
      name: 'Valencian',
      state: true,
    },
    'ES-C': {
      name: 'Catalan',
      state: true,
    },
    IT: {
      name: 'Italian',
      state: true,
    },
    EN: {
      name: 'English',
      state: true,
    },
  } as Languages);

  const allergensData = [
    {
      label: t('celery'),
      icon: <img src={leaf} width={'30px'} />,
      value: 'celery',
    },
    {
      label: t('crustaceans'),
      value: 'crustaceans',
      icon: <img src={crab} width={'25px'} />,
    },
    {
      label: t('dairy'),
      value: 'dairy',
      icon: <img src={milk} width={'25px'} />,
    },
    { label: t('eggs'), value: 'eggs', icon: <img src={egg} width={'25px'} /> },
    {
      label: t('fish'),
      value: 'fish',
      icon: <img src={fish} width={'25px'} />,
    },
    {
      label: t('gluten'),
      value: 'gluten',
      icon: <img src={wheat} width={'25px'} />,
    },
    {
      label: t('lupins'),
      value: 'lupins',
      icon: <img src={seed1} width={'25px'} />,
    },
    {
      label: t('mollusks'),
      value: 'mollusks',
      icon: <img src={clam} width={'25px'} />,
    },
    {
      label: t('mustard'),
      value: 'mustard',
      icon: <img src={condiments} width={'25px'} />,
    },
    {
      label: t('peanuts'),
      value: 'peanuts',
      icon: <img src={peanut} width={'25px'} />,
    },
    {
      label: t('sesame'),
      value: 'sesame',
      icon: <img src={seed2} width={'25px'} />,
    },
    { label: t('nuts'), value: 'nuts', icon: <img src={nut} width={'25px'} /> },
    {
      label: t('soy'),
      value: 'soy',
      icon: <img src={soybean} width={'25px'} />,
    },
    {
      label: t('sulfites'),
      value: 'sulfites',
      icon: <img src={compound} width={'25px'} />,
    },
  ];

  const tagsData = [
    {
      label: t('vegetarian'),
      icon: <img src={vegetarian} width={'25px'} />,
      value: 'vegetarian',
    },
    {
      label: t('vegan'),
      icon: <img src={vegan} width={'25px'} />,
      value: 'vegan',
    },
    {
      label: t('spicy'),
      icon: <img src={spicy} width={'25px'} />,
      value: 'spicy',
    },
    {
      label: t('gluten_free'),
      icon: <img src={gluten_free} width={'25px'} />,
      value: 'gluten_free',
    },
    {
      label: t('ecological'),
      icon: <img src={bag} width={'25px'} />,
      value: 'ecological',
    },
  ];

  const handleChipClick = (chipKey: any) => {
    setSelectedAllergens((prev: any) => {
      if (prev.includes(chipKey)) {
        return prev.filter((key: any) => key !== chipKey);
      } else {
        return [...prev, chipKey];
      }
    });
  };

  const AllergyChips = () => (
    <>
      {allergensData.map((item: any, index: any) => (
        <Chip
          key={index}
          icon={item.icon}
          label={item.label}
          onClick={() => handleChipClick(item.value)}
          color={selectedAllergens.includes(item.value) ? 'primary' : 'default'}
          variant="outlined"
          sx={{
            m: 0.5,
            backgroundColor: selectedAllergens.includes(item.value)
              ? 'lightgrey'
              : undefined,
          }}
        />
      ))}
    </>
  );

  const handleTagClick = (chipKey: any) => {
    setSelectedTags((prev: any) => {
      if (prev.includes(chipKey)) {
        return prev.filter((key: any) => key !== chipKey);
      } else {
        return [...prev, chipKey];
      }
    });
  };

  const TagChips = () => (
    <>
      {tagsData.map((item: any, index: any) => (
        <Chip
          key={index}
          icon={item.icon}
          label={item.label}
          onClick={() => handleTagClick(item.value)}
          color={selectedTags.includes(item.value) ? 'primary' : 'default'}
          variant="outlined"
          sx={{
            m: 0.5,
            backgroundColor: selectedTags.includes(item.value)
              ? 'lightgrey'
              : undefined,
          }}
        />
      ))}
    </>
  );

  useEffect(() => {
    if (props.automaticCategory && props.automaticSection) {
      setSection(props.automaticSection);
      setCategory(props.automaticCategory);
    }
  }, [props.automaticCategory, props.automaticSection]);

  const getSections = () => {
    setPendingCategories(true);

    firebase
      .firestore()
      .collection('business')
      .doc(adminState.businessData?.id)
      .get()
      .then((doc: any) => {
        let data = doc.data();
        data.id = doc.id;

        setSections(data.sections);
        setPendingCategories(false);
      })
      .catch((e) => {
        console.log(e);

        setPendingCategories(false);
      });
  };

  useEffect(() => {
    if (update) {
      if (update && props.itemData.branches !== undefined) {
        setAllBranches(
          props.itemData.branches.length === branchesElements.length
        );

        if (!allBranches) {
          let branchList: any[] = [];

          for (let i = 0; i < props.itemData.branches.length; i++) {
            branchList.push({
              id: '',
              name: props.itemData.branches[i],
            });
          }

          setBranchList(branchList); // Assuming you have a setBranchList function
        }
      }
    }
  }, [update, props.itemData, branchesElements.length, allBranches]);

  useEffect(() => {
    if (props.itemData !== null) {
      let d = JSON.parse(JSON.stringify(Object.assign({}, props.itemData)));
      getSections();

      if (d.options !== null && d.options !== undefined) {
        for (let i = 0; i < d.options.length; i++) {
          d.options[i].normalPrice = String(d.options[i].price);
          //d.options[i].lowerPrice = String(d.options[i].lowerPrice);
        }
      }

      if (d.extras !== null && d.extras !== undefined) {
        for (let i = 0; i < d.extras.length; i++) {
          d.extras[i].price = String(d.extras[i].price);
        }
      }

      setName(d.productName);
      setDescription(d.productDescription);
      setCategory(d.category);
      setSection(d.section);
      setVariation(d.options === null ? [] : d.options);
      setActive(d.active === undefined ? true : d.active);
      setPrevImage(d.imageUrl);
      setExtras(d.extras);
      setUpdate(true);
      setNormalPrice(d.price);
      setMakingTime(d.makingTime);
      setImageEdit(false);
      setSelectedAllergens(d.allergens);
      setSelectedTags(d.tags);
    }
  }, []);

  useEffect(() => {
    if (resetting === true) {
      setResetting(false);
    }
  }, [resetting]);

  const deleteItem = async () => {
    setIsLoading(true);

    try {
      try {
        firebase
          .storage()
          .refFromURL(prevImage as any)
          .delete();
      } catch (e) {
        console.log(e);
        toast.error(t('something_went_wrong'));
      }

      let db = firebase.firestore();

      var sfDocRef = db.collection('business').doc(`${props.businessData.id}`);

      return db
        .runTransaction((transaction) => {
          // This code may get re-run multiple times if there are conflicts.
          return transaction.get(sfDocRef).then((sfDoc) => {
            if (!sfDoc.exists) {
              toast.error(t('business_doesnt_exist'));
            } else {
              var newItems =
                ((sfDoc as any).data().products === undefined
                  ? 1
                  : (sfDoc as any).data().products) - 1;
              transaction.update(sfDocRef, { products: newItems });

              transaction.delete(
                db.collection('products').doc(props.itemData.id)
              );
            }

            // Add one person to the city population.
            // Note: this could be done without a transaction
            //       by updating the population using FieldValue.increment()
          });
        })
        .then(() => {
          setIsLoading(false);

          toast.error(t('product_deleted'));
          props.close();
          props.reload();
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);

          toast.error(t('something_went_wrong'));
        });
    } catch (e) {
      console.log(e);
      toast.error(t('something_went_wrong'));

      setIsLoading(false);
    }
  };

  const updateItem = async () => {
    let flag = 0;
    let messages = [];

    if (checkIfEmptyMultilanguageValue(name)) {
      flag = 1;
      messages.push(t('name_cannot_be_empty'));
    }

    if (checkIfEmptyMultilanguageValue(description)) {
      flag = 1;
      messages.push(t('description_cannot_be_empty'));
    }

    if (section === '') {
      flag = 1;
      messages.push(t('you_must_choose_a_section'));
    }

    if (category === '') {
      flag = 1;
      messages.push(t('you_must_choose_a_category'));
    }

    if (isNaN(Number(makingTime))) {
      flag = 1;
      messages.push(t('the_preparation_time_must_be_a_numeric_number'));
    }

    if (isNaN(Number(normalPrice))) {
      flag = 1;
      messages.push(t('the_price_must_be_a_numeric_value'));
    }

    if (!allBranches) {
      for (let i = 0; i < branchList.length; i++) {
        if (branchList[i].id === undefined || branchList[i].name === '') {
          flag = 1;
          messages.push(t('you_should_choose_the_specific_branches'));
        }
      }
    }

    if (flag === 0) {
      setIsLoading(true);

      if (image !== null) {
        try {
          if (prevImage !== '') {
            setIsLoading(true);

            try {
              await firebase
                .storage()
                .refFromURL(prevImage as any)
                .delete();
            } catch (e) {
              console.log(e);
              toast.error(t('something_went_wrong'));
            }
          }

          let file = image;

          let downloadUrl = image
            ? await uploadToStorage(
              file as Blob,
              `products/${props.itemData.id}`,
              setIsLoading,
              (message: string) => {
                toast.info(message);
              }
            )
            : '';

          let extrasArr = [...extras];

          for (let i = 0; i < extrasArr.length; i++) {
            delete extrasArr[i].edit;
            extrasArr[i].price = Number(extras[i].price);
            extrasArr[i].max = Number(extras[i].max);
          }

          let variationArr = [...variation];

          for (let i = 0; i < variationArr.length; i++) {
            variationArr[i].price = Number(variationArr[i].normalPrice);
            delete variationArr[i].normalPrice;
            delete variationArr[i].lowerPrice;
            //variation[i].lowerPrice = Number(variation[i].lowerPrice)
          }

          let branches = [];

          if (allBranches) {
            for (let i = 0; i < branchesElements.length; i++) {
              branches.push((branchesElements as any)[i].name);
            }
          } else {
            for (let i = 0; i < branchList.length; i++) {
              branches.push(branchList[i].name);
            }
          }

          firebase
            .firestore()
            .collection('business')
            .doc(adminState.businessData?.id)
            .collection('menus')
            .doc(adminState.menuData?.id)
            .collection('products')
            .doc(props.itemData.id)
            .update({
              available: true,
              live: false,
              category: category,
              changeState: 'pending',
              updated: new Date(),
              imagePath: '',
              imageUrl: downloadUrl,
              productDescription: description,
              active: active,
              makingTime: Number(makingTime),
              price: Number(normalPrice),
              productName: name,
              allergens: selectedAllergens,
              tags: selectedTags,
              keywords: returnArrayOfValidMulilanguageValues(name)
                .map((el) => KeywordGeneration.generateKeywords(el))
                .concat(
                  returnArrayOfValidMulilanguageValues(
                    adminState.menuData?.sections?.filter(
                      (value) => value.id === section
                    )[0] as LanguageGeneric<string>
                  ).map((el) => KeywordGeneration.generateKeywords(el))
                )
                .concat(
                  returnArrayOfValidMulilanguageValues(
                    adminState.menuData?.categories?.filter(
                      (value) => value.id === category
                    )[0] as LanguageGeneric<string>
                  ).map((el) => KeywordGeneration.generateKeywords(el))
                )
                .flat(),
              options: variation,
              section: section,
              extras: extras,
              type: type,
              businessID: props.businessData.id,
            })
            .then(() => {
              setIsLoading(false);

              toast.success(t('product_updated'));
              props.close();
              props.reload();
            })
            .catch((e) => {
              console.log(e);
              toast.error(t('something_went_wrong'));

              setIsLoading(false);
            });
        } catch (e) {
          console.log(e);
          toast.error(t('something_went_wrong'));

          setIsLoading(false);
        }
      } else {
        let extrasArr = [...extras];

        for (let i = 0; i < extrasArr.length; i++) {
          delete extrasArr[i].edit;
          extrasArr[i].price = Number(extrasArr[i].price);
          extrasArr[i].max = Number(extrasArr[i].max);
        }

        let variationArr = [...variation];

        for (let i = 0; i < variationArr.length; i++) {
          variationArr[i].price = Number(variationArr[i].normalPrice);
          delete variationArr[i].normalPrice;
          delete variationArr[i].lowerPrice;
        }

        let branches: any[] = [];

        if (allBranches) {
          for (let i = 0; i < branchesElements.length; i++) {
            branches.push((branchesElements[i] as any).name);
          }
        } else {
          for (let i = 0; i < branchList.length; i++) {
            branches.push(branchList[i].name);
          }
        }

        firebase
          .firestore()
          .collection('business')
          .doc(adminState.businessData?.id)
          .collection('menus')
          .doc(adminState.menuData?.id)
          .collection('products')
          .doc(props.itemData.id)
          .update({
            available: true,
            live: false,
            category: category,
            changeState: 'pending',
            updated: new Date(),
            productDescription: description,
            active: active,
            makingTime: Number(makingTime),
            price: Number(normalPrice),
            productName: name,
            allergens: selectedAllergens,
            tags: selectedTags,
            keywords: returnArrayOfValidMulilanguageValues(name)
              .map((el) => KeywordGeneration.generateKeywords(el))
              .concat(
                returnArrayOfValidMulilanguageValues(
                  adminState.menuData?.sections?.filter(
                    (value) => value.id === section
                  )[0] as LanguageGeneric<string>
                ).map((el) => KeywordGeneration.generateKeywords(el))
              )
              .concat(
                returnArrayOfValidMulilanguageValues(
                  adminState.menuData?.categories?.filter(
                    (value) => value.id === category
                  )[0] as LanguageGeneric<string>
                ).map((el) => KeywordGeneration.generateKeywords(el))
              )
              .flat(),
            options: variation,
            section: section,
            extras: extras,
            type: type,
            businessID: props.businessData.id,
          })
          .then(() => {
            setIsLoading(false);

            toast.success(t('item_updated'));
            props.close();
            props.reload();
          })
          .catch((e) => {
            console.log(e);
            toast.error(t('something_went_wrong'));

            setIsLoading(false);
          });
      }
    } else {
      for (let i = 0; i < messages.length; i++) {
        toast.error(messages[i]);
      }
    }
  };

  const createItem = async () => {
    let flag = 0;
    let messages = [];

    if (checkIfEmptyMultilanguageValue(name)) {
      flag = 1;
      messages.push(t('name_cannot_be_empty'));
    }

    if (checkIfEmptyMultilanguageValue(description)) {
      flag = 1;
      messages.push(t('description_cannot_be_empty'));
    }

    if (isNaN(Number(makingTime))) {
      flag = 1;
      messages.push(t('the_preparation_time_must_be_a_numeric_number'));
    }

    if (section === '') {
      flag = 1;
      messages.push(t('you_must_choose_a_section'));
    }

    if (category === '') {
      flag = 1;
      messages.push(t('you_must_choose_a_category'));
    }

    if (type === 'simple') {
      if (isNaN(Number(normalPrice))) {
        flag = 1;
        messages.push(t('the_price_should_be_a_numeric_value'));
      }
    }

    if (type === 'variable') {
      if (variation.length < 1) {
        flag = 1;
        messages.push(t('you_should_choose_at_least_one_variation'));
      }

      for (let i = 0; i < variation.length; i++) {
        if (variation[i].name.trim() === '') {
          flag = 1;
          messages.push(
            `${t('on_the_variation_number')} #${i}, ${t(
              'you_must_include_the_name'
            )}`
          );
        }

        if (isNaN(Number(variation[i].normalPrice))) {
          flag = 1;
          messages.push(
            `${t('on_the_variation_number')} #${i}, ${t(
              'the_price_should_be_a_numeric_value'
            )}`
          );
        }
      }
    }

    if (flag === 0) {
      setIsLoading(true);

      let file = image;

      let id = firebase.firestore().collection('products').doc().id;

      let downloadUrl = image
        ? await uploadToStorage(
          file as Blob,
          `products/${id}`,
          setIsLoading,
          (message: string) => {
            toast.info(message);
          }
        )
        : '';
      let extrasArr = [...extras];

      for (let i = 0; i < extrasArr.length; i++) {
        delete extrasArr[i].edit;
        extrasArr[i].price = Number(extrasArr[i].price);
        extrasArr[i].max = Number(extrasArr[i].max);
      }

      let variationArr = [...variation];

      for (let i = 0; i < variationArr.length; i++) {
        variationArr[i].price = Number(variationArr[i].normalPrice);
        delete variationArr[i].normalPrice;
        delete variationArr[i].lowerPrice;
      }

      let db = firebase.firestore();

      var sfDocRef = db.collection('business').doc(`${props.businessData.id}`);

      // Uncomment to initialize the doc.
      // sfDocRef.set({ population: 0 });

      return db
        .runTransaction((transaction) => {
          // This code may get re-run multiple times if there are conflicts.
          return transaction.get(sfDocRef).then((sfDoc) => {
            if (!sfDoc.exists) {
              toast.error(t('business_doesnt_exist'));
            } else {
              var newItems =
                ((sfDoc as any).data().products === undefined
                  ? 0
                  : (sfDoc as any).data().products) + 1;
              transaction.update(sfDocRef, { products: newItems });

              let branches: any[] = [];

              transaction.set(
                db
                  .collection('business')
                  .doc(adminState.businessData?.id)
                  .collection('menus')
                  .doc(adminState.menuData?.id)
                  .collection('products')
                  .doc(id),
                {
                  available: true,
                  live: false,
                  category: category,
                  changeState: 'pending',
                  created: new Date(),
                  updated: new Date(),
                  imagePath: ``,
                  imageUrl: downloadUrl,
                  productDescription: description,
                  active: active,
                  makingTime: Number(makingTime),
                  price: Number(normalPrice),
                  productName: name,
                  allergens: selectedAllergens,
                  tags: selectedTags,
                  keywords: returnArrayOfValidMulilanguageValues(name)
                    .map((el) => KeywordGeneration.generateKeywords(el))
                    .concat(
                      returnArrayOfValidMulilanguageValues(
                        adminState.menuData?.sections?.filter(
                          (value) => value.id === section
                        )[0] as LanguageGeneric<string>
                      ).map((el) => KeywordGeneration.generateKeywords(el))
                    )
                    .concat(
                      returnArrayOfValidMulilanguageValues(
                        adminState.menuData?.categories?.filter(
                          (value) => value.id === category
                        )[0] as LanguageGeneric<string>
                      ).map((el) => KeywordGeneration.generateKeywords(el))
                    )
                    .flat(),
                  options: variation,
                  section: section,
                  extras: extras,
                  type: type,
                  businessID: props.businessData.id,
                  index: (sfDoc as any).data().products + 1,
                }
              );
            }
          });
        })
        .then(() => {
          setIsLoading(false);

          toast.success(t('product_created'));
          props.close();
          props.reload();
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
          toast.error(t('something_went_wrong'));
        });
    } else {
      for (let i = 0; i < messages.length; i++) {
        toast.error(messages[i]);
      }
    }
  };

  const getAutomaticTranslationBasedOnIndex = (i: number) => {
    if (
      adminState.planData?.translation &&
      languagesToTranslate[indexes[i].lang as keyof Languages].state === false
    ) {
      if (indexes[i].lang !== adminState.businessData?.defaultLanguage) {
        if (automaticTranslation) {
          let productNameVal: string = getMultilanguageValue(
            name,
            adminState.businessData?.defaultLanguage as keyof Languages
          );

          let productDescriptionVal: string = getMultilanguageValue(
            description,
            adminState.businessData?.defaultLanguage as keyof Languages
          );

          const valuesToTranslate = [];

          if (productNameVal.trim() !== '') {
            valuesToTranslate.push(productNameVal);
          }

          if (productDescriptionVal.trim() !== '') {
            valuesToTranslate.push(productDescriptionVal);
          }

          if (valuesToTranslate.length > 0) {
            getTranslationApi(
              valuesToTranslate,
              adminState.languagesISO[indexes[i].lang as keyof Languages]
            )
              .then((r) => {
                if (r[0]) {
                  setMultilanguageValue(
                    r[0],
                    setName,
                    indexes[i].lang as keyof Languages
                  );
                }

                if (r[1]) {
                  setMultilanguageValue(
                    r[1],
                    setDescription,
                    indexes[i].lang as keyof Languages
                  );
                }

                let langObj: Languages = JSON.parse(
                  JSON.stringify(languagesToTranslate)
                );

                langObj[indexes[i].lang as keyof Languages].state = true;
                setLanguagesToTranslate(langObj);
              })
              .catch((e) => {
                console.log(e);
              });
          }
        }
      }
    }
  };

  return (
    <Modal
      open={true}
      onClose={() => {
        props.close();
      }}
      sx={{
        '& > .MuiBox-root': {
          '& > .MuiBox-root': {
            pb: 3,
          },
        },
      }}
    >
      <>
        {src !== null ? (
          <Cropper
            mount={(image: any) => {
              setImage(image);
              setImageEdit(false);
            }}
            src={src}
            aspect={1 / 1}
            close={() => {
              setSrc(null);
            }}
          />
        ) : null}

        {update === true ? (
          <Typography variant="h4" textAlign={'center'}>
            {t('update_product')}
          </Typography>
        ) : (
          <Typography variant="h4" textAlign={'center'}>
            {t('add_product')}
          </Typography>
        )}


        {/*

        <TabsContainer
          tabs={[
            {
              label: t('product_profile'),
            },

            {
              label: t('additionals_uppercase'),
            },
          ]}
          muiProps={{
            centered: true,
            sx: {
              mt: 2,
            },
            variant: 'scrollable',
          }}
        >

        */}

        <TabsContainer
          tabs={[
            {
              label: t('product_profile'),
            },


          ]}
          muiProps={{
            centered: true,
            sx: {
              mt: 2,
            },
            variant: 'scrollable',
          }}
        >
          <TabsContainer.TabPanel>
            <Box>
              {adminState.planData?.productImages ? (
                <>
                  {imageEdit || (prevImage === '' && image == null) ? (
                    <Stack direction={'row'} justifyContent={'center'}>
                      <Dropzone
                        acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                        dropzoneClass={classNames(styles['image-dropzone'])}
                        dropzoneText="Drop the image"
                        showPreviewsInDropzone={true}
                        onChange={(element) => {
                          if (element.length > 0) {
                            const blob = new Blob(element, {
                              type: 'application/octet-stream',
                            });

                            setSrc(URL.createObjectURL(element[0]) as any);

                            setResetting(true);
                          }
                        }}
                      />
                    </Stack>
                  ) : null}

                  {!imageEdit && (image || prevImage) ? (
                    <>
                      <Card
                        sx={{
                          height: 180,
                        }}
                        display={'flex'}
                        justifyContent={'center'}
                      >
                        <Box
                          sx={{
                            borderRadius: 'var(--border-radius)',
                            p: 1,
                            backgroundColor:
                              'var(--mui-palette-background-default)',
                          }}
                        >
                          <img
                            src={
                              prevImage
                                ? prevImage
                                : image
                                  ? URL.createObjectURL(image as any)
                                  : ''
                            }
                            style={{
                              borderRadius: 'var(--border-radius)',
                            }}
                            height={'100%'}
                          />{' '}
                        </Box>
                      </Card>
                      <Stack direction={'row'} justifyContent={'center'}>
                        <IconButton
                          color="primary"
                          disableRipple
                          onClick={() => {
                            setSrc(null);
                            setImage(null);
                            setImageEdit(true);
                          }}
                        >
                          <ChangeCircleOutlined />
                        </IconButton>
                      </Stack>
                    </>
                  ) : null}
                </>
              ) : null}

              {/* <Box
                sx={{
                  boxSizing: 'border-box',
                }}
              >
                <Grid mt={2} container spacing={2}>
                  <Grid xs={6} item>
                    <Select
                      labelText="Section"
                      placeholder="Select the section"
                      onChange={(el) => {
                        setSection(el.target.value as string);
                      }}
                      value={section}
                      options={adminState.menuData?.sections?.map(
                        (e: any, i: number) => {
                          return {
                            value: e.id,
                            key: i,
                            text: chooseMultilanguageValue(
                              e as LanguageGeneric<string>,
                              adminState.language as keyof Languages,
                              'ES'
                            ),
                          };
                        }
                      )}
                    />
                  </Grid>
                  {section &&
                  (
                    adminState.menuData?.categories?.filter(
                      (val) => val.linkedSection === section
                    ) as any
                  ).length > 0 ? (
                    <Grid xs={6} item>
                      <Select
                        labelText="Category"
                        placeholder="Select the category"
                        onChange={(el) => {
                          setCategory(el.target.value as string);
                        }}
                        value={category}
                        options={adminState.menuData?.categories
                          ?.filter((val) => val.linkedSection === section)
                          .map((e: any, i: number) => {
                            return {
                              value: e.id,
                              key: i,
                              text: chooseMultilanguageValue(
                                e as LanguageGeneric<string>,
                                adminState.language as keyof Languages,
                                'ES'
                              ),
                            };
                          })}
                      />
                    </Grid>
                  ) : null}
                </Grid>
              </Box> */}

              <TabsContainer
                muiProps={{
                  sx: {
                    mt: 2,
                  },
                }}
                listenIndexChange={getAutomaticTranslationBasedOnIndex}
                defaultValue={
                  indexes.filter(
                    (v) => v.lang === adminState.businessData?.defaultLanguage
                  )[0].i
                }
                tabs={Object.keys(
                  (adminState.businessData as BusinessData).languages
                )

                  .filter(
                    (key) =>
                      adminState.businessData?.languages[key as keyof Languages]
                        .state === true
                  )
                  .sort()
                  .map((key: string, i: number) => ({
                    label:
                      adminState.businessData?.languages[key as keyof Languages]
                        .name,
                    key: i,
                    icon:
                      key === adminState.businessData?.defaultLanguage ? (
                        <Star />
                      ) : undefined,
                  }))}
              >
                {Object.keys(
                  (adminState.businessData as BusinessData).languages
                )
                  .filter(
                    (key) =>
                      adminState.businessData?.languages[key as keyof Languages]
                        .state === true
                  )
                  .sort()
                  .map((lang: string, i: number) => {
                    return (
                      <TabsContainer.TabPanel key={i}>
                        <>
                          {adminState.businessData?.defaultLanguage ? (
                            lang !==
                              adminState.businessData?.defaultLanguage ? (
                              <Stack direction={'row'} justifyContent={'end'}>
                                <Switch
                                  labelText={t('automatic_translation')}
                                  checked={automaticTranslation}
                                  onChange={(e) => {
                                    setAutomaticTranslation(e.target.checked);
                                  }}
                                />
                              </Stack>
                            ) : null
                          ) : null}
                          <Stack spacing={1}>
                            <TextInput
                              value={getMultilanguageValue(
                                name,
                                lang as keyof Languages
                              )}
                              label={t('product_name')}
                              onChange={(e) => {
                                setMultilanguageValue(
                                  e.currentTarget.value,
                                  setName,
                                  lang as keyof Languages
                                );

                                let langObj: Languages = JSON.parse(
                                  JSON.stringify(languagesToTranslate)
                                );

                                Object.keys(langObj).forEach((key: any) => {
                                  langObj[key as keyof Languages].state = false;
                                });

                                setLanguagesToTranslate(langObj);
                              }}
                            />

                            <TextInput
                              style={{ resize: 'none' }}
                              label={t('product_description')}
                              value={getMultilanguageValue(
                                description,
                                lang as keyof Languages
                              )}
                              onChange={(e) => {
                                setMultilanguageValue(
                                  e.currentTarget.value,
                                  setDescription,
                                  lang as keyof Languages
                                );

                                let langObj: Languages = JSON.parse(
                                  JSON.stringify(languagesToTranslate)
                                );

                                Object.keys(langObj).forEach((key: any) => {
                                  langObj[key as keyof Languages].state = false;
                                });

                                setLanguagesToTranslate(langObj);
                              }}
                              multiline={true}
                              rows={3}
                            />

                            {/* <TextInput
                              label="Preparation time"
                              value={makingTime}
                              type="number"
                              onChange={(e) => {
                                setMakingTime(e.currentTarget.value);
                              }}
                              InputProps={{
                                endAdornment: <Typography>min</Typography>,
                              }}
                            /> */}

                            <TextInput
                              className="form-control custom-input-1"
                              label={t('price')}
                              value={normalPrice}
                              onChange={(el) => {
                                setNormalPrice(el.currentTarget.value);
                              }}
                              type="number"
                            />

                            <Box>
                              <Typography mb={1} mt={1}>{t('allergens')}</Typography>
                              <Stack direction={'row'} flexWrap={'wrap'}>
                                <AllergyChips />
                              </Stack>
                            </Box>

                            <Box>
                              <Typography mb={1} mt={1}>{t('tags')}</Typography>
                              <Stack direction={'row'} flexWrap={'wrap'}>
                                <TagChips />
                              </Stack>
                            </Box>
                          </Stack>

                          {adminState.planData?.productVariations ? (
                            <>
                              <Divider
                                sx={{
                                  mt: 3,
                                }}
                              />

                              <Box mt={3}>
                                <Stack
                                  direction={'row'}
                                  justifyContent={'flex-end'}
                                >
                                  <Button
                                    startIcon={<Add />}
                                    variant="outlined"
                                    onClick={() => {
                                      let base = [...variation];
                                      base.push({
                                        name: distributeValue(''),
                                        normalPrice: '',
                                        active: true,
                                      });

                                      setVariation(base);
                                    }}
                                  >
                                    {t('add_variation')}
                                  </Button>
                                </Stack>
                                {variation !== null
                                  ? variation.map((e: any, i: number) => {
                                    return (
                                      <>
                                        <Typography textAlign={'center'}>
                                          {t('variant')} #{i + 1}{' '}
                                        </Typography>

                                        <Grid container spacing={2} mt={1}>
                                          <Grid xs={8} item>
                                            <TextInput
                                              placeholder={t(
                                                'name_of_the_variant'
                                              )}
                                              label={t('name')}
                                              value={getMultilanguageValue(
                                                e.name,
                                                lang as keyof Languages
                                              )}
                                              onChange={(el) => {
                                                let base = [...variation];
                                                base[i].name =
                                                  updateMultilanguageObj(
                                                    base[i].name,
                                                    el.currentTarget.value,
                                                    lang as keyof Languages
                                                  );

                                                setVariation(base);
                                              }}
                                              fullWidth
                                            />
                                          </Grid>

                                          <Grid item xs={3}>
                                            <TextInput
                                              type="number"
                                              label={t('price')}
                                              value={e.normalPrice}
                                              fullWidth
                                              onChange={(el) => {
                                                let base = [...variation];

                                                base[i].normalPrice =
                                                  el.currentTarget.value;

                                                setVariation(base);
                                              }}
                                            />
                                          </Grid>

                                          <Grid item xs={1}>
                                            <Stack
                                              direction={'row'}
                                              justifyContent={'flex-end'}
                                              alignItems={'end'}
                                              sx={{
                                                height: '100%',
                                              }}
                                            >
                                              <IconButton
                                                onClick={() => {
                                                  let base = [...variation];

                                                  base.pop();

                                                  setVariation(base);
                                                }}
                                              >
                                                <Delete />
                                              </IconButton>
                                            </Stack>
                                          </Grid>
                                        </Grid>

                                        <Grid container spacing={2} mt={1}>
                                          <Grid spacing={2} xs={12} item>
                                            <Switch
                                              type="checkbox"
                                              checked={e.active}
                                              labelText={
                                                e.active
                                                  ? t('active')
                                                  : t('disabled')
                                              }
                                              onClick={() => {
                                                let base = [...variation];

                                                base[i].active = !e.active;

                                                setVariation(base);
                                              }}
                                            />
                                          </Grid>
                                        </Grid>

                                        <Divider
                                          sx={{
                                            mt: 2,
                                            mb: 2,
                                          }}
                                        />
                                      </>
                                    );
                                  })
                                  : null}
                              </Box>
                            </>
                          ) : null}
                        </>
                      </TabsContainer.TabPanel>
                    );
                  })}
              </TabsContainer>

              <Divider
                sx={{
                  mb: 2,
                }}
              />
            </Box>
          </TabsContainer.TabPanel>


          {/*
          <TabsContainer.TabPanel>
            <Typography mt={3}>{t('additionals_are_extras_that')}</Typography>

            <TabsContainer
              muiProps={{
                sx: {
                  mt: 3,
                },
              }}
              tabs={Object.keys(
                (adminState.businessData as BusinessData).languages
              )
                .filter(
                  (key) =>
                    adminState.businessData?.languages[key as keyof Languages]
                      .state === true
                )
                .map((key: string, i: number) => ({
                  label:
                    adminState.businessData?.languages[key as keyof Languages]
                      .name,
                  key: i,
                }))}
            >
              {Object.keys((adminState.businessData as BusinessData).languages)
                .filter(
                  (key) =>
                    adminState.businessData?.languages[key as keyof Languages]
                      .state === true
                )
                .map((lang: string, i: number) => {
                  return (
                    <TabsContainer.TabPanel key={i}>
                      <Box>
                        <Button
                          onClick={() => {
                            let base = [...extras];
                            base.push({
                              name: distributeValue(''),
                              price: '',
                              max: '',
                              active: true,
                              edit: true,
                            });

                            setExtras(base);
                          }}
                          variant="outlined"
                          startIcon={<Add />}
                        >
                          Additionals
                        </Button>
                      </Box>

                      <Box mt={3}>
                        {extras.map((e: any, i: number) => {
                          return e.edit === false ? (
                            <div className="extra-item flex-sb mt-3">
                              <div className="flex-left-2">
                                <h5 className="mb-0">
                                  {getMultilanguageValue(
                                    e.name,
                                    lang as keyof Languages
                                  )}
                                </h5>

                                <h5 className="ml-2">$ {e.price}</h5>
                              </div>

                              <div className="flex-end">
                                <p className="mb-0">{e.max} additional</p>
                                <Button>Edit</Button>
                                <label className="switch mb-0">
                                  <Switch
                                    type="checkbox"
                                    labelText="Active"
                                    checked={e.active}
                                    onClick={() => {
                                      let base = [...extras];

                                      base[i].active = !e.active;

                                      setExtras(base);
                                    }}
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                            </div>
                          ) : (
                            <>
                              <Typography textAlign={'center'} variant="body1">
                                Additional #{i + 1}
                                <Button
                                  variant="outlined"
                                  sx={{
                                    ml: 2,
                                  }}
                                  onClick={() => {
                                    let base = [...extras];
                                    base.splice(i, 1);

                                    setExtras(base);
                                  }}
                                >
                                  <Delete />
                                </Button>
                              </Typography>

                              <Grid container spacing={2} mt={1}>
                                <Grid item xs={6}>
                                  <TextInput
                                    label={t('name_of_extra')}
                                    placeholder={t('name')}
                                    value={getMultilanguageValue(
                                      e.name,
                                      lang as keyof Languages
                                    )}
                                    onChange={(el) => {
                                      let base = [...extras];
                                      base[i].name = updateMultilanguageObj(
                                        base[i].name,
                                        el.currentTarget.value,
                                        lang as keyof Languages
                                      );

                                      setExtras(base);
                                    }}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextInput
                                    type="number"
                                    label={t('price')}
                                    InputProps={{
                                      startAdornment: <AttachMoney />,
                                    }}
                                    value={e.price}
                                    onChange={(el) => {
                                      let base = [...extras];
                                      base[i].price = el.currentTarget.value;

                                      setExtras(base);
                                    }}
                                    fullWidth
                                  />
                                </Grid>
                              </Grid>

                              <Grid container spacing={2} mt={1}>
                                <Grid item xs={6}>
                                  <TextInput
                                    type="number"
                                    placeholder="Max"
                                    label={t('max_allowed')}
                                    value={e.max}
                                    onChange={(el) => {
                                      let base = [...extras];
                                      base[i].max = el.currentTarget.value;

                                      setExtras(base);
                                    }}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <Switch
                                    labelText={
                                      e.active ? t('active') : t('disabled')
                                    }
                                    checked={e.active}
                                    onClick={() => {
                                      let base = [...extras];

                                      base[i].active = !e.active;

                                      setExtras(base);
                                    }}
                                  />
                                </Grid>
                              </Grid>

                              <Divider
                                sx={{
                                  mt: 2,
                                  mb: 2,
                                }}
                                variant="fullWidth"
                              />
                            </>
                          );
                        })}
                      </Box>
                    </TabsContainer.TabPanel>
                  );
                })}
            </TabsContainer>
          </TabsContainer.TabPanel>

             */ }
        </TabsContainer>

        <Stack
          direction={'row'}
          justifyContent={'center'}
          spacing={2}
          sx={{
            position: 'sticky',
            left: '0',
            bottom: '0',
            backgroundColor: 'white',
            zIndex: 99,
            py: 2,
            transform: 'translate(-50%,24px)',
            border: '1px solid lightgrey',
            borderBottom: '0',
            borderRadius: '5px',
            width: '100%',
            marginLeft: '50%',
          }}
        >
          {/* {update === true ? (
            <Button
              className="btn btn-delete"
              onClick={() => {
                deleteItem();
              }}
              startIcon={<Delete />}
            >
              Eliminar
            </Button>
          ) : null} */}

          <Button
            className="btn"
            onClick={() => {
              props.close();
            }}
            variant="text"
            disableElevation={false}
            size="large"
          >
            {t('cancel')}
          </Button>

          {update === false ? (
            <Button
              onClick={() => {
                createItem();
              }}
              disableElevation={false}
              size="large"
            >
              {t('save_uppercase')}
            </Button>
          ) : null}
          {update === true ? (
            <Button
              onClick={() => {
                updateItem();
              }}
            >
              {t('update_uppercase')}
            </Button>
          ) : null}
        </Stack>
      </>
    </Modal>
  );
};

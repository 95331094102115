import React, { useEffect, useState } from 'react';
import {
  Paper,
  Typography,
  Grid,
  Box,
  Stack,
  Container,
  Chip,
  Divider,
} from '@mui/material';
import MenuAppBar from '../../components/MenuAppBar/MenuAppBar';
import { Button } from '../../reusableComponents/Button/Button';

import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { loadingAtom } from '../../state/loading';

import firebase from '../../services/firebase';
import { ProductBase } from '../../models/product';
import { chooseMultilanguageValue } from '../../services/multiLanguague';
import { LanguagesSpace } from '../../components/LanguagePicker/LanguagePicker';
import { menuAtom } from '../../state/menuAtom';
import { Languages } from '../../models/languages';
import { useTranslation } from 'react-i18next';

import clam from '../../res/icons/clam.png';
import compound from '../../res/icons/compound.png';
import condiments from '../../res/icons/condiments.png';
import crab from '../../res/icons/crab-with-two-claws.png';
import fish from '../../res/icons/fish.png';
import egg from '../../res/icons/fried-egg.png';
import nut from '../../res/icons/hazelnut.png';
import leaf from '../../res/icons/leaf.png';
import milk from '../../res/icons/milk-bottle.png';
import peanut from '../../res/icons/peanut-butter.png';
import seed1 from '../../res/icons/seed-bag.png';
import seed2 from '../../res/icons/seed-bag-2.png';
import soybean from '../../res/icons/soybean.png';
import wheat from '../../res/icons/wheat-grain.png';

import vegetarian from '../../res/icons/vegetarian-food.png';
import vegan from '../../res/icons/vegan-food.png';
import spicy from '../../res/icons/spicy-food.png';
import gluten_free from '../../res/icons/gluten-free.png';
import bag from '../../res/icons/bag.png';

import { changeLanguage } from '../../i18n/config';
import { currencyList } from '../../services/currency';
import ProductPlaceHolder from '../../res/item-placeholder.png';

// Define a type for the product prop
interface Product {
  id: number;
  name: string;
  description: string;
  price: number;
  imageUrl: string;
}

// Define the component props type
interface ProductPageProps {
  product: Partial<ProductBase> | null;
}

const ProductPage: React.FC<ProductPageProps> = ({ product }) => {
  const { t } = useTranslation();
  const params = useParams();

  const [isLoading, setIsLoading] = useRecoilState(loadingAtom);

  const [productData, setProductData] = useState<ProductBase | null>(null);

  const [menuState, setMenuState] = useRecoilState(menuAtom);

  const allergensData = [
    {
      label: t('celery'),
      icon: <img src={leaf} width={'20px'} />,
      value: 'celery',
    },
    {
      label: t('crustaceans'),
      value: 'crustaceans',
      icon: <img src={crab} width={'20px'} />,
    },
    {
      label: t('dairy'),
      value: 'dairy',
      icon: <img src={milk} width={'20px'} />,
    },
    { label: t('eggs'), value: 'eggs', icon: <img src={egg} width={'20px'} /> },
    {
      label: t('fish'),
      value: 'fish',
      icon: <img src={fish} width={'20px'} />,
    },
    {
      label: t('gluten'),
      value: 'gluten',
      icon: <img src={wheat} width={'20px'} />,
    },
    {
      label: t('lupins'),
      value: 'lupins',
      icon: <img src={seed1} width={'20px'} />,
    },
    {
      label: t('mollusks'),
      value: 'mollusks',
      icon: <img src={clam} width={'20px'} />,
    },
    {
      label: t('mustard'),
      value: 'mustard',
      icon: <img src={condiments} width={'20px'} />,
    },
    {
      label: t('peanuts'),
      value: 'peanuts',
      icon: <img src={peanut} width={'20px'} />,
    },
    {
      label: t('sesame'),
      value: 'sesame',
      icon: <img src={seed2} width={'20px'} />,
    },
    { label: t('nuts'), value: 'nuts', icon: <img src={nut} width={'20px'} /> },
    {
      label: t('soy'),
      value: 'soy',
      icon: <img src={soybean} width={'20px'} />,
    },
    {
      label: t('sulfites'),
      value: 'sulfites',
      icon: <img src={compound} width={'20px'} />,
    },
  ];

  const tagsData = [
    {
      label: t('vegetarian'),
      icon: <img src={vegetarian} width={'20px'} />,
      value: 'vegetarian',
    },
    {
      label: t('vegan'),
      icon: <img src={vegan} width={'20px'} />,
      value: 'vegan',
    },
    {
      label: t('spicy'),
      icon: <img src={spicy} width={'20px'} />,
      value: 'spicy',
    },
    {
      label: t('gluten_free'),
      icon: <img src={gluten_free} width={'20px'} />,
      value: 'gluten_free',
    },
    {
      label: t('ecological'),
      icon: <img src={bag} width={'20px'} />,
      value: 'ecological',
    },
  ];

  const AllergyChips = ({
    selectedAllergens,
  }: {
    selectedAllergens: string[];
  }) => {
    return (
      <>
        {allergensData
          .filter((v) => selectedAllergens.includes(v.value))
          .map((item: any, index: any) => (
            <Chip
              key={index}
              icon={item.icon}
              label={t(item.label, {
                fallbackLng: 'en',
              })}
              // onClick={() => handleChipClick(item.label)}
              color={
                selectedAllergens.includes(item.value) ? 'primary' : 'default'
              }
              variant="outlined"
              sx={{
                m: 0.5,
              }}
            />
          ))}
      </>
    );
  };

  const TagsChips = ({ selectedTags }: { selectedTags: string[] }) => {
    return (
      <>
        {tagsData
          .filter((v) => selectedTags.includes(v.value))
          .map((item: any, index: number) => (
            <Chip
              key={index}
              icon={item.icon}
              label={t(item.label, {
                fallbackLng: 'en',
              })}
              // onClick={() => handleChipClick(item.label)}
              color={selectedTags.includes(item.value) ? 'primary' : 'default'}
              variant="outlined"
              sx={{
                m: 0.5,
              }}
            ></Chip>
          ))}
      </>
    );
  };

  const getProductByParam = async () => {
    setIsLoading(true);
    try {
      const businessMatch = await firebase
        .firestore()
        .collection('business')
        .where('businessUrl', '==', params.restaurantId)
        .get();

      if (!businessMatch.empty) {
        const businessId = businessMatch.docs[0].data().id;

        const productData = (
          await firebase
            .firestore()
            .collection('business')
            .doc(businessId)
            .collection('menus')
            .doc(params.sectionId)
            .collection('products')
            .doc(params.productId)
            .get()
        ).data();

        if (productData) {
          setProductData(productData as ProductBase);
        }
      }

      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const accessAvailableObject = (
    obj1: any | null,
    obj2: any | null,
    field: string
  ) => {
    return obj1 !== null ? obj1[field] : obj2 !== null ? obj2[field] : '';
  };

  useEffect(() => {
    changeLanguage(String(menuState.language).toLowerCase() as string);
  }, [menuState.language]);

  useEffect(() => {
    if (params.productId && product === null) {
      getProductByParam();
    }
  }, []);

  const returnAvailableObject = (
    obj1: any | null,
    obj2: any | null,
    field: string
  ) => {
    return obj1 !== null ? obj1 : obj2 !== null ? obj2 : '';
  };

  useEffect(() => {
    changeLanguage(String(menuState.language).toLowerCase() as string);
  }, [menuState.language]);

  useEffect(() => {
    if (params.productId && product === null) {
      getProductByParam();
    }
  }, []);

  return (
    <>
      <MenuAppBar
        position="sticky"
        businessName={menuState.businessData?.businessName!}
        goBack
        canOrder={true}
        onChangeCanSelect={(v) => {}}
        language={
          menuState.businessData?.defaultLanguage ||
          (menuState.language as LanguagesSpace)
        }
        updateLanguage={(v) => setMenuState((s) => ({ ...s, language: v }))}
        languagesToRender={
          Object.keys(menuState.businessData?.languages as any).filter(
            (v: any) =>
              (menuState.businessData?.languages as Languages)[
                v as LanguagesSpace
              ].state === true
          ) as LanguagesSpace[]
        }
      />

      {(product !== null && product !== undefined) ||
      (productData !== null && productData !== undefined) ? (
        <Box
          sx={{
            maxWidth: '500px',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <Container
            sx={{
              py: 2,
            }}
          >
            <Stack spacing={3}>
              <Box
                maxWidth={'500px'}
                maxHeight={'500px'}
                width={{
                  xs: '93vw',
                  sm: '500px',
                }}
                height={{
                  xs: '93vw',
                  sm: '500px',
                }}
                alignSelf={'center'}
                sx={{
                  borderRadius: 'var(--border-radius)',
                  backgroundColor: 'lightgrey',
                  backgroundImage: `url(${
                    product?.imageUrl === ''
                      ? ProductPlaceHolder
                      : product?.imageUrl
                  })`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}
              />
              <Box>
                <Typography variant="h4" gutterBottom>
                  {chooseMultilanguageValue(
                    accessAvailableObject(product, productData, 'productName'),
                    menuState.language as LanguagesSpace,
                    menuState.language as LanguagesSpace
                  )}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {chooseMultilanguageValue(
                    accessAvailableObject(
                      product,
                      productData,
                      'productDescription'
                    ),
                    menuState.language as LanguagesSpace,
                    menuState.language as LanguagesSpace
                  )}
                </Typography>
                {(product || productData)?.options ? (
                  ((product || productData) as ProductBase)?.options.length ===
                    0 || menuState.planData?.productVariations === false ? (
                    <Typography
                      variant="h6"
                      gutterBottom
                      mt={2}
                      fontWeight={'bold'}
                    >
                      {
                        currencyList.filter(
                          (v) => v.value === menuState.businessData?.currency
                        )[0].symbol
                      }{' '}
                      {accessAvailableObject(product, productData, 'price')}
                    </Typography>
                  ) : null
                ) : null}

                {(product || productData)?.options &&
                menuState.planData?.productVariations === true ? (
                  ((product || productData) as ProductBase)?.options.length >
                  0 ? (
                    <Box mt={2}>
                      <Divider>{t('variations')}</Divider>
                      {(product || productData)?.options?.map((e, i) => (
                        <Box>
                          {chooseMultilanguageValue(
                            e.name,
                            menuState.language as LanguagesSpace,
                            menuState.language as LanguagesSpace
                          )}

                          <Typography
                            variant="h6"
                            gutterBottom
                            mt={1}
                            fontWeight={'bold'}
                          >
                            {
                              currencyList.filter(
                                (v) =>
                                  v.value === menuState.businessData?.currency
                              )[0].symbol
                            }{' '}
                            {e.price}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  ) : null
                ) : null}

                {(product || productData)?.extras ? (
                  ((product || productData) as ProductBase)?.extras.length >
                  0 ? (
                    <Box mt={2}>
                      <Divider>{t('additionals')}</Divider>
                      {(product || productData)?.extras?.map((e, i) => (
                        <Box>
                          {chooseMultilanguageValue(
                            e.name,
                            menuState.language as LanguagesSpace,
                            menuState.language as LanguagesSpace
                          )}

                          <Typography
                            variant="h6"
                            gutterBottom
                            mt={1}
                            fontWeight={'bold'}
                          >
                            {
                              currencyList.filter(
                                (v) =>
                                  v.value === menuState.businessData?.currency
                              )[0].symbol
                            }{' '}
                            {e.price}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  ) : null
                ) : null}

                <Box mt={2}>
                  {((product || productData)?.allergens as string[]).length >
                  0 ? (
                    <Typography paddingLeft={1} paddingRight={1}>
                      {t('allergens')}
                    </Typography>
                  ) : (
                    <></>
                  )}

                  <AllergyChips
                    selectedAllergens={
                      (product || productData)?.allergens as string[]
                    }
                  />
                </Box>
                <Box mt={1}>
                  {((product || productData)?.tags as string[]).length > 0 ? (
                    <Typography paddingLeft={1} paddingRight={1}>
                      {t('tags')}
                    </Typography>
                  ) : (
                    <></>
                  )}
                  <TagsChips
                    selectedTags={(product || productData)?.tags as string[]}
                  />
                </Box>
                {true !== true ? (
                  <Button variant="outlined">Add to Cart</Button>
                ) : null}
              </Box>
            </Stack>
          </Container>
        </Box>
      ) : null}
    </>
  );
};

export default ProductPage;

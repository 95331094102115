import { Divider, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Languages } from '../../../../../models/languages';
import {
  Grid,
  IconButton,
  Menu,
  MenuItem as MenuItemMUI,
  Box,
} from '@mui/material';
import {
  ArrowDownward,
  ArrowUpward,
  MoreHorizOutlined,
  MoreVertOutlined,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface CategoryItemProps {
  categoryName: string;
  edit?: Function;
  moveUpward?: Function;
  moveDownward?: Function;
}

export function CategoryItem({
  categoryName,
  edit,
  moveDownward,
  moveUpward,
}: CategoryItemProps) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  return (
    <Divider textAlign="center">
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        spacing={1}
      >
        <Typography variant="h5" fontWeight={'bold'}>
          {categoryName}
        </Typography>

        <Box>
          <IconButton onClick={openMenu} size="small">
            <MoreHorizOutlined />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={closeMenu}
          >
            <MenuItemMUI
              onClick={() => {
                if (edit) {
                  edit();
                }
              }}
            >
              Edit
            </MenuItemMUI>
            {moveUpward ? (
              <MenuItemMUI
                onClick={() => {
                  moveUpward();
                }}
              >
                <ArrowUpward
                  sx={{
                    mr: 1,
                  }}
                />
                {t('move_upward')}
              </MenuItemMUI>
            ) : null}
            {moveDownward ? (
              <MenuItemMUI
                onClick={() => {
                  moveDownward();
                }}
              >
                <ArrowDownward sx={{ mr: 1 }} />
                {t('move_downward')}
              </MenuItemMUI>
            ) : null}
          </Menu>
        </Box>
      </Stack>
    </Divider>
  );
}

class KeywordGeneration {
  static createKeywords(name: any) {
    const arrName: any[] = [];
    let curName = '';
    name.split('').forEach((letter: any) => {
      curName += letter.toLowerCase();
      arrName.push(curName.toLowerCase());
    });
    console.log(name);
    return arrName;
  }

  static generateKeywords(names: any) {
    let arrOfName = names.split(' ');
    let objOfSets = {};
    let previous = '';
    let newSet = [];
    let finalSet: any[] = [];
    for (let i = 0; i < arrOfName.length; i++) {
      newSet.push(KeywordGeneration.createKeywords(arrOfName[i].toLowerCase()));
      for (let j = 0; j < arrOfName.length; j++) {
        if (j > i) {
          previous =
            previous !== ''
              ? previous + ' ' + arrOfName[j].toLowerCase()
              : arrOfName[j].toLowerCase();
          newSet.push(
            KeywordGeneration.createKeywords(
              arrOfName[i].toLowerCase() + ' ' + previous
            )
          );
        }
      }
      previous = '';
    }

    for (let i = 0; i < newSet.length; i++) {
      finalSet = finalSet.concat(newSet[i]);
    }

    let filteredArr: any[] = [];

    finalSet.map((e, i) => {
      if (!filteredArr.includes(e)) {
        filteredArr.push(e);
      }
    });

    return filteredArr;
  }
}

export default KeywordGeneration;

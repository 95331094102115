import { httpRequest } from './base';

export function getTranslationApi(
  texts: string[],
  target: string
): Promise<string[]> {
  return new Promise((resolve: (value: string[]) => void, reject) => {
    httpRequest({
      path: '/translation',
      method: 'POST',
      data: {
        texts,
        target,
      },
    })
      .then((d) => {
        resolve(d.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button } from '../../../../../reusableComponents/Button/Button';
import { Add } from '@mui/icons-material';
import { AddMenu } from '../../../Modals/AddMenu/AddMenu';
import { useRecoilState } from 'recoil';
import { adminAtom } from '../../../../../state/adminAtom';
import firebase from '../../../../../services/firebase';
import { MenuItem } from '../MenuItem/MenuItem';
import { Card } from '../../../../../reusableComponents/Card/Card';
import { PDFVisualizer } from '../../../Modals/PDFVisualizer/PDFVisualizer';
import { LanguageGeneric, Languages } from '../../../../../models/languages';
import {
  checkIfEmptyMultilanguageValue,
  chooseMultilanguageValue,
} from '../../../../../services/multiLanguague';
import { useNavigate } from 'react-router-dom';
import DraggableList from '../../../../../reusableComponents/Draggable/DraggableList';
import { DropResult } from '@hello-pangea/dnd';
import { MenuCreate } from '../../../../../models/menu';
import { reorder } from '../../../../../reusableComponents/Draggable/helpers';
import { dialogAtom } from '../../../../../state/dialogAtom';
import { loadingAtom } from '../../../../../state/loading';
import { toast } from 'react-toastify';
import emptyImage from '../../../../../res/undraw_create_new_menu.svg';
import { useTranslation } from 'react-i18next';

export const MenuOrchestrator = () => {
  const { t } = useTranslation();
  const [adminState, setAdminState] = useRecoilState(adminAtom);
  const [dialogState, setDialogState] = useRecoilState(dialogAtom);
  const [isLoading, setIsLoading] = useRecoilState(loadingAtom);
  const [addMenu, setAddMenu] = useState<boolean>(false);
  const [pending, setPending] = useState<boolean>(false);
  const [sourceUrl, setSourceUrl] = useState<string>('');
  const [pdfVisualizer, setPdfVisualizer] = useState<boolean>(false);
  const [menus, setMenus] = useState<any[]>([]);

  const navigate = useNavigate();

  const getMenus = () => {
    setPending(true);

    firebase
      .firestore()
      .collection('business')
      .doc(adminState.businessData?.id)
      .collection('menus')
      .orderBy('index', 'asc')
      .get()
      .then((snap) => {
        let arr: any[] = [];

        snap.forEach((doc) => {
          let data = doc.data();
          data.id = doc.id;

          arr.push(data);
        });

        setMenus(arr);
        setPending(false);
      })
      .catch((e) => {
        console.log(e);
        setPending(false);
      });
  };

  useEffect(() => {
    getMenus();
  }, []);

  const onDragEnd = (
    { destination, source }: DropResult,
    menus: (MenuCreate & { index?: number })[]
  ) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(menus, source.index, destination.index).map(
      (e, i) => ({ ...e, index: i })
    );

    setAdminState((state) => ({
      ...state,
      toUpdate: true,
      menusToUpdate: newItems as MenuCreate[],
    }));

    setMenus(newItems);
  };
  return (
    <Box
      sx={{
        pt: '64px',
      }}
    >
      <PDFVisualizer
        url={sourceUrl}
        open={pdfVisualizer}
        close={() => {
          setPdfVisualizer(false);
          setSourceUrl('');
        }}
      />
      {addMenu ? (
        <AddMenu
          close={() => {
            setAddMenu(false);
          }}
          reload={() => {
            getMenus();
          }}
        />
      ) : null}
      <Typography textAlign={'center'} variant="h4" mb={3} fontWeight={'bold'}>
        {t('menus')}
      </Typography>

      <Box mt={3} display={'flex'} justifyContent={'center'}>
        <Divider
          sx={{
            width: '50%',
          }}
        >
          <Button
            variant="outlined"
            startIcon={<Add />}
            onClick={() => {
              if (adminState.businessData?.menus !== undefined) {
                if (
                  (adminState.businessData?.menus as number) <
                  (adminState.planData?.menus as number)
                ) {
                  setAddMenu(true);
                } else {
                  setAdminState((state) => ({
                    ...state,
                    plansViewer: true,
                  }));
                }
              } else {
                setAddMenu(true);
              }
            }}
          >
            {t('create_menu')}
          </Button>
        </Divider>
      </Box>

      {menus.length > 0 ? (
        <Card
          mt={2}
          sx={{
            p: 2,
            px: 4,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography fontWeight={'bold'}>Name</Typography>
            </Grid>
            {/* <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <Typography fontWeight={'bold'}>Availavility</Typography>
          </Grid> */}
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography fontWeight={'bold'}>Visibility</Typography>
            </Grid>
          </Grid>
        </Card>
      ) : null}

      <Box mt={2}>
        {pending ? (
          <Stack direction={'row'} justifyContent={'center'}>
            <CircularProgress />
          </Stack>
        ) : null}

        {menus.length < 1 && pending === false ? (
          <Stack direction={'column'} alignItems={'center'} spacing={2} mt={3}>
            <Typography variant="h4">Start creating your menu!</Typography>
            {/* <Button size="large">CREATE NEW MENU</Button> */}
            <img
              src={emptyImage}
              style={{
                maxWidth: '400px',
              }}
            />
          </Stack>
        ) : null}

        {pending == false ? (
          <Stack spacing={1}>
            <DraggableList
              id={'draggableList'}
              items={menus.map(
                (
                  e: MenuCreate & {
                    id: string;
                    sourceUrl: LanguageGeneric<string>;
                  },
                  i
                ) => {
                  const blocked =
                    i + 1 > (adminState.planData?.menus as number);
                  return {
                    index: i,
                    id: e.id,
                    element: (
                      <Box
                        sx={{
                          cursor: blocked ? 'not-allowed' : 'auto',
                          opacity: blocked ? 0.5 : 1,
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <MenuItem
                          handleChangeActive={(b: boolean) => {
                            if (!blocked) {
                              let menusArr = [
                                ...JSON.parse(JSON.stringify(menus)),
                              ];
                              menusArr[i].active = b;
                              setMenus(menusArr);

                              let menusToUpdate = [
                                ...(adminState.menusToUpdate
                                  ? adminState.menusToUpdate
                                  : []),
                              ];
                              if (
                                menusToUpdate.filter(
                                  (v) => v.id === menusArr[i].id
                                ).length > 0
                              ) {
                                let menuIndex = menusToUpdate
                                  .map((e) => e.id)
                                  .indexOf(menusArr[i].id);
                                menusToUpdate[menuIndex] = menusArr[i];
                              } else {
                                menusToUpdate.push(menusArr[i]);
                              }

                              setAdminState((state) => ({
                                ...state,
                                menusToUpdate,
                                toUpdate: true,
                              }));
                            }
                          }}
                          name={chooseMultilanguageValue(
                            e.name,
                            adminState.language as keyof Languages,
                            adminState.language as keyof Languages
                          )}
                          navigate={() => {
                            navigate(e.id);
                            setAdminState((state) => ({
                              ...state,
                              menuData: e,
                            }));
                          }}
                          active={!blocked ? e.active : !blocked}
                          key={i}
                          handleDelete={() => {
                            setDialogState((state) => ({
                              ...state,
                              open: true,
                              setOpen: (o: boolean) => {
                                setDialogState((s) => ({
                                  ...s,
                                  open: o,
                                }));
                              },
                              onConfirm: async () => {
                                setIsLoading(true);

                                try {
                                  let batch = firebase.firestore().batch();
                                  if (
                                    checkIfEmptyMultilanguageValue(
                                      e.sourceUrl
                                    ) === false
                                  ) {
                                    let arr = Object.keys(e.sourceUrl);

                                    for (let i = 0; i < arr.length; i++) {
                                      if (
                                        e.sourceUrl[
                                          arr[i] as keyof Languages
                                        ] !== ''
                                      ) {
                                        await firebase
                                          .storage()
                                          .refFromURL(
                                            e.sourceUrl[
                                              arr[i] as keyof Languages
                                            ]
                                          )
                                          .delete();
                                      }
                                    }
                                  }

                                  if (e.type === 'native') {
                                    let products = await firebase
                                      .firestore()
                                      .collection('business')
                                      .doc(adminState.businessData?.id)
                                      .collection('menus')
                                      .doc(e.id)
                                      .collection('products')
                                      .get();

                                    for (
                                      let i = 0;
                                      i < products.docs.length;
                                      i++
                                    ) {
                                      if (
                                        products.docs[i].data().imageUrl !== ''
                                      ) {
                                        await firebase
                                          .storage()
                                          .refFromURL(
                                            products.docs[i].data().imageUrl
                                          )
                                          .delete();
                                        batch.delete(
                                          firebase
                                            .firestore()
                                            .collection('business')
                                            .doc(adminState.businessData?.id)
                                            .collection('menus')
                                            .doc(e.id)
                                            .collection('products')
                                            .doc(products.docs[i].id)
                                        );
                                      }
                                    }
                                  }

                                  batch.update(
                                    firebase
                                      .firestore()
                                      .collection('business')
                                      .doc(adminState.businessData?.id),
                                    {
                                      menus:
                                        firebase.firestore.FieldValue.increment(
                                          -1
                                        ),
                                    }
                                  );

                                  batch.delete(
                                    firebase
                                      .firestore()
                                      .collection('business')
                                      .doc(adminState.businessData?.id)
                                      .collection('menus')
                                      .doc(e.id)
                                  );
                                  await batch.commit();

                                  toast.info(t('menu_deleted'));
                                  setIsLoading(false);
                                  getMenus();
                                } catch (e) {
                                  console.log(e);
                                  toast.error(t('something_went_wrong'));
                                  setIsLoading(false);
                                }
                              },
                              children: (
                                <Box>
                                  {t('sure_you_want_to_delete_this_menu')}
                                </Box>
                              ),
                              sentiment: 'error',
                              title: t('delete_menu'),
                            }));
                          }}
                          openPdfVisualizer={
                            chooseMultilanguageValue(
                              e.sourceUrl,
                              adminState.language as keyof Languages,
                              adminState.language as keyof Languages
                            ) !== ''
                              ? () => {
                                  setSourceUrl(
                                    chooseMultilanguageValue(
                                      e.sourceUrl,
                                      adminState.language as keyof Languages,
                                      adminState.language as keyof Languages
                                    )
                                  );
                                  setPdfVisualizer(true);
                                }
                              : undefined
                          }
                        />
                      </Box>
                    ),
                  };
                }
              )}
              onDragEnd={(result) => onDragEnd(result, menus)}
            />
          </Stack>
        ) : null}
      </Box>
    </Box>
  );
};

import React, { useEffect, useRef, useState } from 'react';
import firebase from '../../../services/firebase';
import { AddProduct } from '../Modals/AddProduct/AddProduct';
import { Configuration as Configurations } from '../Modals/configurations/Configurations';

import {
  Box,
  Chip,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';
import { BusinessData, WorkingHour } from '../../../models/business';
import {
  AccountBoxOutlined,
  ArrowBack,
  LanguageOutlined,
  PresentToAllOutlined,
  QrCode,
  RestaurantMenuOutlined,
  ScheduleOutlined,
} from '@mui/icons-material';
import { useRecoilState } from 'recoil';
import { dialogAtom } from '../../../state/dialogAtom';
import { loadingAtom } from '../../../state/loading';
import { ProductBase } from '../../../models/product';
import { uploadToStorage } from '../../../services/uploadToStorage';
import { Navbar } from '../Navbar/Navbar';
import { AddSection } from '../Modals/AddSection/AddSection';
import { AddCategory } from '../Modals/AddCategory/AddCategory';
import { adminAtom } from '../../../state/adminAtom';
import { MenuSandbox } from './MenuSandbox/MenuSandbox';
import { Presentation } from './Presentation/Presentation';
import { Languages as LanguagesSection } from './Languages/Languages';
import { Schedule } from './Schedule/Schedule';
import { Address } from './Address/Address';
import { Route, Routes } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { LanguageGeneric, Languages } from '../../../models/languages';
import { QrCode as QrCodeComponent } from './QrCode/QrCode';
import { MyAccount } from './MyAccount/MyAccount';
import { MenuOrchestrator } from './MenuSandbox/MenuOrchestrator/MenuOrchestrator';
import { MenuNative } from './MenuSandbox/MenuNative/MenuNative';
import { MenuPDF } from './MenuSandbox/MenuPDF/MenuPDF';
import {
  distributeValue,
  getMultilanguageValue,
} from '../../../services/multiLanguague';
import { MenuCreate } from '../../../models/menu';
import { useTranslation } from 'react-i18next';
import { PlansViewer } from '../PlansViewer/PlansViewer';
import { subscribeApi, updateSubscriptionApi } from '../../../api/pricing';

import logoPiattoQR from '../../../res/PiattoQR-LOGO.png';

interface PanelProps {
  openAlert: any;
  addToast: any;
  userData: any;
  setCoords: any;
  placeholder: any;
}

export const Panel = ({ userData, placeholder }: PanelProps) => {
  const { t } = useTranslation();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const [drawerWidth, setDrawerWidth] = useState(240);
  const [plans, setPlans] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const [search, setSearch] = useState('');

  const [configurations, setConfigurations] = useState(false);
  const [isLoading, setIsLoading] = useRecoilState(loadingAtom);

  const [dialogState, setDialogState] = useRecoilState(dialogAtom);

  const [adminState, setAdminState] = useRecoilState(adminAtom);

  let _mounted: boolean = false;

  const isActive = (path: string, index: number) => {
    return (
      (index === 0 && path == '/admin/panel/menu') ||
      (index === 1 && path == '/admin/panel/presentation') ||
      (index === 2 && path == '/admin/panel/languages') ||
      (index === 3 && path == '/admin/panel/schedule')
    );
  };

  const isActive2 = (path: string, index: number) => {
    return (
      (index === 0 && path == '/admin/panel/qr') ||
      (index === 1 && path == '/admin/panel/account')
    );
  };

  useEffect(() => {
    if (location.pathname === '/admin/panel') {
      //  navigate('menu');
    }
  }, [location.pathname]);

  const drawer = (
    <div>
      <Toolbar children={<img src={logoPiattoQR} width={'150px'} />} />
      <Divider />
      <List>
        {[t('menu'), t('presentation'), t('languages')].map((text, index) => (
          <ListItem
            key={text}
            disablePadding
            sx={
              isActive(location.pathname, index)
                ? { bgcolor: 'primary.main', color: 'white' }
                : undefined
            }
          >
            <ListItemButton
              color="black"
              disableRipple
              onClick={() => {
                if (index === 0) {
                  navigate('menu');
                }

                if (index === 1) {
                  navigate('presentation');
                }

                if (index === 2) {
                  navigate('languages');
                }

                if (index === 3) {
                  navigate('schedule');
                }

                if (mobileOpen) {
                  handleDrawerClose();
                }
              }}
            >
              <ListItemIcon>
                {index === 0 ? (
                  <RestaurantMenuOutlined
                    sx={
                      isActive(location.pathname, index)
                        ? {
                            color: 'white',
                          }
                        : undefined
                    }
                  />
                ) : null}
                {index === 1 ? (
                  <PresentToAllOutlined
                    sx={
                      isActive(location.pathname, index)
                        ? {
                            color: 'white',
                          }
                        : undefined
                    }
                  />
                ) : null}
                {index === 2 ? (
                  <LanguageOutlined
                    sx={
                      isActive(location.pathname, index)
                        ? {
                            color: 'white',
                          }
                        : undefined
                    }
                  />
                ) : null}
                {index === 3 ? (
                  <ScheduleOutlined
                    sx={
                      isActive(location.pathname, index)
                        ? {
                            color: 'white',
                          }
                        : undefined
                    }
                  />
                ) : null}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}

        {[t('qr_code'), t('my_account')].map((text, index) => (
          <ListItem
            key={text}
            disablePadding
            sx={
              isActive2(location.pathname, index)
                ? { bgcolor: 'primary.main', color: 'white' }
                : undefined
            }
          >
            <ListItemButton
              disableRipple
              onClick={() => {
                if (index === 0) {
                  navigate('qr');
                }

                if (index === 1) {
                  navigate('account');
                }
                if (mobileOpen) {
                  handleDrawerClose();
                }
              }}
            >
              <ListItemIcon>
                {index === 0 ? (
                  <QrCode
                    sx={
                      location.pathname === '/admin/panel/qr'
                        ? { bgcolor: 'primary.main', color: 'white' }
                        : undefined
                    }
                  />
                ) : null}

                {index === 1 ? (
                  <AccountBoxOutlined
                    sx={
                      location.pathname === '/admin/panel/account'
                        ? { bgcolor: 'primary.main', color: 'white' }
                        : undefined
                    }
                  />
                ) : null}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}

        <ListItem
          key={t('sign_out')}
          disablePadding
          sx={{
            mt: 5,
          }}
        >
          <ListItemButton
            disableRipple
            onClick={() => {
              firebase.auth().signOut();
            }}
          >
            <ListItemIcon>
              <ArrowBack />
            </ListItemIcon>
            <ListItemText primary={t('sign_out')} />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  const updateBusiness = async () => {
    setIsLoading(true);

    try {
      let flag = 0;
      let messages = [];

      let data: BusinessData = Object.assign({}, adminState.businessData);

      if (adminState.businessCover) {
        if (data.businessCoverImageUrl !== '') {
          await firebase
            .storage()
            .refFromURL(data.businessCoverImageUrl)
            .delete();
        }
        data.businessCoverImageUrl = (await uploadToStorage(
          adminState.businessCover,
          `business/${adminState.businessData?.id}`,
          setIsLoading,
          (message: string) => {
            toast.info(message);
          }
        )) as string;
      }

      if (adminState.businessImage) {
        if (data.businessImageUrl !== '') {
          await firebase.storage().refFromURL(data.businessImageUrl).delete();
        }
        data.businessImageUrl = (await uploadToStorage(
          adminState.businessImage,
          `business/${adminState.businessData?.id}`,
          setIsLoading,
          (message: string) => {
            toast.info(message);
          }
        )) as string;
      }

      const schedule = adminState.businessData?.workingHours;

      for (let i = 0; i < (schedule as any).length; i++) {
        if ((schedule as any)[i].startHour === null) {
          flag = 1;
          messages.push(
            `${t('opening_hour"')} ${translate(i)} ${t('is_not_defined')}`
          );
        }

        if ((schedule as any)[i].endHour === null) {
          flag = 1;
          messages.push(
            `${t('clousure_time')} ${translate(i)} ${t('is_not_defined')}`
          );
        }

        if (
          (schedule as any)[i].startHour !== null &&
          (schedule as any)[i].endHour !== null
        ) {
          if ((schedule as any)[i].startHour > (schedule as any)[i].endHour) {
            flag = 1;
            messages.push(
              t('opening_hour_cannot_be_greater_than_the_closure_hour')
            );
          }
        }
      }

      let batch = firebase.firestore().batch();

      if (flag === 0) {
        setIsLoading(true);

        if (data.changes !== undefined) {
          delete data.changes;
        }

        let sourceUrl = adminState.menuData
          ? JSON.parse(JSON.stringify(adminState.menuData?.sourceUrl))
          : distributeValue('');
        if (adminState.menuData) {
          if (adminState.menuData.pdfs) {
            for (let key of Object.keys(adminState.menuData.pdfs)) {
              if (adminState.menuData.pdfs[key as keyof Languages] !== null) {
                if (sourceUrl) {
                  if (sourceUrl[key as keyof Languages] !== '') {
                    await firebase
                      .storage()
                      .refFromURL(sourceUrl[key as keyof Languages])
                      .delete();
                  }

                  sourceUrl[key as keyof Languages] = (await uploadToStorage(
                    getMultilanguageValue(
                      adminState.menuData.pdfs,
                      key as keyof Languages
                    ) as Blob,
                    `/business/${adminState.businessData?.id}/menus/${adminState.menuData.id}`,
                    setIsLoading,
                    toast.info
                  )) as string;
                }

                batch.update(
                  firebase
                    .firestore()
                    .collection('business')
                    .doc(adminState.businessData?.id)
                    .collection('menus')
                    .doc(adminState.menuData.id as string),
                  {
                    sourceUrl,
                  }
                );
              }
            }
          }
        }

        if (adminState.menusToUpdate) {
          for (let i = 0; i < adminState.menusToUpdate.length; i++) {
            batch.update(
              firebase
                .firestore()
                .collection('business')
                .doc(adminState.businessData?.id)
                .collection('menus')
                .doc(adminState.menusToUpdate[i].id),
              {
                index: adminState.menusToUpdate[i].index,
                active: adminState.menusToUpdate[i].active,
              }
            );
          }
        }

        let menuData = JSON.parse(JSON.stringify(adminState.menuData));
        if (adminState.menuData) {
          if (menuData.pdfs) {
            delete menuData.pdfs;
          }

          if (menuData.sourceUrl) {
            delete menuData.sourceUrl;
          }

          if (adminState.menuData.id) {
            batch.update(
              firebase
                .firestore()
                .collection('business')
                .doc(adminState.businessData?.id)
                .collection('menus')
                .doc(adminState.menuData.id),
              {
                ...menuData,
                updated: firebase.firestore.FieldValue.serverTimestamp(),
              }
            );
          }
        }

        batch.update(
          firebase
            .firestore()
            .collection('business')
            .doc(adminState.businessData?.id),
          {
            ...data,
            updated: new Date(),
          }
        );

        for (let id of adminState.productsToUpdate) {
          batch.update(
            firebase
              .firestore()
              .collection('business')
              .doc(adminState.businessData?.id)
              .collection('menus')
              .doc(adminState.menuData?.id)
              .collection('products')
              .doc(id),
            {
              updated: new Date(),
              index: adminState.products.filter((value) => value.id === id)[0]
                .index,
            }
          );
        }

        batch
          .commit()
          .then(() => {
            let menuData =
              adminState.menuData !== null
                ? JSON.parse(JSON.stringify(adminState.menuData))
                : null;

            if (menuData) {
              if (menuData.pdfs) {
                delete menuData.pdfs;
              }
            }

            setAdminState((state) => ({
              ...state,
              productsToUpdate: [],
              toUpdate: false,
              businessImage: null,
              businessCover: null,
              menuData: {
                ...state.menuData,
                sourceUrl,
              } as MenuCreate,
            }));

            setIsLoading(false);
            toast.info(t('business_updated'));
          })
          .catch((e: any) => {
            console.log(e);
            toast.error(t('something_went_wrong'));
            setIsLoading(false);
          });
      } else {
        for (let i = 0; i < messages.length; i++) {
          toast.error(messages[i]);
        }
      }
    } catch (e) {
      console.log(e);
      toast.error(t('something_went_wrong'));
      setIsLoading(false);
    }
  };

  const translate = (day: string | number) => {
    if (day == '0') {
      return t('monday');
    }

    if (day == '1') {
      return t('tuesday');
    }

    if (day == '2') {
      return t('wednesday');
    }

    if (day == '3') {
      return t('thursday');
    }

    if (day == '4') {
      return t('friday');
    }

    if (day == '5') {
      return t('saturday');
    }

    if (day == '6') {
      return t('sunday');
    }
  };

  function isEquivalent(a: any, b: any) {
    // Create arrays of property names
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length != bProps.length) {
      return false;
    }

    for (var i = 0; i < aProps.length; i++) {
      var propName = aProps[i];

      // If values of same property are not equal,
      // objects are not equivalent
      if (a[propName] !== b[propName]) {
        return false;
      }
    }

    // If we made it this far, objects
    // are considered equivalent
    return true;
  }

  useEffect(() => {
    // getProducts();
  }, []);

  const container =
    window !== undefined ? () => window.document.body : undefined;

  function getDaysLeftUntilTrialEnds(trialEndTimestamp: number): number {
    // Get the current date in Unix timestamp format (seconds)
    const currentDate = new Date().getTime() / 1000;

    // Calculate the difference in seconds between the trial end date and the current date
    const differenceInSeconds = trialEndTimestamp - currentDate;

    // Convert the difference from seconds to days
    const differenceInDays = Math.ceil(differenceInSeconds / (60 * 60 * 24));

    return differenceInDays;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      {
        <PlansViewer
          open={adminState.plansViewer}
          currentPlan={adminState.planData?.key}
          selectPlan={(plan_key: string) => {
            if (
              adminState.businessData?.plan.status !== 'canceled' &&
              adminState.planData?.key !== 'free'
            ) {
              setIsLoading(true);

              updateSubscriptionApi(plan_key)
                .then(() => {
                  setIsLoading(false);
                })
                .catch((e) => {
                  console.log(e);
                  setIsLoading(false);
                  toast.error(t('something_went_wrong'));
                });
            } else {
              setIsLoading(true);

              subscribeApi(plan_key as any)
                .then(() => {
                  setIsLoading(false);
                })
                .catch((e) => {
                  console.log(e);
                  setIsLoading(false);
                });
            }
          }}
          onClose={() => {
            setAdminState((state) => ({
              ...state,
              plansViewer: false,
            }));
          }}
        />
      }
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Navbar
        drawerToggle={handleDrawerToggle}
        drawerWidth={drawerWidth}
        logout={() => {
          firebase.auth().signOut();
        }}
        openSettings={() => {
          setConfigurations(true);
        }}
        openNotifications={() => {
          // setOpenNotifications(true);
        }}
        openPreview={() => {
          window.open('/' + adminState.businessData?.businessUrl);
        }}
        changesToSave={adminState.toUpdate}
        upgrade={() => {
          setAdminState((state) => ({
            ...state,
            plansViewer: true,
          }));
        }}
        save={() => {
          setDialogState((s) => ({
            ...s,
            open: true,
            title: t('do_you_want_to_save_changed'),
            children: <div></div>,
            sentiment: 'primary',
            setOpen: (v: boolean) => {
              setDialogState((st) => ({
                ...st,
                open: v,
              }));
            },
            onConfirm: () => {
              updateBusiness();
            },
          }));
        }}
      />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          backgroundColor: 'rgba(0,0,0,0.05)',
          minHeight: '100vh',
          maxWidth: '100vw',
          p: {
            xs: 2,
            sm: 3,
            md: 4,
            lg: 5,
            xl: 5,
          },

          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        {adminState.businessData?.plan?.trial_end !== null &&
        adminState.businessData?.plan?.trial_end !== undefined &&
        adminState.businessData?.plan?.status !== 'canceled' ? (
          <Box
            sx={{
              pt: '64px',
            }}
          >
            <Chip
              label={
                t('trial_ends_in') +
                ' ' +
                getDaysLeftUntilTrialEnds(
                  adminState.businessData?.plan.trial_end
                ) +
                ' ' +
                t('days')
              }
              color="secondary"
              size="medium"
            />
          </Box>
        ) : null}
        {adminState.addSection ? (
          <AddSection
            close={() => {
              setAdminState((state) => ({
                ...state,
                addSection: false,
                sectionData: null,
              }));
            }}
          />
        ) : null}
        {adminState.addCategory ? (
          <AddCategory
            automaticSection={adminState.automaticSection}
            close={() => {
              setAdminState((state) => ({
                ...state,
                addCategory: false,
                categoryData: null,
              }));
            }}
          />
        ) : null}
        {configurations ? (
          <Configurations
            businessData={adminState.businessData}
            userData={userData}
            close={() => {
              setConfigurations(false);
            }}
          />
        ) : null}
        {adminState.addProduct ? (
          <AddProduct
            reload={() => {}}
            businessData={adminState.businessData}
            categories={
              adminState.businessData?.sections as LanguageGeneric<string>[]
            }
            itemData={adminState.productData}
            close={() => {
              setAdminState((state) => ({
                ...state,
                addProduct: false,
                productData: null,
              }));
            }}
            automaticCategory={adminState.automaticCategory}
            automaticSection={adminState.automaticSection}
            data={undefined}
          />
        ) : null}{' '}
        <Box>
          <Routes>
            <Route path="/presentation" element={<Presentation />} />
            <Route path="/languages" element={<LanguagesSection />} />
            <Route path="/schedule" element={<Schedule />} />
            <Route path="/address" element={<Address />} />
            <Route path="/menu" element={<MenuOrchestrator />} />
            <Route
              path="/menu/:menuId"
              element={
                adminState.menuData?.type === 'native' ? (
                  <MenuNative />
                ) : adminState.menuData?.type === 'pdf' ? (
                  <MenuPDF />
                ) : null
              }
            />
            <Route path="/qr" element={<QrCodeComponent />} />
            <Route path="/account" element={<MyAccount />} />
          </Routes>
        </Box>
      </Box>
    </Box>
  );
};

import { httpRequest } from './base';

export const getPricingApi = () => {
  return httpRequest({
    path: '/pricing/config',
    method: 'get',
  });
};

export const updateSubscriptionApi = (plan_key: string) => {
  return httpRequest({
    path: '/pricing/update-subscription',
    method: 'POST',
    data: {
      plan_key,
    },
  });
};

export const subscribeApi = (
  priceId:
    | 'free'
    | 'basic-monthly'
    | 'basic-yearly'
    | 'pro-monthly'
    | 'pro-yearly'
) => {
  return new Promise((resolve, reject) => {
    httpRequest({
      path: '/pricing/create-checkout-session-subscription',
      method: 'POST',
      data: {
        priceId,
        trial: priceId === 'pro-monthly' || priceId === 'pro-yearly' ? '1' : '',
      },
    })
      .then((d) => {
        resolve(d.data);

        if (priceId !== 'free') {
          window.location.href = d.data.url;
        }
      })
      .catch((e) => {
        reject();
        console.log(e);
      });
  });
};

export const checkoutSessionFeedbackApi = (sessionId: string) => {
  return httpRequest({
    path: '/pricing/feedback-checkout-session',
    method: 'POST',
    data: {
      sessionId,
    },
  });
};

export const customerPortalApi = () => {
  return httpRequest({
    path: '/pricing/customer-portal',
  });
};

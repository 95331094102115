import { Image, PhotoCamera, ImageOutlined } from '@mui/icons-material';
import { styled } from '@mui/material';
import { DropzoneArea, DropzoneAreaProps } from 'react-mui-dropzone';
import styles from './Dropzone.module.css';
import React from 'react';
import classNames from 'classnames';

const CustomDropzoneStyled = styled(DropzoneArea)(({ theme }) => {
  return {};
});

export const Dropzone = (props: DropzoneAreaProps) => {
  return (
    <DropzoneArea
      Icon={ImageOutlined}
      {...props}
      dropzoneClass={classNames(styles['dropzone'], props.dropzoneClass)}
    />
  );
};

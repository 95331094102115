import {
  Box,
  CardContent,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Card } from '../../../reusableComponents/Card/Card';
import { TextInput } from '../../../reusableComponents/TextInput/TextInput';
import { Button } from '../../../reusableComponents/Button/Button';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import QRCode from 'qrcode';
import piattoqrLogo from '../../../res/PiattoQR-LOGO-WHITE.png';

//@ts-ignore
import { Select } from '../../../reusableComponents/Select/Select';
import {
  BusinessCreate,
  BusinessData,
  businessFormFields,
} from '../../../models/business';
import { ValueModel } from '../../../models/value';
import { fieldValidator } from '../../../models/validation';
import { loadingAtom } from '../../../state/loading';
import { useRecoilState } from 'recoil';
import { toast } from 'react-toastify';

import firebase from '../../../services/firebase';
import { completeBusinessApi, registerApi } from '../../../api/register';
import { getPricingApi, subscribeApi } from '../../../api/pricing';
import restaurantBackground from '../../../res/santa-caterina.jpg';

import GoogleButton from 'react-google-button';
import { testGoogleAuthApi } from '../../../api/test';
import { Languages } from '../../../models/languages';
import { currencyList } from '../../../services/currency';
import { planDetails } from '../../../services/plan';
import PricingCard from './PricingCard/PricingCard';
import { PlansViewer } from '../PlansViewer/PlansViewer';
import { LanguagesSpace } from '../../../components/LanguagePicker/LanguagePicker';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const DefaultLocation = { lat: 10, lng: 106 };
const DefaultZoom = 10;

const provider = new firebase.auth.GoogleAuthProvider();

type RegisterProps = {};

export const Register = (props: RegisterProps) => {
  const { t } = useTranslation();
  const [businessName, setBusinessName] = useState<ValueModel<string>>({
    valid: false,
    value: '',
    error: '',
  });
  const [type, setType] = useState<ValueModel<string>>({
    valid: false,
    error: '',
    value: '',
  });

  const [name, setName] = useState<ValueModel<string>>({
    valid: false,
    error: '',
    value: '',
  });
  const [lastName, setLastName] = useState<ValueModel<string>>({
    valid: false,
    error: '',
    value: '',
  });
  const [phone, setPhone] = useState<ValueModel<string>>({
    valid: false,
    error: '',
    value: '',
  });
  const [email, setEmail] = useState<ValueModel<string>>({
    valid: false,
    error: '',
    value: '',
  });
  const [password, setPassword] = useState<ValueModel<string>>({
    valid: false,
    value: '',
    error: '',
  });
  const [repeatPassword, setRepeatPassword] = useState<ValueModel<string>>({
    valid: false,
    value: '',
    error: '',
  });

  const [step1Valid, setStep1Valid] = useState(false);
  const [step2Valid, setStep2Valid] = useState(false);

  const [address, setAddress] = useState('');
  const [position, setPosition] = useState<any>({ lat: -34.397, lng: 150.644 });

  const [isMarkerShown, setIsMarkerShow] = useState<boolean>(true);
  const [isValid, setIsValid] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useRecoilState(loadingAtom);

  const [activeStep, setActiveStep] = useState<number>(0);

  const [qrImage, setQrImage] = useState('');

  const [businessUrl, setBusinessUrl] = useState<string>('');

  const [prices, setPrices] = useState([]);

  const [priceId, setPriceId] = useState('');

  const [businessData, setBusinessData] = useState<BusinessData | null>(null);

  const [defaultLanguage, setDefaultLanguage] = useState<string>('');

  const [currency, setCurrency] = useState<string>('EUR');

  const [menuType, setMenuType] = useState<string>('native');

  const [planSelected, setPlanSelected] = useState<
    'free' | 'basic-monthly' | 'basic-yearly' | 'pro-monthly' | 'pro-yearly'
  >('pro-yearly');

  const [planIds, setPlanIds] = useState({
    free: 'price_1Oo1cVClMWSvGxWVDtpbGDaY',
    basic: 'price_1OeLUtClMWSvGxWVrjwN2ZEi',
    pro: 'price_1Oo1cVClMWSvGxWVBWHeLOgF',
  });

  const [plansViewer, setPlansViewer] = useState<boolean>(false);

  const navigate = useNavigate();

  let authListener: any;

  const getPrices = () => {
    getPricingApi()
      .then((d) => {
        setPrices(d.data.prices);
        if (d.data.prices.length > 0) {
          setPriceId(d.data.prices[0].id);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const triggerStep1Validation = () => {
    fieldValidator(
      businessFormFields.businessName,
      setBusinessName,
      businessName.value
    );

    fieldValidator(businessFormFields.email, setEmail, email.value);
    fieldValidator(businessFormFields.name, setName, name.value);
    fieldValidator(businessFormFields.lastName, setLastName, lastName.value);
    fieldValidator(businessFormFields.password, setPassword, password.value);
    fieldValidator(
      businessFormFields.password,
      setRepeatPassword,
      repeatPassword.value
    );
  };

  const locationHook = useLocation();

  useEffect(() => {
    // Creating URLSearchParams object from the current URL
    const queryParams = new URLSearchParams(locationHook.search);

    // Getting the 'plan' query parameter from the URL
    if (queryParams.has('plan')) {
      const planParam = queryParams.get('plan');

      if (planParam === 'free') {
        setPlanSelected('free');
      }
      if (planParam === 'pro') {
        setPlanSelected('pro-monthly');
      }

      if (planParam === 'basic') {
        setPlanSelected('basic-monthly');
      }
    }

    // Updating the 'plan' state with the value of the query parameter
    // If 'plan' query parameter does not exist, it will set the state to null
  }, [locationHook]);

  useEffect(() => {
    if (name.valid && lastName.valid && email.valid && password.valid) {
      if (password.value === repeatPassword.value) {
        setStep1Valid(true);
      } else {
        setStep1Valid(false);
      }
    } else {
      setStep1Valid(false);
    }
  }, [name, lastName, email, password, repeatPassword]);

  let registerListener: any = undefined;

  useEffect(() => {
    if (
      businessName.valid &&
      String(currency).trim() !== '' &&
      String(defaultLanguage).trim() !== ''
    ) {
      setStep2Valid(true);
    } else {
      setStep2Valid(false);
    }
  }, [businessName, currency, defaultLanguage]);

  useEffect(() => {
    getPrices();

    authListener = firebase.auth().onAuthStateChanged(
      (u) => {
        if (u) {
          registerListener = firebase
            .firestore()
            .collection('business')
            .where('owner', '==', u.uid)
            .onSnapshot(
              (snap) => {
                if (!snap.empty) {
                  let data: BusinessData = snap.docs[0].data() as BusinessData;
                  setBusinessData(data);
                  if (data.plan === null) {
                    if (data.completed) {
                      setActiveStep(2);
                    } else {
                      setActiveStep(1);
                    }
                    setIsLoading(false);
                  } else {
                    setIsLoading(false);
                  }
                }
              },
              (e) => {
                console.log(e);
              }
            );
        } else {
          if (registerListener !== undefined) {
            registerListener(); //Clean up the listener
          }
        }
      },
      (e) => {
        console.log(e);
      }
    );
    return () => {
      if (authListener !== undefined) {
        authListener(); //CLEAN UP THE LISTENER
      }

      if (registerListener !== undefined) {
        registerListener(); //Clean up the listener
      }
    };
  }, []);

  const register = () => {
    const data: BusinessCreate = {
      businessName: businessName.value,
      email: email.value,
      lastName: lastName.value,
      name: name.value,
      password: password.value,
      phone: phone.value,
      businessUrl: businessUrl,
    };

    setIsLoading(true);
    registerApi(data)
      .then((r) => {
        console.log(r);

        firebase.auth().signInWithEmailAndPassword(email.value, password.value);
      })
      .catch((e) => {
        console.log(e);
        toast.error(t('something_went_wrong'));
        setIsLoading(false);
      });
  };

  const completeBusiness = () => {
    const data = {
      currency: currency as string,
      defaultLanguage: defaultLanguage as LanguagesSpace,
      businessName: businessName.value as string,
    } as {
      currency: string;
      defaultLanguage: LanguagesSpace;
      businessName: string;
    };

    setIsLoading(true);

    completeBusinessApi(data)
      .then(() => {
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  const handleChange = (address: string) => {
    setAddress(address);
  };

  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);

  const [location, setLocation] = useState(defaultLocation);
  const [zoom, setZoom] = useState(DefaultZoom);

  function handleResetLocation() {
    setDefaultLocation({ ...DefaultLocation });
    setZoom(DefaultZoom);
  }

  const delayedShowMarker = () => {
    setTimeout(() => {
      setIsMarkerShow(true);
    }, 3000);
  };

  const handleMarkerClick = () => {
    setIsMarkerShow(false);
    delayedShowMarker();
  };

  useEffect(() => {
    let errorCheck = 0;
    const messages = [];

    if (!businessName.valid) {
      errorCheck = 1;
    }

    if (!phone.valid) {
      errorCheck = 1;
    }

    if (!email.valid) {
      errorCheck = 1;
    }

    if (address == '') {
      errorCheck = 1;
    }

    if (!password.valid) {
      errorCheck = 1;
    }

    if (password.value !== repeatPassword.value) {
      errorCheck = 1;
    }

    if (errorCheck === 0) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [
    businessName,
    type,
    name,
    lastName,
    phone,
    email,
    address,
    password,
    repeatPassword,
  ]);

  const handleSelect = (address: string) => {
    geocodeByAddress(address)
      .then(async (results) => {
        setAddress(results[0].formatted_address);
        return getLatLng(results[0]);
      })
      .then((latLng) => setPosition(latLng))
      .catch((error) => console.error('Error', error));
  };

  const generateQR = async (text: string): Promise<string> => {
    try {
      const url = await QRCode.toDataURL(text, { scale: 10 });
      return url;
    } catch (err) {
      console.error(err);
      return '';
    }
  };

  const subscribe = (
    priceId:
      | 'free'
      | 'basic-monthly'
      | 'basic-yearly'
      | 'pro-monthly'
      | 'pro-yearly'
  ) => {
    setIsLoading(true);

    subscribeApi(priceId)
      .then(() => {
        setIsLoading(false);

        if (priceId === 'free') {
          const _listenPlanChange = firebase
            .firestore()
            .collection('business')
            .doc(businessData?.id)
            .onSnapshot(
              (doc) => {
                if (doc?.data()?.plan !== null) {
                  window.location.pathname = '/admin/login';

                  if (_listenPlanChange !== undefined) {
                    _listenPlanChange(); //clean up the listener
                  }
                }

                setTimeout(() => {
                  if (_listenPlanChange !== undefined) {
                    _listenPlanChange(); //clean up the listener
                  }
                }, 10000);
              },
              (e) => {
                console.log(e);
                toast.error(t('something_went_wrong'));
              }
            );
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  const completeStep2 = async () => {
    if (step2Valid) {
      completeBusiness();
    }
  };

  const completeStep1 = async () => {
    setIsLoading(true);

    if (step1Valid) {
      const data: BusinessCreate = {
        businessName: businessName.value,
        email: email.value,
        lastName: lastName.value,
        name: name.value,
        password: password.value,
        phone: phone.value,
        businessUrl: businessUrl,
      };

      setIsLoading(true);
      registerApi(data)
        .then((r) => {
          firebase
            .auth()
            .signInWithEmailAndPassword(email.value, password.value);
        })
        .catch((e) => {
          console.log(e);
          toast.error(t('something_went_wrong'));
          setIsLoading(false);
        });
    } else {
      toast.error(t('there_are_some_fields_that_are_not_valid'));
      setIsLoading(false);
      triggerStep1Validation();
    }
  };

  const camelCaseToCapitalizedText = (input: string): string => {
    // Split the string into words by looking for points in the string where a lowercase letter is followed by an uppercase letter
    const words = input.split(/(?=[A-Z])/);

    // Capitalize the first letter of each word and join them with a space
    return words
      .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        p: 2,
        backgroundImage: `url(${restaurantBackground})`, // Ensure restaurantBackground contains a valid image URL
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: 'rgba(0, 0,0, 0.6)', // Example: A semi-transparent black overlay
        backgroundBlendMode: 'multiply',
      }}
    >
      <PlansViewer
        open={plansViewer}
        onClose={() => {
          setPlansViewer(false);
        }}
        selectPlan={(
          p:
            | 'free'
            | 'basic-monthly'
            | 'basic-yearly'
            | 'pro-monthly'
            | 'pro-yearly'
        ) => {
          setPlanSelected(p);
        }}
      />

      <Box display={'flex'} justifyContent={'center'} mb={5}>
        <img src={piattoqrLogo} width={'250px'} />
      </Box>

      <Typography textAlign={'center'} variant="h4" color={'white'}>
        {t('register')}
      </Typography>

      <Stack alignItems={'center'}>
        {activeStep === 0 ? (
          <Card
            mt={3}
            sx={{
              width: {
                xs: '90%',
                sm: '90%',
                md: '30rem',
                lg: '40rem',
                xl: '40rem',
              },
              p: 2,
            }}
          >
            <CardContent>
              <GoogleButton
                onClick={() => {
                  //setIsLoading(true);
                  firebase
                    .auth()
                    .signInWithPopup(provider)
                    .then((result) => {
                      /** @type {firebase.auth.OAuthCredential} */
                      const credential: firebase.auth.OAuthCredential | null =
                        result.credential;

                      // This gives you a Google Access Token. You can use it to access the Google API.
                      const token = credential?.accessToken;
                      // The signed-in user info.

                      const user = result.user;
                      // IdP data available in result.additionalUserInfo.profile.
                      // ...
                      // setIsLoading(false);
                      testGoogleAuthApi()
                        .then(() => {
                          console.log('Authenticated');
                        })
                        .catch((e) => {
                          console.log(e);
                        });
                    })
                    .catch((error) => {
                      // Handle Errors here.
                      var errorCode = error.code;
                      var errorMessage = error.message;
                      // The email of the user's account used.
                      var email = error.email;
                      // The firebase.auth.AuthCredential type that was used.
                      var credential = error.credential;
                      //setIsLoading(false);
                      // ...
                    });
                }}
                type="light"
                style={{
                  boxShadow: 'none',
                  border: '1.5px solid #e0e0e0',
                  overflow: 'hidden',
                  borderRadius: 'var(--border-radius)',
                  width: '100%',
                  textAlign: 'left',
                  display: 'flex',
                  justifyContent: 'center',
                  gap: 4,
                }}
              />

              <Divider
                sx={{
                  mt: 3,
                  backgroundColor: 'lightgrey',
                  mb: 3,
                }}
              />
              <Stack mt={3} spacing={2}>
                <Box
                  sx={{
                    boxSizing: 'border-box',
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextInput
                        fullWidth
                        label={t('first_name')}
                        value={name.value}
                        placeholder={t('first_name')}
                        onChange={(e) => {
                          fieldValidator(
                            businessFormFields.name,
                            setName,
                            e.currentTarget.value
                          );
                        }}
                        sx={{
                          textAlign: 'left',
                        }}
                        helperText={name.error}
                        error={name.error !== ''}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextInput
                        fullWidth
                        label={t('last_name')}
                        value={lastName.value}
                        placeholder={t('last_name')}
                        onChange={(e) => {
                          fieldValidator(
                            businessFormFields.lastName,
                            setLastName,
                            e.currentTarget.value
                          );
                        }}
                        sx={{
                          textAlign: 'left',
                        }}
                        helperText={lastName.error}
                        error={lastName.error !== ''}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <TextInput
                  fullWidth
                  label={t('email')}
                  value={email.value}
                  placeholder={t('write_your_email')}
                  onChange={(e) =>
                    fieldValidator(
                      businessFormFields.email,
                      setEmail,
                      e.currentTarget.value
                    )
                  }
                  sx={{
                    textAlign: 'left',
                  }}
                  helperText={email.error}
                  error={email.error !== ''}
                />

                <Box
                  sx={{
                    boxSizing: 'border-box',
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextInput
                        fullWidth
                        label={t('password')}
                        type="password"
                        placeholder={t('write_your_password')}
                        value={password.value}
                        onChange={(e) =>
                          fieldValidator(
                            businessFormFields.password,
                            setPassword,
                            e.currentTarget.value
                          )
                        }
                        sx={{
                          textAlign: 'left',
                        }}
                        helperText={password.error}
                        error={password.error !== ''}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextInput
                        fullWidth
                        label={t('repeat_password')}
                        type="password"
                        placeholder={t('repeat_your_password')}
                        value={repeatPassword.value}
                        onChange={(e) =>
                          fieldValidator(
                            businessFormFields.password,
                            setRepeatPassword,
                            e.currentTarget.value
                          )
                        }
                        sx={{
                          textAlign: 'left',
                        }}
                        helperText={repeatPassword.error}
                        error={repeatPassword.error !== ''}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Stack>
              <Box
                sx={{
                  mt: 4,
                }}
              >
                <Button
                  fullWidth
                  size="large"
                  onClick={() => {
                    completeStep1();
                  }}
                >
                  {t('continue')}
                </Button>
              </Box>
            </CardContent>
          </Card>
        ) : null}

        {activeStep === 1 ? (
          <Card
            mt={3}
            sx={{
              width: {
                xs: '90%',
                sm: '90%',
                md: '30rem',
                lg: '40rem',
                xl: '40rem',
              },
              p: 2,
            }}
          >
            <CardContent>
              <Typography textAlign={'center'} variant="h5">
                {t('lets_complete_some_details')}
              </Typography>
              <Stack spacing={2} mt={3}>
                <TextInput
                  fullWidth
                  label={t('business_name')}
                  type="text"
                  required
                  placeholder={t('write_the_name_of_your_business')}
                  value={businessName.value}
                  onChange={(e) =>
                    fieldValidator(
                      businessFormFields.businessName,
                      setBusinessName,
                      e.currentTarget.value
                    )
                  }
                  sx={{
                    textAlign: 'left',
                  }}
                  helperText={businessName.error}
                  error={businessName.error !== ''}
                />

                <Select
                  labelText={t('default_language')}
                  placeholder={t('select_the_default_language')}
                  value={defaultLanguage}
                  required
                  options={Object.keys(businessData?.languages as object).map(
                    (e, i) => ({
                      value: e,
                      text: businessData?.languages[e as keyof Languages].name,
                      key: i,
                    })
                  )}
                  onChange={(el) => {
                    setDefaultLanguage(el.target.value as string);
                  }}
                />

                <Select
                  labelText={t('currency')}
                  placeholder={t('select_currency')}
                  value={currency}
                  options={currencyList.map((e, i) => ({
                    value: e.value,
                    text: e.text + ' - ' + e.symbol,
                    key: i,
                  }))}
                  required
                  onChange={(e) => {
                    setCurrency(e.target.value as string);
                  }}
                />
              </Stack>

              <Stack
                sx={{
                  mt: 2,
                }}
                spacing={1}
              >
                <Button
                  fullWidth
                  onClick={() => {
                    completeStep2();
                  }}
                  disabled={!step2Valid}
                >
                  Continue
                </Button>

                {/* <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => {
                    setActiveStep(activeStep - 1);
                  }}
                >
                  Back
                </Button> */}
              </Stack>
            </CardContent>
          </Card>
        ) : null}

        {activeStep === 2 ? (
          <Card
            mt={3}
            sx={{
              width: {
                xs: '90%',
                sm: '90%',
                md: '30rem',
                lg: '40rem',
                xl: '40rem',
              },
              p: 2,
            }}
          >
            <CardContent>
              <Typography textAlign={'center'} variant="h5">
                {t('your_plan')}
              </Typography>
              <Stack spacing={2} mt={3}>
                {planSelected === 'pro-monthly' ? (
                  <PricingCard
                    title={t('pro')}
                    features={[
                      t('unlimited_menus'),
                      t('unlimited_visits'),
                      t('product_images_and_variations'),
                      t('multilanguage'),
                      t('pdf_menus'),
                      t('customized_support'),
                    ]}
                    description=""
                    price="9.99"
                    previousPrice="12.00"
                    label={t('most_common')}
                    buttonText={t('start_now')}
                    onClick={() => {}}
                    headerColor="secondary.main"
                    frequency="month"
                    disableButton
                  />
                ) : null}

                {planSelected === 'pro-yearly' ? (
                  <PricingCard
                    title={t('pro')}
                    features={[
                      t('unlimited_menus'),
                      t('unlimited_visits'),
                      t('product_images_and_variations'),
                      t('multilanguage'),
                      t('pdf_menus'),
                      t('customized_support'),
                    ]}
                    description=""
                    price="99.99"
                    previousPrice="140.00"
                    label={t('most_common')}
                    buttonText={t('start_now')}
                    onClick={() => {}}
                    headerColor="secondary.main"
                    frequency="year"
                    disableButton
                  />
                ) : null}

                {planSelected === 'basic-monthly' ? (
                  <PricingCard
                    title={t('basic')}
                    features={[
                      `3 ${t('menus')}`,
                      `2000 ${t('visits_per_month')}`,
                      t('product_images_and_variations'),
                      t('basic_support'),
                    ]}
                    description=""
                    price="5.99"
                    label={t('starter')}
                    headerColor="white"
                    buttonText={t('start_now')}
                    onClick={() => {}}
                    frequency="month"
                    disableButton
                  />
                ) : null}

                {planSelected === 'basic-yearly' ? (
                  <PricingCard
                    title={t('basic')}
                    features={[
                      `3 ${t('menus')}`,
                      `2000 ${t('visits_per_month')}`,
                      t('product_images_and_variations'),
                      t('basic_support'),
                    ]}
                    description=""
                    price="59.99"
                    label={t('starter')}
                    headerColor="white"
                    buttonText={t('start_now')}
                    onClick={() => {}}
                    frequency="year"
                    disableButton
                  />
                ) : null}

                {planSelected === 'free' ? (
                  <PricingCard
                    title={t('always_free')}
                    features={[
                      `1 ${t('menu')}`,
                      `300 ${t('visits_per_month')}`,
                    ]}
                    description=""
                    price={t('free')}
                    label={t('free_uppercase')}
                    buttonText={t('start_now')}
                    headerColor="white"
                    onClick={() => {}}
                    disableButton
                  />
                ) : null}
              </Stack>

              <Stack
                sx={{
                  mt: 2,
                }}
                spacing={1}
              >
                <Button
                  fullWidth
                  onClick={() => {
                    subscribe(planSelected);
                  }}
                >
                  {planSelected.includes('pro')
                    ? t('start_trial')
                    : t('continue')}
                </Button>

                <Button
                  fullWidth
                  variant="text"
                  onClick={() => {
                    setPlansViewer(true);
                  }}
                >
                  {t('change_plan')}
                </Button>
              </Stack>
            </CardContent>
          </Card>
        ) : null}

        {activeStep === 3 ? (
          <Card
            mt={3}
            sx={{
              width: {
                xs: '90%',
                sm: '90%',
                md: '30rem',
                lg: '40rem',
                xl: '40rem',
              },
              p: 2,
            }}
          >
            <CardContent>
              <Card>
                <CardContent>
                  {prices.map((e: any, i: number) => {
                    return (
                      <Box key={i}>
                        <Typography variant="h6" gutterBottom>
                          {e.product.name}
                        </Typography>
                        <Typography variant="h4" component="div">
                          ${e.unit_amount / 100}
                          <span style={{ fontSize: '0.8rem' }}>/month</span>
                        </Typography>
                        <Typography color="textSecondary" gutterBottom>
                          Billed monthly
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Nullam eget felis nec velit laoreet bibendum vel
                          non libero.
                        </Typography>
                      </Box>
                    );
                  })}
                </CardContent>
              </Card>

              <Box
                sx={{
                  mt: 2,
                }}
              >
                <Button
                  fullWidth
                  onClick={() => {
                    //  subscribe(priceId);
                  }}
                >
                  Continue
                </Button>

                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => {
                    setActiveStep(activeStep - 1);
                  }}
                  sx={{ mt: 1 }}
                >
                  Continue with free trial
                </Button>
              </Box>
            </CardContent>
          </Card>
        ) : null}
      </Stack>

      <div className="mt-3 flex-center-2">
        <Typography
          color={'white'}
          sx={{
            opacity: 0.8,
          }}
        >
          {t('already_registered')}
        </Typography>{' '}
        <Button
          color="primary"
          sx={{
            ml: 2,
          }}
          onClick={() => {
            navigate('/admin/login');
          }}
        >
          <span>LOGIN</span>
        </Button>
      </div>
    </Box>
  );
};

import firebase from '../services/firebase';

export const uploadToStorage = (
  fileToUpload: Blob,
  route: string,
  loadingActivator?: Function,
  stackBarActivator?: Function
) => {
  return new Promise((resolve, reject) => {
    let file = fileToUpload;

    let storageRef = firebase.storage().ref();

    let id = firebase.firestore().collection('business').doc().id;

    var uploadTask = storageRef.child(`${route}/${id}`).put(file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if (loadingActivator) {
          loadingActivator(true);
        }

        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log('Upload is paused');
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log('Upload is running');
            break;
        }
      },
      (error) => {
        console.log(error);
        if (stackBarActivator) {
          stackBarActivator('Something went wrong!');
        }
        if (loadingActivator) {
          loadingActivator(false);
        }
        reject(error);
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref
          .getDownloadURL()
          .then((downloadURL) => {
            resolve(downloadURL);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
            if (loadingActivator) {
              loadingActivator(false);
            }
            if (stackBarActivator) {
              stackBarActivator('Something went wrong!');
            }
          });
      }
    );
  });
};

import MenuOptionButton from '../../components/MenuOptionButton/MenuOptionButton';
import './Home.css';
import { FaChevronRight } from 'react-icons/fa';
import { MdOutlineLocationOn } from 'react-icons/md';

import { FaWifi } from 'react-icons/fa';

import Dropdown from 'react-bootstrap/Dropdown';
import { useEffect, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import React from 'react';
import SocialNetworks from '../../components/SocialNetworks/SocialNetworks';
import ButtonGroupActions from '../../components/ButtonGroup/ButtonGroupActions';
import IconAndText from '../../components/IconAndText/IconAndText';
import { useNavigate } from 'react-router-dom';
import LanguagePicker, {
  LanguagesSpace,
} from '../../components/LanguagePicker/LanguagePicker';
import { AppBar, Box, CardActionArea, Stack, Typography } from '@mui/material';
import MenuAppBar from '../../components/MenuAppBar/MenuAppBar';
import { LanguageGeneric, Languages } from '../../models/languages';
import { chooseMultilanguageValue } from '../../services/multiLanguague';
import { useRecoilState } from 'recoil';
import { menuAtom } from '../../state/menuAtom';
import { MenuCreate } from '../../models/menu';
import { useTranslation } from 'react-i18next';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import BottomPanel from '../BottomPanel';

interface HomeProps {
  sections?: LanguageGeneric<string>[];
  categories?: LanguageGeneric<string>[];
  cover?: string;
}

const Home = (props: HomeProps) => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState('English');

  const [menuState, setMenuState] = useRecoilState(menuAtom);
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const toggleDrawer = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  function countryCode(lang: string) {
    if (lang == 'English') {
      return 'GB';
    } else if (lang == 'Italiano') {
      return 'IT';
    } else {
      return 'ES';
    }
  }

  useEffect(() => {
    i18n.changeLanguage(
      (
        menuState.businessData?.defaultLanguage ||
        (menuState.language as LanguagesSpace)
      ).toLowerCase()
    );
  }, []);

  return (
    <Box>
      <MenuAppBar
        position="absolute"
        businessName={menuState.businessData?.businessName!}
        canOrder={false}
        onChangeCanSelect={() => {}}
        language={
          menuState.businessData?.defaultLanguage ||
          (menuState.language as LanguagesSpace)
        }
        updateLanguage={(v) => {
          i18n.changeLanguage(v.toLowerCase());
          setMenuState((s) => ({ ...s, language: v }));
        }}
        languagesToRender={
          Object.keys(menuState.businessData?.languages as any).filter(
            (v: any) =>
              (menuState.businessData?.languages as Languages)[
                v as LanguagesSpace
              ].state === true
          ) as LanguagesSpace[]
        }
      />

      <Box>
        <Box
          sx={{
            backgroundImage: `url(${props.cover})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            position: 'relative',
            maxWidth: '500px',
            height: '220px',
            backgroundColor: 'lightslategrey',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              left: '50%',
              bottom: '0',
              width: '120px',
              height: '120px',
              backgroundColor: 'white',
              borderRadius: '50%',
              transform: 'translate(-50%,50%)',
              padding: '0.5rem',
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                backgroundImage: `url(${menuState.businessData?.businessImageUrl})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                borderRadius: '50%',
              }}
            ></Box>
          </Box>
        </Box>

        <Box className="home-container" mt={5}>
          <Box>
            <Typography
              variant="h4"
              textAlign={'center'}
              fontWeight={'bold'}
              mt={3}
            >
              {menuState.businessData?.businessName}
            </Typography>

            {menuState.businessData?.businessDescription !== '' ? (
              <Typography textAlign={'center'} mt={1} color={'InfoText'}>
                {menuState.businessData?.businessDescription}
              </Typography>
            ) : null}

            <Box px={2}>
              <CardActionArea
                sx={{
                  marginTop: '40px',

                  borderRadius: '5px',
                  display: 'flex',
                  paddingTop: '5px',
                  paddingBottom: '5px',
                }}
                onClick={() => {
                  toggleDrawer(true);
                }}
              >
                <InfoOutlinedIcon
                  fontSize="medium"
                  sx={{
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    fontSize: '30px',
                  }}
                />

                <Box width={'100%'}>
                  <Typography marginLeft={2} fontWeight={600}>
                    {t('info')}
                  </Typography>
                  <Typography marginLeft={2} fontSize={'14px'}>
                    {`${t('wifi')}, ${t('address')} ${t('and')} ${t('phone')}`}
                  </Typography>
                </Box>

                <ArrowForwardIosRoundedIcon
                  sx={{
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    fontSize: '20px',
                  }}
                />
              </CardActionArea>
            </Box>
            <Stack spacing={2} mt={2} px={2}>
              {menuState.menus
                ?.filter((v: MenuCreate) => v.active === true)
                .map((e: any, i: number) => {
                  const blocked = i + 1 > (menuState.planData?.menus as number);
                  return !blocked ? (
                    <MenuOptionButton
                      text={e.name}
                      icon={<FaChevronRight className="my-auto" />}
                      onPressed={() => {
                        navigate(e.id);
                      }}
                      key={i}
                    />
                  ) : null;
                })}
            </Stack>
          </Box>
        </Box>
      </Box>

      <BottomPanel toggleDrawer={toggleDrawer} open={open} />
    </Box>
  );
};

export default Home;

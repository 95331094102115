import { atom } from 'recoil';
import { DialogProps } from '../reusableComponents/Dialog/Dialog';
import React from 'react';

export const dialogAtom = atom<DialogProps>({
  key: 'dialogState',
  default: {
    setOpen: () => {},
    open: false,
    children: React.createElement('div'),
    title: '',
    onConfirm: () => {},
    sentiment: 'primary',
  },
});

import React, { ReactNode } from 'react';
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  MenuItemProps,
  Select,
  SelectProps,
  Switch as SwitchMUI,
  SwitchProps,
} from '@mui/material';
import { styled } from '@mui/material';

const CustomSwitchStyled = styled(SwitchMUI)(({ theme }) => {
  return {
    padding: 8,
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
    },
  };
});

type CustomSwitchProps = {
  labelText?: string;
};

export const Switch: React.FC<CustomSwitchProps & SwitchProps> = ({
  labelText,

  ...props
}) => {
  return (
    <FormControlLabel
      sx={{
        '.MuiSwitch-root': {},
      }}
      control={
        <CustomSwitchStyled
          disableRipple
          disableFocusRipple
          disableTouchRipple
          {...props}
        />
      }
      label={labelText}
    />
  );
};

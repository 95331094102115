import { LanguagesSpace } from '../components/LanguagePicker/LanguagePicker';
import { config } from '../config';
import { BusinessCreate } from '../models/business';
import { httpRequest } from './base';

export const registerApi = (data: BusinessCreate) => {
  return httpRequest({
    url: config.api_base + '/register',
    data: data,
    method: 'POST',
  });
};

export const generateUniqueBusinessUrlApi = (businessName: string) => {
  return new Promise((resolve, reject) => {
    httpRequest({
      data: {
        businessName,
      },
      method: 'POST',
      path: '/register/generate-unique-url',
    })
      .then((r) => {
        resolve(r.data);
      })
      .catch((e) => {
        console.log(e);
        reject(e);
      });
  });
};

export const completeBusinessApi = (data: {
  currency: string;
  defaultLanguage: LanguagesSpace;
  businessName: string;
}) => {
  return httpRequest({
    data: data,
    method: 'POST',
    path: '/register/complete-business',
  });
};

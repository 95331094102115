import React from 'react';
import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/material';

const CustomCardStyled = styled(Box)(({ theme }) => {
  return {
    backgroundColor: 'white',
    borderRadius: 'var(--border-radius)',
  };
});

export type CardProps = {};

export const Card: React.FC<CardProps & BoxProps> = ({ ...props }) => {
  return (
    <CustomCardStyled
      sx={{
        p: 4,
        border: '1px solid lightgrey',
      }}
      {...props}
    >
      {props.children}
    </CustomCardStyled>
  );
};

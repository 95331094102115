import React from 'react';
import { Button as ButtonMUI, TextField, ButtonProps } from '@mui/material';
import { styled } from '@mui/material';

const CustomButtonStyled = styled(ButtonMUI)(({ theme }: any) => {
  return {
    borderRadius: 'var(--border-radius)',
  };
});

type CustomButtonProps = {};

export const Button: React.FC<CustomButtonProps & ButtonProps> = ({
  ...props
}) => {
  return (
    <CustomButtonStyled
      disableElevation
      disableRipple
      variant="contained"
      {...props}
    >
      {props.children}
    </CustomButtonStyled>
  );
};

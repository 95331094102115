import React, { useEffect, useState } from 'react';
import { Modal } from '../../../../reusableComponents/Modal/Modal';
import { Box, Stack, Typography } from '@mui/material';
import { LanguageGeneric, Languages } from '../../../../models/languages';
import { TabsContainer } from '../../../../reusableComponents/Tabs/Tabs';
import { TextInput } from '../../../../reusableComponents/TextInput/TextInput';
import { Button } from '../../../../reusableComponents/Button/Button';
import { useRecoilState } from 'recoil';
import { loadingAtom } from '../../../../state/loading';
import firebase from '../../../../services/firebase';
import { adminAtom } from '../../../../state/adminAtom';
import { toast } from 'react-toastify';
import { BusinessData } from '../../../../models/business';
import { Select } from '../../../../reusableComponents/Select/Select';
import { Delete, Star } from '@mui/icons-material';
import { dialogAtom } from '../../../../state/dialogAtom';
import { MenuCreate } from '../../../../models/menu';
import {
  chooseMultilanguageValue,
  getMultilanguageValue,
  setMultilanguageValue,
} from '../../../../services/multiLanguague';
import { LanguagesSpace } from '../../../../components/LanguagePicker/LanguagePicker';
import { Switch } from '../../../../reusableComponents/Switch/Switch';
import { getTranslationApi } from '../../../../api/translation';
import { useTranslation } from 'react-i18next';

interface AddCategoryProps {
  close: Function;
  automaticSection?: string;
}

export function AddCategory({ close, automaticSection }: AddCategoryProps) {
  const { t } = useTranslation();
  const [adminState, setBusinessState] = useRecoilState(adminAtom);
  const [dialogState, setDialogState] = useRecoilState(dialogAtom);

  const [automaticTranslation, setAutomaticTranslation] = useState(false);

  const [languagesToTranslate, setLanguagesToTranslate] = useState({
    DE: {
      name: 'German',
      state: true,
    },
    ES: {
      name: 'Spanish',
      state: true,
    },
    'ES-V': {
      name: 'Valencian',
      state: true,
    },
    'ES-C': {
      name: 'Catalan',
      state: true,
    },
    IT: {
      name: 'Italian',
      state: true,
    },
    EN: {
      name: 'English',
      state: true,
    },
  } as Languages);

  const [indexes, setIndexes] = useState(
    Object.keys((adminState.businessData as BusinessData).languages)

      .filter(
        (key) =>
          adminState.businessData?.languages[key as keyof Languages].state ===
          true
      )
      .sort()
      .map((key, i) => ({ lang: key, i }))
  );

  const [categoryName, setCategoryName] = useState<any>(
    Object.keys((adminState.businessData as BusinessData).languages).reduce(
      (obj: any, key: any) => {
        obj[key] = '';

        return obj;
      },
      {}
    )
  );
  const [selectedSection, setSelectedSection] = useState('');
  const [isLoading, setIsLoading] = useRecoilState(loadingAtom);

  useEffect(() => {
    if (adminState.categoryData) {
      setCategoryName(adminState.categoryData);
      setSelectedSection(
        adminState.menuData?.sections?.filter(
          (v) => v.id === adminState.categoryData?.linkedSection
        )[0].id as string
      );
    }
  }, [adminState.categoryData]);

  useEffect(() => {
    if (automaticSection) {
      setSelectedSection(automaticSection);
    }
  }, [automaticSection]);

  const createCategory = () => {
    setIsLoading(true);

    let errorCheck = 0; //Verify that at least in one language there is text
    let messages: String[] = []; //Acum of messages
    let collectedNames = [];

    Object.keys(categoryName).forEach((key: string) => {
      if (String(categoryName[key]).trim() === '') {
      } else {
        collectedNames.push(categoryName[key]);
      }

      if (String(categoryName[key]).trim().length > 50) {
        errorCheck = 1;
        messages.push(`${t('text_too_large_on_language')} ${key}`);
      }
    });

    if (collectedNames.length === 0) {
      errorCheck = 1;
      messages.push(t('category_name_cant_be_empty'));
    }

    if (errorCheck === 0 && collectedNames.length > 0) {
      firebase
        .firestore()
        .collection('business')
        .doc(adminState.businessData?.id)
        .collection('menus')
        .doc(adminState.menuData?.id)
        .update({
          categories: firebase.firestore.FieldValue.arrayUnion({
            ...categoryName,
            id: firebase.firestore().collection('categories').doc().id,
            linkedSection: selectedSection,
          }),
        })
        .then(() => {
          setIsLoading(false);
          toast.success(t('category_created'));
          close();
        })
        .catch((e) => {
          console.log(e);
          toast.error(t('something_went_wrong'));
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);

      for (let mss of messages) {
        toast.info(mss);
      }
    }
  };

  const updateCategory = () => {
    setIsLoading(true);

    let errorCheck = 0; //Verify that at least in one language there is text
    let messages: String[] = []; //Acum of messages
    let collectedNames = [];

    Object.keys(categoryName).forEach((key: string) => {
      if (String(categoryName[key]).trim() === '') {
      } else {
        collectedNames.push(categoryName[key]);
      }

      if (String(categoryName[key]).trim().length > 50) {
        errorCheck = 1;
        messages.push(`${t('text_too_large_on_language')} ${key}`);
      }
    });

    if (collectedNames.length === 0) {
      errorCheck = 1;
      messages.push(t('category_name_cant_be_empty'));
    }

    if (errorCheck === 0 && collectedNames.length > 0) {
      const categories = [
        ...(adminState.menuData?.categories as (LanguageGeneric<string> & {
          id: string;
        })[]),
      ];

      for (let i = 0; i < categories.length; i++) {
        if (categories[i].id === adminState.categoryData?.id) {
          if (categoryName['linkedSection'] !== undefined) {
            delete categoryName['linkedSection'];
          }

          categories[i] = {
            ...categories[i],
            ...categoryName,
            linkedSection: selectedSection,
          };
        }
      }

      firebase
        .firestore()
        .collection('business')
        .doc(adminState.businessData?.id)
        .collection('menus')
        .doc(adminState.menuData?.id)
        .update({
          categories: categories,
        })
        .then(() => {
          setIsLoading(false);
          toast.success(t('category_updated'));
          close();
        })
        .catch((e) => {
          console.log(e);
          toast.error(t('something_went_wrong'));
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);

      for (let mss of messages) {
        toast.info(mss);
      }
    }
  };

  const deleteCategory = () => {
    setDialogState((state) => ({
      ...state,
      open: true,
      setOpen: (o: boolean) => {
        setDialogState((s) => ({
          ...s,
          open: o,
        }));
      },
      onConfirm: () => {
        setIsLoading(true);

        firebase
          .firestore()
          .collection('business')
          .doc(adminState.businessData?.id)
          .collection('menus')
          .doc(adminState.menuData?.id)
          .collection('products')
          .where('category', '==', adminState.categoryData?.id)
          .get()
          .then((snap) => {
            if (snap.empty) {
              const categories: (LanguageGeneric<string> & { id: string })[] = [
                ...(adminState.menuData
                  ?.categories as (LanguageGeneric<string> & {
                  id: string;
                })[]),
              ];

              for (let i = 0; i < categories.length; i++) {
                if (categories[i].id === adminState.categoryData?.id) {
                  categories.splice(i, 1);
                }
              }

              firebase
                .firestore()
                .collection('business')
                .doc(adminState.businessData?.id)
                .collection('menus')
                .doc(adminState.menuData?.id)
                .update({
                  categories: categories,
                })
                .then(() => {
                  setIsLoading(false);
                  toast.success(t('category_deleted'));
                  close();
                })
                .catch((e) => {
                  console.log(e);
                  toast.error(t('something_went_wrong'));
                  setIsLoading(false);
                });
            } else {
              setIsLoading(false);
              toast.error(t('cant_delete_because_some_products_use_it'));
            }
          })
          .catch((e) => {
            console.log(e);
            toast.error(t('something_went_wrong'));
            setIsLoading(false);
          });
      },
      children: <Box>{t('sure_you_want_to_delete_this_category')}</Box>,
      sentiment: 'error',
      title: 'Delete Category',
    }));
  };

  const getAutomaticTranslationBasedOnIndex = (i: number) => {
    if (
      adminState.planData?.translation &&
      languagesToTranslate[indexes[i].lang as keyof Languages].state === false
    ) {
      if (indexes[i].lang !== adminState.businessData?.defaultLanguage) {
        if (automaticTranslation) {
          let categoryNameVal: string = getMultilanguageValue(
            categoryName,
            adminState.businessData?.defaultLanguage as keyof Languages
          );

          const valuesToTranslate = [];

          if (categoryNameVal.trim() !== '') {
            valuesToTranslate.push(categoryNameVal);
          }

          if (valuesToTranslate.length > 0) {
            getTranslationApi(
              valuesToTranslate,
              adminState.languagesISO[indexes[i].lang as keyof Languages]
            )
              .then((r) => {
                if (r[0]) {
                  setMultilanguageValue(
                    r[0],
                    setCategoryName,
                    indexes[i].lang as keyof Languages
                  );
                }

                let langObj: Languages = JSON.parse(
                  JSON.stringify(languagesToTranslate)
                );

                langObj[indexes[i].lang as keyof Languages].state = true;
                setLanguagesToTranslate(langObj);
              })
              .catch((e) => {
                console.log(e);
              });
          }
        }
      }
    }
  };

  return (
    <Modal
      open={true}
      onClose={() => {
        if (close) {
          close();
        }
      }}
    >
      <>
        <Typography variant="h4" textAlign={'center'}>
          {adminState.categoryData
            ? `${t('update_category')}`
            : `${t('add_category')}`}
        </Typography>

        <Box mt={2}>
          <Select
            labelText={t('choose_section')}
            placeholder={t('select_section')}
            options={(adminState.menuData as MenuCreate)?.sections?.map(
              (e, i) => ({
                text: chooseMultilanguageValue(
                  e,
                  adminState.language as LanguagesSpace,
                  adminState.language as LanguagesSpace
                ),
                value: e.id,
                key: i,
              })
            )}
            value={selectedSection}
            onChange={(el) => {
              setSelectedSection(el.target.value as any);
            }}
          />
        </Box>

        {selectedSection !== '' ? (
          <TabsContainer
            muiProps={{
              sx: {
                mt: 2,
              },
            }}
            listenIndexChange={getAutomaticTranslationBasedOnIndex}
            defaultValue={
              indexes.filter(
                (v) => v.lang === adminState.businessData?.defaultLanguage
              )[0].i
            }
            tabs={Object.keys(
              (adminState.businessData as BusinessData).languages
            )
              .filter(
                (key) =>
                  adminState.businessData?.languages[key as keyof Languages]
                    .state === true
              )
              .sort()
              .map((key: string, i: number) => ({
                label:
                  adminState.businessData?.languages[key as keyof Languages]
                    .name,
                key: 1,
                icon:
                  key === adminState.businessData?.defaultLanguage ? (
                    <Star />
                  ) : undefined,
              }))}
          >
            {Object.keys((adminState.businessData as BusinessData).languages)
              .filter(
                (key) =>
                  adminState.businessData?.languages[key as keyof Languages]
                    .state === true
              )
              .sort()
              .map((key: string, i: number) => {
                return (
                  <TabsContainer.TabPanel key={i}>
                    {adminState.businessData?.defaultLanguage ? (
                      key !== adminState.businessData?.defaultLanguage ? (
                        <Stack direction={'row'} justifyContent={'end'}>
                          <Switch
                            labelText={t('automatic_translation')}
                            checked={automaticTranslation}
                            onChange={(e) => {
                              setAutomaticTranslation(e.target.checked);
                            }}
                          />
                        </Stack>
                      ) : null
                    ) : null}

                    <TextInput
                      fullWidth
                      label={t('category_name')}
                      inputProps={{
                        maxLength: 50,
                      }}
                      value={categoryName[key]}
                      onChange={(el) => {
                        const value = el.currentTarget.value;
                        setCategoryName((state: any) => ({
                          ...state,
                          [key]: value,
                        }));

                        let langObj: Languages = JSON.parse(
                          JSON.stringify(languagesToTranslate)
                        );

                        Object.keys(langObj).forEach((key: any) => {
                          langObj[key as keyof Languages].state = false;
                        });

                        setLanguagesToTranslate(langObj);
                      }}
                    />
                  </TabsContainer.TabPanel>
                );
              })}
          </TabsContainer>
        ) : null}

        {adminState.categoryData ? (
          <Stack spacing={1}>
            <Button
              fullWidth
              onClick={() => {
                updateCategory();
              }}
              sx={{
                mt: 2,
              }}
            >
              Update
            </Button>

            <Stack direction={'row'} justifyContent={'center'}>
              <Button
                color="error"
                onClick={() => {
                  deleteCategory();
                }}
              >
                <Delete />
              </Button>
            </Stack>
          </Stack>
        ) : (
          <Button
            fullWidth
            onClick={() => {
              createCategory();
            }}
            sx={{
              mt: 2,
            }}
          >
            {t('create')}
          </Button>
        )}
      </>
    </Modal>
  );
}

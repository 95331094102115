import { Box, Paper, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode';
import { useRecoilState } from 'recoil';
import { adminAtom } from '../../../../state/adminAtom';
import { Button } from '../../../../reusableComponents/Button/Button';
import { Download } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export const QrCode = () => {
  const { t } = useTranslation();
  const [qrImage, setQrImage] = useState('');
  const [adminState, setAdminState] = useRecoilState(adminAtom);

  const generateQR = async (text: string): Promise<string> => {
    try {
      const url = await QRCode.toDataURL(text, { scale: 10 });
      return url;
    } catch (err) {
      console.error(err);
      return '';
    }
  };

  useEffect(() => {
    generateQR(
      window.location.host + '/' + adminState.businessData?.businessUrl
    ).then((url) => {
      setQrImage(url);
    });
  }, []);

  const downloadImage = (imageUrl: string, filename: string): void => {
    // Create an anchor element
    const anchor = document.createElement('a');
    anchor.href = imageUrl;
    anchor.download = filename || 'image'; // Provide a default filename if none is specified

    // Append anchor to the body temporarily
    document.body.appendChild(anchor);

    // Trigger the download
    anchor.click();

    // Remove the anchor from the body
    document.body.removeChild(anchor);
  };
  return (
    <Box
      sx={{
        pt: '64px',
      }}
    >
      <Typography textAlign={'center'} variant="h4">
        {t('qr_code')}
      </Typography>

      <Stack spacing={2} mt={3}>
        <Stack direction={'row'} justifyContent={'center'}>
          <Paper
            sx={{
              p: 5,
              borderRadius: '50%',
              backgroundColor: 'white',
              width: '300px',
              height: '300px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img src={qrImage} width={'90%'} />
          </Paper>
        </Stack>

        <Stack direction={'row'} justifyContent={'center'}>
          <Button
            endIcon={<Download />}
            size="large"
            onClick={() => {
              downloadImage(qrImage, 'qrcode');
            }}
          >
            {t('download')}
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

import * as React from 'react';
import DraggableListItem from './DraggableListItem';
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder,
} from '@hello-pangea/dnd';
import { Item } from './typings';
import { Stack } from '@mui/material';

export type DraggableListProps = {
  items: any[];
  onDragEnd: OnDragEndResponder;
  id: string;
};

const DraggableList = React.memo(
  ({ items, onDragEnd, id }: DraggableListProps) => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={id}>
          {(provided) => (
            <Stack
              ref={provided.innerRef}
              {...provided.droppableProps}
              spacing={2}
            >
              {items.map((item, index) => (
                <DraggableListItem id={item.id} index={index} key={item.id}>
                  {item.element}
                </DraggableListItem>
              ))}
              {provided.placeholder}
            </Stack>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
);

export default DraggableList;

import React, { ReactNode } from 'react';
import {
  IconButton,
  TextField,
  TextFieldProps,
  Tooltip,
  TooltipProps,
  Typography,
  tooltipClasses,
} from '@mui/material';
import { styled } from '@mui/material';
import { Info } from '@mui/icons-material';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    // border: '1px solid #dadde9',
    boxShadow: '0 8px 8px 0 rgba(0,0,0,0.2)',
  },
}));

const CustomTextField = styled(TextField)(
  ({ theme, error, label, multiline }) => {
    let colorBorder = error
      ? theme.palette.error.main
      : theme.palette.primary.main;
    return {
      '& .MuiInputBase-root': {
        marginTop: label ? '0.4rem' : '0',
        borderRadius: 'var(--border-radius)',
        //  backgroundColor: theme.palette.background.default,
        border: `1.5px solid ${theme.palette.grey[300]}`,
        transition: '0.3s',
        paddingRight: multiline ? '0.5rem' : undefined,
        paddingLeft: multiline ? '0.5rem' : undefined,

        textarea: {
          padding: 0,
          paddingLeft: '1rem',
          paddingRight: '1rem',
        },

        '&:has(.MuiInputBase-input:focus)': {
          border: `1.5px solid ${colorBorder}`,
        },

        '&:hover': {
          // Only apply hover styles if the input is not disabled
        },

        '& fieldset': {
          border: 'none',
        },
      },

      ':-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 1000px white inset',
      },

      '& .MuiFormLabel-root': {
        display: 'block',
        position: 'relative',
        transform: 'translate(0,0)',
        color: theme.palette.text.primary,
        fontSize: '0.8rem',
      },

      '& .MuiInputBase-input': {
        paddingLeft: '1.5rem',
        paddingRight: '1.5rem',
        borderRadius: multiline ? 0 : 'var(--border-radius)',

        ':-webkit-autofill': {
          backgroundColor: theme.palette.primary.main,
        },
      },

      '&:has(> input:-webkit-autofill)': {
        backgroundColor: 'red',
      },

      '& .MuiFormHelperText-root': {
        marginLeft: '0',
      },
    };
  }
);

type CustomTextInputProps = {
  labelText?: string;
  tooltip?: ReactNode | string;
};

export const TextInput: React.FC<CustomTextInputProps & TextFieldProps> = ({
  labelText,
  tooltip,
  ...props
}) => {
  return (
    <CustomTextField
      size="small"
      variant="outlined"
      {...props}
      label={
        <>
          {props.label}
          {tooltip ? (
            <HtmlTooltip title={tooltip}>
              <IconButton
                sx={{
                  p: 0,
                  ml: 1,
                }}
                aria-label="info"
                size="small"
              >
                <Info
                  sx={{
                    width: 20,
                    height: 20,
                  }}
                />
              </IconButton>
            </HtmlTooltip>
          ) : null}
        </>
      }
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

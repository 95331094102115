import React, { useEffect, useState } from 'react';
import logoKing2 from '../../../res/logoKing2.png';

import ball1 from '../../../res/ball1.png';
import ball2 from '../../../res/ball2.png';
import ball3 from '../../../res/ball3.png';

import firebase from '../../../services/firebase';
import { Button } from '../../../reusableComponents/Button/Button';
import { TextInput } from '../../../reusableComponents/TextInput/TextInput';
import { Box, CardContent, Stack, Typography } from '@mui/material';
import { Card } from '../../../reusableComponents/Card/Card';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { loadingAtom } from '../../../state/loading';
import { toast } from 'react-toastify';
import restaurantBackground from '../../../res/restaurant-background.png';
import restaurantBackground2 from '../../../res/puglia-background.jpg';
import piattoqrLogo from '../../../res/PiattoQR-LOGO-WHITE.png';

import './Auth.scss';
import GoogleButton from 'react-google-button';
import { testGoogleAuthApi } from '../../../api/test';
import { useTranslation } from 'react-i18next';

const provider = new firebase.auth.GoogleAuthProvider();

interface AuthProps {}

export const Auth = (props: AuthProps) => {
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [isLoading, setIsLoading] = useRecoilState(loadingAtom);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const check = (auth?: any) => {
    setTimeout(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const email = urlParams.get('email');

      const pass = urlParams.get('pass');

      if (email !== null && pass !== null) {
        if (auth === true) {
          firebase
            .auth()
            .signOut()
            .then(() => {
              setIsLoading(true);
              firebase
                .auth()
                .signInWithEmailAndPassword(email, pass)
                .then(() => {
                  setIsLoading(false);
                })
                .catch((e) => {
                  console.log(e);
                  setIsLoading(false);
                });
            })
            .catch((e) => {
              console.log(e);
              toast.error(t('something_went_wrong'));
            });
        } else {
          firebase.auth().signInWithEmailAndPassword(email, pass);
        }
      } else {
        // this.props.history.push("/")
      }
    }, 2000);
  };

  useEffect(() => {
    check();
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column',
        position: 'relative',
        overflow: 'hidden',
        minHeight: '100vh',
        p: 2,
        backgroundImage: `url(${restaurantBackground2})`, // Ensure restaurantBackground contains a valid image URL
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: 'rgba(0, 0,0, 0.6)', // Example: A semi-transparent black overlay
        backgroundBlendMode: 'multiply',
      }}
    >
      <div className="balls">
        <img src={ball1} />
        <img src={ball3} />
        <img src={ball2} />
        <img src={ball2} />
        <img src={ball1} />
        <img src={ball3} />
        <img src={ball2} />
        <img src={ball2} />
        <img src={ball1} />
      </div>

      <Box display={'flex'} justifyContent={'center'} mb={5}>
        <img src={piattoqrLogo} width={'250px'} />
      </Box>

      <Typography textAlign={'center'} variant="h4" color={'white'}>
        {t('login')}
      </Typography>
      <Card
        mt={3}
        textAlign={'left'}
        sx={{
          width: {
            xs: '90%',
            sm: '90%',
            md: '30rem',
            lg: '40rem',
            xl: '40rem',
          },
          p: 2,
        }}
      >
        <CardContent>
          <Stack spacing={2}>
            <GoogleButton
              onClick={() => {
                // setIsLoading(true);
                firebase
                  .auth()
                  .signInWithPopup(provider)
                  .then((result) => {
                    /** @type {firebase.auth.OAuthCredential} */
                    const credential: firebase.auth.OAuthCredential | null =
                      result.credential;

                    // This gives you a Google Access Token. You can use it to access the Google API.
                    const token = credential?.accessToken;
                    // The signed-in user info.

                    const user = result.user;
                    // IdP data available in result.additionalUserInfo.profile.
                    // ...
                    //  setIsLoading(false);
                    testGoogleAuthApi()
                      .then(() => {
                        console.log('Authenticated');
                        //setIsLoading(false);
                      })
                      .catch((e) => {
                        console.log(e);
                        //setIsLoading(false);
                      });
                  })
                  .catch((error) => {
                    // Handle Errors here.
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    // The email of the user's account used.
                    var email = error.email;
                    // The firebase.auth.AuthCredential type that was used.
                    var credential = error.credential;
                    //setIsLoading(false);
                    // ...
                  })
                  .finally(() => {
                    //setIsLoading(false);
                  });
              }}
              type="light"
              style={{
                boxShadow: 'none',
                border: '1.5px solid #e0e0e0',
                overflow: 'hidden',
                borderRadius: 'var(--border-radius)',
                width: '100%',
                textAlign: 'left',
                display: 'flex',
                justifyContent: 'center',
                gap: 4,
              }}
            />

            <TextInput
              label={t('email')}
              placeholder={t('write_your_email')}
              disabled={isLoading}
              value={email}
              onChange={(e) => {
                setEmail(e.currentTarget.value);
              }}
            />

            <TextInput
              label={t('password')}
              disabled={isLoading}
              value={pass}
              type="password"
              placeholder={t('write_your_password')}
              onChange={(e) => {
                setPass(e.currentTarget.value);
              }}
            />

            {isLoading === false ? (
              <Box>
                <Button
                  size="large"
                  sx={{
                    mt: 2,
                  }}
                  fullWidth
                  onClick={() => {
                    setIsLoading(true);

                    firebase
                      .auth()
                      .signInWithEmailAndPassword(email, pass)
                      .then((u) => {
                        setIsLoading(false);
                      })
                      .catch((e) => {
                        console.log(e);
                        setIsLoading(false);

                        if (e.code === 'auth/user-not-found') {
                          toast.error('The user is not registered');
                        } else if (e.code === 'auth/invalid-credential') {
                          toast.error('Invalid email or password');
                        }
                      });
                  }}
                >
                  {t('login_uppercase')}
                </Button>
              </Box>
            ) : (
              <div
                className="spinner-border"
                style={{
                  color: 'white',
                }}
              ></div>
            )}
          </Stack>
        </CardContent>
      </Card>

      <div className="mt-3 flex-center-2">
        <Typography
          color={'white'}
          sx={{
            opacity: 0.8,
          }}
        >
          {t('your_business_is_not_affiliated')}
        </Typography>{' '}
        <Button
          color="primary"
          sx={{
            ml: 2,
          }}
          onClick={() => {
            navigate('/register');
          }}
        >
          {t('join')}
        </Button>
      </div>
    </Box>
  );
};

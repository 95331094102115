import React, { useState } from 'react';
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Stack,
  Switch,
  Toolbar,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import {
  Help,
  Menu,
  Notifications,
  PowerSettingsNew,
  Save,
  Settings,
  Support,
  Visibility,
} from '@mui/icons-material';
import LanguagePicker, {
  LanguagesSpace,
} from '../../../components/LanguagePicker/LanguagePicker';
import { TextInput } from '../../../reusableComponents/TextInput/TextInput';
import { Button } from '../../../reusableComponents/Button/Button';
import { useRecoilState } from 'recoil';
import { adminAtom } from '../../../state/adminAtom';
import { useTranslation } from 'react-i18next';

interface NavbarProps {
  logout?: Function;
  openSettings?: Function;
  openNotifications?: Function;
  openPreview?: Function;
  save?: Function;
  changesToSave?: boolean;
  drawerWidth: number;
  drawerToggle: Function;
  upgrade?: Function;
}

export const Navbar = ({
  logout,
  openSettings,
  openNotifications,
  openPreview,
  save,
  changesToSave,
  drawerWidth,
  drawerToggle,
  upgrade,
}: NavbarProps) => {
  const navigate = useNavigate();

  const [adminState, setAdminState] = useRecoilState(adminAtom);

  const { t } = useTranslation();

  return (
    <AppBar
      position="fixed"
      variant="outlined"
      color="transparent"
      sx={{
        boxSizing: 'border-box',
        backgroundColor: 'white',
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
      }}
    >
      <Container maxWidth="xl">
        <Toolbar>
          <Box sx={{ flexGrow: 1, display: { sm: 'block' } }}>
            <Stack direction={'row'} spacing={1}>
              <Box sx={{ display: { sm: 'none' } }}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={() => {
                    if (drawerToggle) {
                      drawerToggle();
                    }
                  }}
                >
                  <Menu />
                </IconButton>
              </Box>
            </Stack>
          </Box>

          <Stack direction={'row'} spacing={1}>
            {adminState.planData?.key !== 'pro' &&
            adminState.planData?.key !== 'pro' ? (
              <Box>
                <Button
                  onClick={() => {
                    if (upgrade) {
                      upgrade();
                    }
                  }}
                  variant="contained"
                  color="secondary"
                >
                  {t('upgrade')}
                </Button>
              </Box>
            ) : null}
            {changesToSave ? (
              <Box>
                <Button
                  endIcon={<Save />}
                  onClick={() => {
                    if (save) {
                      save();
                    }
                  }}
                >
                  {t('save')}
                </Button>
              </Box>
            ) : null}
            <Box>
              <Button
                endIcon={<Visibility />}
                onClick={() => {
                  if (openPreview) {
                    openPreview();
                  }
                }}
                variant="outlined"
              >
                {t('preview')}
              </Button>
            </Box>
            {true !== true ? (
              <Box>
                <IconButton
                  onClick={() => {
                    if (openSettings) {
                      openSettings();
                    }
                  }}
                >
                  <Settings />
                </IconButton>
              </Box>
            ) : null}

            {
              <Box>
                <IconButton
                  disableRipple
                  href="mailto:alejandro.hernandez@soflopsoftware.com"
                >
                  <Support />
                </IconButton>
              </Box>
            }

            <LanguagePicker
              languageValue={adminState.language as LanguagesSpace}
              languagesToRender={['ES', 'ES-V', 'ES-C', 'EN', 'IT', 'DE']}
              updateLanguage={(v) =>
                setAdminState((s) => ({ ...s, language: v }))
              }
            />
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const Address = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        pt: '64px',
      }}
    >
      <Typography textAlign={'center'} variant="h4">
        {t('address')}
      </Typography>
    </Box>
  );
};

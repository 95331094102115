import * as Yup from 'yup';

export const fieldValidator = (
  validator: Yup.Schema,
  setter: React.Dispatch<{ value: string; error: string; valid: boolean }>,
  value: string,
  cbSuccess: Function | undefined = undefined,
  cbFailure: Function | undefined = undefined
) => {
  validator
    .validate(value)
    .then(() => {
      setter({
        value,
        error: '',
        valid: true,
      });

      if (cbSuccess) {
        cbSuccess(value);
      }
    })
    .catch((e: any) => {
      if (e.errors) {
        if (e.errors.length > 0) {
          setter({
            value,
            error: e.errors[0],
            valid: false,
          });
        }
      }

      if (cbFailure) {
        cbFailure(value);
      }
    });
};

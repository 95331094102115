import { Box, Stack, Typography } from '@mui/material';
import { DE, ES, IT, US } from 'country-flag-icons/react/3x2';

import valencianFlag from '../../../../res/valencian-flag.png';
import barcelonaFlag from '../../../../res/barcelona-flag.png';
import React, { useState } from 'react';
import { Switch } from '../../../../reusableComponents/Switch/Switch';
import { useRecoilState } from 'recoil';
import { adminAtom } from '../../../../state/adminAtom';
import { Card } from '../../../../reusableComponents/Card/Card';
import { Select } from '../../../../reusableComponents/Select/Select';
import { BusinessData } from '../../../../models/business';
import { LanguagesSpace } from '../../../../components/LanguagePicker/LanguagePicker';
import { Languages as LanguagesModel } from '../../../../models/languages';
import { useTranslation } from 'react-i18next';

export const Languages = () => {
  const { t } = useTranslation();
  const [adminState, setAdminState] = useRecoilState(adminAtom);

  const [languages, setLanguages] = useState([
    {
      name: 'Spanish',
      code: 'ES',
    },
    {
      name: 'Valencian',
      code: 'ES-V',
    },
    {
      name: 'Catalan',
      code: 'ES-C',
    },
    {
      name: 'English',
      code: 'EN',
    },
    {
      name: 'Italian',
      code: 'IT',
    },
    {
      name: 'German',
      code: 'DE',
    },
  ]);

  const styleLanguageBox = {
    with: '150px',
    minWidth: '150px',
    p: 2,
  };

  return adminState.businessData ? (
    <Box
      sx={{
        pt: '64px',
      }}
    >
      <Typography textAlign={'center'} variant="h4">
        {t('languages')}
      </Typography>

      <Card mt={4}>
        <Typography variant="h5" fontWeight={'bold'}>
          {t('active_languages')}
        </Typography>

        <Stack direction={'row'} flexWrap={'wrap'} mt={2}>
          <Stack direction={'column'} spacing={1} sx={styleLanguageBox}>
            <ES
              width={'50px'}
              style={{
                borderRadius: '.5rem',
                alignSelf: 'center',
              }}
            />
            <Switch
              labelText={t('spanish')}
              checked={adminState.businessData?.languages.ES.state}
              onChange={(el) => {
                const multilanguage = adminState.planData?.multilanguage;

                const languagesObj: LanguagesModel = JSON.parse(
                  JSON.stringify(adminState.businessData?.languages)
                );
                Object.keys(languagesObj).forEach((key: any, index: number) => {
                  languagesObj[key as keyof LanguagesModel].state = false;
                });
                setAdminState((state) => ({
                  ...state,
                  businessData: {
                    ...state.businessData,
                    defaultLanguage: !multilanguage
                      ? 'ES'
                      : state.businessData?.defaultLanguage,
                    languages: {
                      ...(!multilanguage
                        ? languagesObj
                        : state.businessData?.languages),
                      ES: {
                        ...state.languages.ES,
                        state: el.target.checked,
                      },
                    } as any,
                  } as any,
                  toUpdate: true,
                }));
              }}
            />
          </Stack>

          <Stack direction={'column'} spacing={1} sx={styleLanguageBox}>
            <Box
              sx={{
                width: '50px',
                height: '33.33px',
                backgroundImage: `url(${valencianFlag})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                borderRadius: '.5rem',
                alignSelf: 'center',
              }}
            ></Box>
            <Switch
              labelText={t('valencian')}
              checked={adminState.businessData?.languages['ES-V'].state}
              onChange={(el) => {
                const multilanguage = adminState.planData?.multilanguage;

                const languagesObj: LanguagesModel = JSON.parse(
                  JSON.stringify(adminState.businessData?.languages)
                );
                Object.keys(languagesObj).forEach((key: any, index: number) => {
                  languagesObj[key as keyof LanguagesModel].state = false;
                });
                setAdminState((state) => ({
                  ...state,
                  businessData: {
                    ...state.businessData,
                    defaultLanguage: !multilanguage
                      ? 'ES-V'
                      : state.businessData?.defaultLanguage,
                    languages: {
                      ...(!multilanguage
                        ? languagesObj
                        : state.businessData?.languages),
                      'ES-V': {
                        ...state.languages['ES-V'],
                        state: el.target.checked,
                      },
                    } as any,
                  } as any,
                  toUpdate: true,
                }));
              }}
            />
          </Stack>

          <Stack direction={'column'} spacing={1} sx={styleLanguageBox}>
            <Box
              sx={{
                width: '50px',
                height: '33.33px',
                backgroundImage: `url(${barcelonaFlag})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                borderRadius: '.5rem',
                alignSelf: 'center',
              }}
            ></Box>
            <Switch
              labelText={t('catalan')}
              checked={adminState.businessData?.languages['ES-C'].state}
              onChange={(el) => {
                const multilanguage = adminState.planData?.multilanguage;

                const languagesObj: LanguagesModel = JSON.parse(
                  JSON.stringify(adminState.businessData?.languages)
                );
                Object.keys(languagesObj).forEach((key: any, index: number) => {
                  languagesObj[key as keyof LanguagesModel].state = false;
                });
                setAdminState((state) => ({
                  ...state,
                  businessData: {
                    ...state.businessData,
                    defaultLanguage: !multilanguage
                      ? 'ES-C'
                      : state.businessData?.defaultLanguage,
                    languages: {
                      ...(!multilanguage
                        ? languagesObj
                        : state.businessData?.languages),
                      'ES-C': {
                        ...state.languages['ES-C'],
                        state: el.target.checked,
                      },
                    } as any,
                  } as any,
                  toUpdate: true,
                }));
              }}
            />
          </Stack>

          <Stack direction={'column'} spacing={1} sx={styleLanguageBox}>
            <US
              width={'50px'}
              style={{
                borderRadius: '.5rem',
                alignSelf: 'center',
              }}
            />
            <Switch
              labelText={t('english')}
              checked={adminState.businessData?.languages.EN.state}
              onChange={(el) => {
                const multilanguage = adminState.planData?.multilanguage;

                const languagesObj: LanguagesModel = JSON.parse(
                  JSON.stringify(adminState.businessData?.languages)
                );
                Object.keys(languagesObj).forEach((key: any, index: number) => {
                  languagesObj[key as keyof LanguagesModel].state = false;
                });
                setAdminState((state) => ({
                  ...state,
                  businessData: {
                    ...state.businessData,
                    defaultLanguage: !multilanguage
                      ? 'EN'
                      : state.businessData?.defaultLanguage,
                    languages: {
                      ...(!multilanguage
                        ? languagesObj
                        : state.businessData?.languages),
                      EN: {
                        ...state.languages.EN,
                        state: el.target.checked,
                      },
                    } as any,
                  } as any,
                  toUpdate: true,
                }));
              }}
            />
          </Stack>

          <Stack direction={'column'} spacing={1} sx={styleLanguageBox}>
            <IT
              width={'50px'}
              style={{
                borderRadius: '.5rem',
                alignSelf: 'center',
              }}
            />
            <Switch
              labelText={t('italian')}
              checked={adminState.businessData?.languages.IT.state}
              onChange={(el) => {
                const multilanguage = adminState.planData?.multilanguage;

                const languagesObj: LanguagesModel = JSON.parse(
                  JSON.stringify(adminState.businessData?.languages)
                );
                Object.keys(languagesObj).forEach((key: any, index: number) => {
                  languagesObj[key as keyof LanguagesModel].state = false;
                });
                setAdminState((state) => ({
                  ...state,
                  businessData: {
                    ...state.businessData,
                    defaultLanguage: !multilanguage
                      ? 'IT'
                      : state.businessData?.defaultLanguage,
                    languages: {
                      ...(!multilanguage
                        ? languagesObj
                        : state.businessData?.languages),
                      IT: {
                        ...state.languages.IT,
                        state: el.target.checked,
                      },
                    } as any,
                  } as any,
                  toUpdate: true,
                }));
              }}
            />
          </Stack>

          <Stack direction={'column'} spacing={1} sx={styleLanguageBox}>
            <DE
              width={'50px'}
              style={{
                borderRadius: '.5rem',
                alignSelf: 'center',
              }}
            />
            <Switch
              labelText={t('german')}
              checked={adminState.businessData?.languages.DE.state}
              onChange={(el) => {
                const multilanguage = adminState.planData?.multilanguage;

                const languagesObj: LanguagesModel = JSON.parse(
                  JSON.stringify(adminState.businessData?.languages)
                );
                Object.keys(languagesObj).forEach((key: any, index: number) => {
                  languagesObj[key as keyof LanguagesModel].state = false;
                });
                setAdminState((state) => ({
                  ...state,
                  businessData: {
                    ...state.businessData,
                    defaultLanguage: !multilanguage
                      ? 'DE'
                      : state.businessData?.defaultLanguage,
                    languages: {
                      ...(!multilanguage
                        ? languagesObj
                        : state.businessData?.languages),
                      DE: {
                        ...state.languages.DE,
                        state: el.target.checked,
                      },
                    } as any,
                  } as any,
                  toUpdate: true,
                }));
              }}
            />
          </Stack>
        </Stack>
      </Card>

      <Card mt={3}>
        <Typography variant="h5" fontWeight={'bold'}>
          Default language
        </Typography>

        <Stack mt={2}>
          <Select
            labelText={t('default_language')}
            placeholder={t('select_the_default_language')}
            value={adminState.businessData?.defaultLanguage}
            options={languages
              ?.filter((v) =>
                Object.keys(adminState.businessData?.languages as object)
                  .filter(
                    (value: string) =>
                      adminState.businessData?.languages[
                        value as keyof LanguagesModel
                      ].state === true
                  )
                  .includes(v.code)
              )
              .map((e, i) => ({
                value: e.code,
                text: e.name,
                key: i,
              }))}
            onChange={(el) => {
              setAdminState((state) => ({
                ...state,
                toUpdate: true,
                businessData: {
                  ...state.businessData,
                  defaultLanguage: el.target.value,
                } as BusinessData,
              }));
            }}
          />
        </Stack>
      </Card>
    </Box>
  ) : null;
};

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

let language = navigator.language;
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    lng: language,
    resources: {
      en: {
        translations: require('./locales/en/translations-en.json'),
      },
      es: {
        translations: require('./locales/es/translations-es.json'),
      },
      it: {
        translations: require('./locales/it/translations-it.json'),
      },
    },
    ns: ['translations'],
    defaultNS: 'translations',
  });

i18n.languages = ['en', 'es', 'it'];

export const changeLanguage = i18n.changeLanguage;

export default i18n;

import React, { ReactNode } from 'react';
import { Button } from '../../reusableComponents/Button/Button';
import { LanguageGeneric } from '../../models/languages';
import { chooseMultilanguageValue } from '../../services/multiLanguague';
import { menuAtom } from '../../state/menuAtom';
import { useRecoilState } from 'recoil';
import { LanguagesSpace } from '../LanguagePicker/LanguagePicker';

type Prop = {
  text: LanguageGeneric<string>;
  icon: ReactNode;
  onPressed: () => void;
};

function MenuOptionButton({ text, icon, onPressed }: Prop) {
  const [menuState, setMenuState] = useRecoilState(menuAtom);
  return (
    <Button
      fullWidth
      onClick={onPressed}
      variant="text"
      sx={{
        height: '50px',
        backgroundColor: '#ececec',
        fontWeight: 'bold',
        textTransform: 'capitalize',
        fontSize: '16px',
        display: 'flext',
        justifyContent: 'space-between',
        verticalAlign: 'middle',
        marginLeft: '50px',
        marginRight: '50px',
        paddingLeft: '20px',
        paddingRight: '20px',
        border: '1px solid rgb(220, 220, 220)',
      }}
    >
      <div className="my-auto">
        {chooseMultilanguageValue(
          text,
          menuState.language as LanguagesSpace,
          menuState.language as LanguagesSpace
        )}
      </div>
    </Button>
  );
}

export default MenuOptionButton;

import { experimental_extendTheme as extendTheme } from '@mui/material/styles';

export const extendedTheme = extendTheme({
  shape: {
    borderRadius: 5,
  },
  components: {
    MuiOutlinedInput: {
      defaultProps: {
        notched: false,
      },
    },

    MuiSwitch: {
      styleOverrides: {
        track: {
          // Controls default (unchecked) color for the track
          opacity: 0.2,
          backgroundColor: 'rgb(100, 100, 100)',
          '$checked$checked + &': {
            // Controls checked color for the track
            opacity: 0.7,
            backgroundColor: 'lightgrey',
          },
        },
      },
    },
  },
  colorSchemes: {
    light: {
      palette: {
        primary: {
          main: '#000000', // Black for primary
        },
        secondary: {
          main: '#FFB800', // Dark gray for secondary
        },

        warning: {
          main: '#FFA500', // Very light gray for warning (preserving the lightness of the original yellow)
        },
        error: {
          main: '#FF1744',
        },
        info: {
          main: '#808080', // Medium gray for info
        },
        background: {
          default: '#f0f0f0', // White for background
        },
        text: {
          primary: '#000000', // Black for text
        },
      },
      //   {
      //     primary: {
      //       main: '#FFA500', // a custom primary orange color
      //     },
      //     secondary: {
      //       main: '#FFD580', // a lighter, custom secondary orange color
      //     },
      //     success: {
      //       main: '#C0F9D3', // success color remains the same
      //     },
      //     warning: {
      //       main: '#FFE898', // warning color remains the same
      //     },
      //     error: {
      //       main: '#ED4747', // error color remains the same
      //     },
      //     info: {
      //       main: '#FFCC99', // a lighter orange for info
      //     },
      //     background: {
      //       default: '#FFF4E0', // a very light orange for background
      //     },
      //     text: {
      //       primary: '#000000', // text color remains the same
      //     },
      //   },
    },
  },
});

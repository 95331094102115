import React from 'react';
import { Box, IconButton, Modal as ModalMUI, ModalProps } from '@mui/material';
import { styled } from '@mui/material';
import { Card } from '../Card/Card';
import { Close } from '@mui/icons-material';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  p: { xs: 3, sm: 3, md: 4, lg: 5, xl: 5 },
  width: '40rem',
  maxHeight: '90vh',
  overflow: 'auto',
  maxWidth: '90%',
};

type CustomModalProps = {};

export const Modal: React.FC<CustomModalProps & ModalProps> = ({
  ...props
}) => {
  return (
    <ModalMUI {...props}>
      <>
        <Box>
          <Card sx={{ ...style }}>
            <div
              style={{
                position: 'relative',
              }}
            >
              <IconButton
                aria-label="close"
                onClick={props.onClose as any}
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  zIndex: 99,
                  //  transform: 'translate(40%, -40%)',
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <Close />
              </IconButton>
              {props.children}
            </div>
          </Card>
        </Box>
      </>
    </ModalMUI>
  );
};

import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  FormControl,
  InputLabel,
  InputAdornment,
  Input,
  Select,
  MenuItem,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  AsYouType,
  getCountryCallingCode,
  parseIncompletePhoneNumber,
} from 'libphonenumber-js';

import CountryCodeMenuItem from './CountryCodeMenuItem';
import { TextInput } from '../TextInput/TextInput';

const useLabelStyles = makeStyles({
  root: {
    transform: 'translate(60%, 24px)',
    '&.hasValue': {
      transform: 'translate(0, 2px) scale(0.75)',
    },
  },
  focused: {
    transform: 'translate(0, 2px) scale(0.75)',
  },
});

const useMenuItemStyles = makeStyles({
  menuItem: {
    '& .flag::after': {
      content: 'attr(data-country-code)',
    },
    '&': {
      padding: '1.2rem',
      width: '6rem',
    },
  },
  selected: {
    '& .flag::after': {
      content: "''",
    },
  },
});

const asYouType = new AsYouType('US');

interface PhoneInputProps {
  value: string;
  label: string;
  selectedCountryOverride?: string;
  countryOptions: any;
  onChange: Function;
}

const PhoneInput = ({
  value,
  label,
  selectedCountryOverride = 'US', // init selectedCountry state
  countryOptions,
  onChange,
}: PhoneInputProps) => {
  const [selectedCountry, setSelectedCountry] = useState<string>(
    selectedCountryOverride
  );
  const [initialized, setInitialized] = useState(false);
  const [cursor, setCursor] = useState(null);
  const phoneRef: any = useRef();
  const labelClasses = useLabelStyles();
  const menuItemClasses = useMenuItemStyles();

  const inputPhone = useCallback((phoneNumber: string) => {
    try {
      asYouType.reset();
      return asYouType.input(phoneNumber);
    } catch (e) {
      console.log(e);
    }
  }, []);

  let val = inputPhone(value);

  if (asYouType?.getNumber?.()?.countryCallingCode === '1') {
    val = inputPhone(asYouType.getNationalNumber());
  }

  useEffect(() => {
    if (value !== '' && initialized == false) {
      phoneNumberChanged({
        target: {
          value: value,
        },
      });

      console.log(value);
      setInitialized(true);
    }
  }, [value]);

  const selectionStart = phoneRef?.current?.selectionStart;
  const selectionEnd = phoneRef?.current?.selectionStart;

  //   useEffect(() => {
  //     //reset the cursor position for input
  //     if (
  //       (selectionStart && selectionEnd) ||
  //       (selectionStart === 0 && selectionEnd === 0)
  //     ) {
  //       phoneRef.current.selectionStart = cursor;
  //       phoneRef.current.selectionEnd = cursor;
  //     }
  //   }, [cursor, selectionStart, selectionEnd]);

  const getParsedPhoneNumber = useCallback((any: any) => {
    let parsed;

    if (asYouType && asYouType.getNumber) {
      parsed = asYouType.getNumber();
    }
    return parsed;
  }, []);

  const getNewPhone = useCallback(
    (phone: string, newCountryCode: string) => {
      let newPhone = phone;
      const parsedPhone = getParsedPhoneNumber(value);

      if ((!parsedPhone || newPhone.trim() === '') && newCountryCode !== '1') {
        return `+${newCountryCode}`;
      } else if (!parsedPhone) {
        return newPhone;
      }

      newPhone =
        newCountryCode === '1'
          ? parsedPhone.nationalNumber
          : `+${newCountryCode}${parsedPhone.nationalNumber}`;

      return newPhone;
    },
    [getParsedPhoneNumber, value]
  );

  const countryCodeChanged = useCallback(
    (ev: any) => {
      const newCountry = ev.target.value;
      const code = getCountryCallingCode(newCountry);
      const newPhone = getNewPhone(value, code);

      if (newPhone !== value) {
        onChange(newPhone);
      }
      setSelectedCountry(newCountry);
    },
    [value, onChange, getNewPhone]
  );

  const phoneNumberChanged = useCallback(
    (ev: any) => {
      setCursor(ev.target.selectionStart);
      const newPhone = parseIncompletePhoneNumber(
        ev.target.value !== undefined ? ev.target.value : ''
      );
      inputPhone(newPhone);
      const parsedPhone = getParsedPhoneNumber(value);

      onChange(
        parseIncompletePhoneNumber(
          ev.target.value !== undefined ? ev.target.value : ''
        )
      );

      if (parsedPhone) {
        let newCountryCode = parsedPhone.country as string;
        if (parsedPhone.countryCallingCode === '44') {
          newCountryCode = 'GB';
        } else if (parsedPhone.countryCallingCode === '1') {
          newCountryCode = 'US';
        }
        setSelectedCountry(newCountryCode);
      }
    },
    [onChange, inputPhone, getParsedPhoneNumber, value]
  );

  const menuItems = Object.keys(countryOptions).map((k: any) => {
    const countryData = {
      ...countryOptions[k],
      countryAbrv: k,
    };

    return (
      <MenuItem value={k} key={k} className={menuItemClasses.menuItem}>
        <CountryCodeMenuItem {...countryData} />
      </MenuItem>
    );
  });

  return (
    <FormControl fullWidth>
      <TextInput
        id="phoneText"
        value={val}
        onChange={phoneNumberChanged}
        type="tel"
        label={label}
        sx={{
          '#phoneText': {
            paddingLeft: '0',
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Select
                fullWidth
                size="small"
                sx={{
                  '&.MuiOutlinedInput-root': {
                    border: 'none !important',
                    margin: '0',
                    padding: '0',
                    width: '5rem',

                    '&:active': {
                      border: 'none',
                    },

                    '&:focused': {
                      border: 'none',
                    },
                  },
                  '& .MuiSelect-select': {
                    padding: '0',
                    border: 'none !important',
                  },

                  '& .MuiSelect-select:focused': {
                    padding: '0',
                    border: 'none',
                  },

                  '& .MuiSelect-select:active': {
                    padding: '0',
                    border: 'none',
                  },
                }}
                disableUnderline={true}
                value={selectedCountry}
                onChange={countryCodeChanged}
              >
                {menuItems}
              </Select>
            </InputAdornment>
          ),
        }}
        inputProps={{
          ref: phoneRef,
        }}
      />
    </FormControl>
  );
};

export default PhoneInput;

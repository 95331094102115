import React, { useState } from 'react';
import {
  SwipeableDrawer,
  Button,
  Box,
  Typography,
  Divider,
  Snackbar,
  Alert,
} from '@mui/material';
import MapComponent from './Admin/MapComponent/MapComponent';
import { adminAtom } from '../state/adminAtom';
import { useRecoilState } from 'recoil';
import { menuAtom } from '../state/menuAtom';
import WifiRoundedIcon from '@mui/icons-material/WifiRounded';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import MyLocationRoundedIcon from '@mui/icons-material/MyLocationRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import { useTranslation } from 'react-i18next';

//@ts-ignore
import LocationPicker from 'react-location-picker';

interface BottomPanel {
  toggleDrawer: (newOpen: boolean) => void;
  open: boolean;
}

const BottomPanel = (props: BottomPanel) => {
  const { t } = useTranslation();

  const [menuState, setMenuState] = useRecoilState(menuAtom);
  const [snackOpen, setSnackOpen] = useState(false);

  const handleClick = () => {
    setSnackOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  };

  const handleCopyPassword = async () => {
    setSnackOpen(true);
  };

  return (
    <div>
      <SwipeableDrawer
        anchor="bottom"
        open={props.open}
        onClose={() => {
          props.toggleDrawer(false);
        }}
        onOpen={() => {
          props.toggleDrawer(true);
        }}
      >
        <Box display={'flex'} justifyContent={'center'} mt={2}>
          <Box
            sx={{
              width: '50px',
              height: '5px',
              backgroundColor: 'rgb(220, 220, 220)',
              borderRadius: '5px',
            }}
          ></Box>
        </Box>
        <Box
          mt={1}
          sx={{
            width: 'auto',
            height: '70vh',
            padding: 2,
            overflow: 'auto',
          }}
          role="presentation"
          onClick={() => {
            props.toggleDrawer(false);
          }}
          onKeyDown={() => {
            props.toggleDrawer(false);
          }}
        >
          <LocationPicker
            containerElement={<div />}
            mapElement={
              <Box
                sx={{
                  width: {
                    xs: '100%',
                    sm: '100%',
                    md: '100%',
                    lg: '100%',
                    xl: '100%',
                  },
                }}
                style={{
                  height: '250px',
                  borderRadius: '5px',
                }}
              />
            }
            defaultPosition={{
              lat: menuState.businessData?.businessAddress.lat,
              lng: menuState.businessData?.businessAddress.lng,
            }}
          />

          <Typography fontSize={'20px'} fontWeight={600} mt={2}>
            {menuState.businessData?.businessName}
          </Typography>

          <Divider
            sx={{
              marginTop: '16px',
            }}
          />

          <Box
            height={'70px'}
            display={'flex'}
            justifyContent={'space-between'}
            py={2}
          >
            <Box display={'flex'}>
              <MyLocationRoundedIcon
                sx={{
                  marginTop: 'auto',
                  marginBottom: 'auto',
                }}
              />

              <Typography ml={1} my={'auto'}>
                {menuState.businessData?.businessAddress.name}
              </Typography>
            </Box>
          </Box>

          <Divider />

          <Box
            height={'70px'}
            display={'flex'}
            justifyContent={'space-between'}
            py={2}
          >
            <Box display={'flex'}>
              <WifiRoundedIcon
                sx={{
                  marginTop: 'auto',
                  marginBottom: 'auto',
                }}
              />

              <Typography ml={1} my={'auto'}>
                {menuState.businessData?.additionalDetails!.wifi.name}
              </Typography>
            </Box>

            <Button
              onClick={handleCopyPassword}
              sx={{
                marginTop: 'auto',
                marginBottom: 'auto',
              }}
            >
              <ContentCopyRoundedIcon />
            </Button>
          </Box>

          <Divider />

          <Box
            height={'70px'}
            display={'flex'}
            justifyContent={'space-between'}
            py={2}
          >
            <Box display={'flex'}>
              <LocalPhoneRoundedIcon
                sx={{
                  marginTop: 'auto',
                  marginBottom: 'auto',
                }}
              />

              <Typography ml={1} my={'auto'}>
                {menuState.businessData?.phone}
              </Typography>
            </Box>
          </Box>

          <Divider />
        </Box>
      </SwipeableDrawer>

      <Snackbar open={snackOpen} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
          {t('passwordCopied')}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default BottomPanel;

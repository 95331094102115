import { Box, Button, Fab, IconButton, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { adminAtom } from '../../../../../state/adminAtom';
import { TabsContainer } from '../../../../../reusableComponents/Tabs/Tabs';
import { BusinessData } from '../../../../../models/business';
import { LanguageGeneric, Languages } from '../../../../../models/languages';
import { ArrowBack, Clear, Create, Star, Upload } from '@mui/icons-material';
import { useBlocker, useNavigate } from 'react-router-dom';
import { PDFContainer } from '../../../Modals/PDFVisualizer/PDFContainer';
import {
  checkIfNullMultilanguageValue,
  chooseMultilanguageValue,
  distributeValue,
  getMultilanguageValue,
  setMultilanguageValue,
} from '../../../../../services/multiLanguague';
import { Dropzone } from '../../../../../reusableComponents/Dropzone/Dropzone';
import classNames from 'classnames';
import styles from './Menu.module.css';
import { Dialog } from '../../../../../reusableComponents/Dialog/Dialog';
import { dialogAtom } from '../../../../../state/dialogAtom';
import { useTranslation } from 'react-i18next';
import { UpdateMenuName } from '../../../Modals/UpdateMenuName/UpdateMenuName';
import { MenuCreate } from '../../../../../models/menu';

interface MenuPDFProps {}

export function MenuPDF(props: MenuPDFProps) {
  const { t } = useTranslation();
  const [adminState, setAdminState] = useRecoilState(adminAtom);
  const [dialogState, setDialogState] = useRecoilState(dialogAtom);
  const [updateMenu, setUpdateMenu] = useState(false);
  const [pdf, setPdf] = useState<LanguageGeneric<File | null>>(
    distributeValue(null)
  );
  const [updatePdf, setUpdatePdf] = useState<LanguageGeneric<boolean>>(
    distributeValue(false)
  );

  const [editPdf, setEditPdf] = useState<LanguageGeneric<boolean>>(
    distributeValue(false)
  );

  const [indexes, setIndexes] = useState(
    Object.keys((adminState.businessData as BusinessData).languages)

      .filter(
        (key) =>
          adminState.businessData?.languages[key as keyof Languages].state ===
          true
      )
      .sort()
      .map((key, i) => ({ lang: key, i }))
  );

  const navigate = useNavigate();

  let blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return (
      adminState.toUpdate === true &&
      currentLocation.pathname !== nextLocation.pathname
    );
  });

  useEffect(() => {
    return () => {
      blocker?.proceed?.();
    };
  }, []);

  useEffect(() => {
    setAdminState((state) => ({
      ...state,
      menuData: {
        ...state.menuData,
        pdfs: pdf,
      } as any,
    }));
  }, [pdf]);

  useEffect(() => {
    if (adminState.toUpdate == false) {
      setUpdatePdf(distributeValue(false));

      if (checkIfNullMultilanguageValue(pdf) === false) {
        let menuData = JSON.parse(JSON.stringify(adminState.menuData));

        Object.keys(pdf).forEach((key) => {
          if (pdf[key as keyof Languages] !== null) {
            menuData.sourceUrl[key] = URL.createObjectURL(
              pdf[key as keyof Languages] as Blob
            );
          }
        });

        setPdf(distributeValue(null));
      }
    }
  }, [adminState.toUpdate]);

  return (
    <Box
      sx={{
        pt: '64px',
      }}
    >
      {updateMenu ? (
        <UpdateMenuName
          close={() => {
            setUpdateMenu(false);
          }}
          forwardUpdate={(obj) => {
            setAdminState((state) => ({
              ...state,
              menuData: {
                ...state.menuData,
                ...obj,
              } as MenuCreate,
              toUpdate: true,
            }));
          }}
          reload={() => {}}
        />
      ) : null}
      <Dialog
        {...dialogState}
        open={blocker.state === 'blocked'}
        onConfirm={() => {
          blocker.proceed?.();
        }}
        cancel={() => {
          blocker.reset?.();
        }}
        title={t('unsaved_changes')}
        sentiment="warning"
      >
        <Box>{t('sure_you_want_to_go_back_without_saving')}</Box>
      </Dialog>
      <Button
        variant="text"
        startIcon={<ArrowBack />}
        onClick={() => {
          navigate('/admin/panel/menu');
        }}
      >
        {t('back')}
      </Button>
      <Typography textAlign={'center'} variant="h4" mb={3} fontWeight={'bold'}>
        {chooseMultilanguageValue(
          adminState.menuData?.name as LanguageGeneric<string>,
          adminState.language as keyof Languages,
          adminState.language as keyof Languages
        )}

        <IconButton
          sx={{ ml: 2 }}
          disableRipple
          onClick={() => {
            setUpdateMenu(true);
          }}
        >
          <Create />
        </IconButton>
      </Typography>

      <TabsContainer
        muiProps={{
          sx: {
            mt: 2,
          },
        }}
        defaultValue={
          indexes.filter(
            (v) => v.lang === adminState.businessData?.defaultLanguage
          )[0].i
        }
        tabs={Object.keys((adminState.businessData as BusinessData).languages)

          .filter(
            (key) =>
              adminState.businessData?.languages[key as keyof Languages]
                .state === true
          )
          .sort()
          .map((key: string, i: number) => ({
            label:
              adminState.businessData?.languages[key as keyof Languages].name,
            key: i,
            icon:
              key === adminState.businessData?.defaultLanguage ? (
                <Star />
              ) : undefined,
          }))}
      >
        {Object.keys((adminState.businessData as BusinessData).languages)
          .filter(
            (key) =>
              adminState.businessData?.languages[key as keyof Languages]
                .state === true
          )
          .sort()
          .map((key: string, i: number) => {
            return (
              <TabsContainer.TabPanel key={i}>
                {/* <Typography textAlign={'center'}>
                  {
                    (adminState.businessData as BusinessData).languages[
                      key as keyof Languages
                    ].name
                  }
                </Typography> */}

                <Box
                  sx={
                    {
                      //   height: '100%',
                      // mt: 2,
                    }
                  }
                >
                  {(getMultilanguageValue(
                    adminState.menuData?.sourceUrl as LanguageGeneric<string>,
                    key as keyof Languages
                  ) !== '' ||
                    getMultilanguageValue(pdf, key as keyof Languages) !==
                      null) &&
                  getMultilanguageValue(editPdf, key as keyof Languages) ==
                    false ? (
                    <PDFContainer
                      url={
                        getMultilanguageValue(
                          adminState.menuData
                            ?.sourceUrl as LanguageGeneric<string>,
                          key as keyof Languages
                        ) !== ''
                          ? getMultilanguageValue(
                              updatePdf,
                              key as keyof Languages
                            )
                            ? URL.createObjectURL(
                                getMultilanguageValue(
                                  pdf,
                                  key as keyof Languages
                                ) as Blob
                              )
                            : getMultilanguageValue(
                                adminState.menuData
                                  ?.sourceUrl as LanguageGeneric<string>,
                                key as keyof Languages
                              )
                          : URL.createObjectURL(
                              getMultilanguageValue(
                                pdf,
                                key as keyof Languages
                              ) as Blob
                            )
                      }
                    />
                  ) : (
                    <Box>
                      <Dropzone
                        acceptedFiles={['application/pdf']}
                        showPreviewsInDropzone={true}
                        dropzoneClass={classNames(styles['dropzone'])}
                        dropzoneText={t('drag_and_drop')}
                        onChange={(f) => {
                          if (f.length > 0) {
                            setMultilanguageValue(
                              f[0],
                              setPdf,
                              key as keyof Languages
                            );

                            if (
                              getMultilanguageValue(
                                adminState.menuData
                                  ?.sourceUrl as LanguageGeneric<string>,
                                key as keyof Languages
                              ) !== ''
                            ) {
                              setMultilanguageValue(
                                true,
                                setUpdatePdf,
                                key as keyof Languages
                              );
                            }
                            setMultilanguageValue(
                              false,
                              setEditPdf,
                              key as keyof Languages
                            );

                            setAdminState((state) => ({
                              ...state,
                              toUpdate: true,
                              menuData: {
                                ...state.menuData,
                                pdfs: pdf,
                              } as any,
                            }));
                          }
                        }}
                        filesLimit={1}
                        showPreviews={false}
                      />
                    </Box>
                  )}
                </Box>

                {
                  getMultilanguageValue(
                    adminState.menuData?.sourceUrl as LanguageGeneric<string>,
                    key as keyof Languages
                  ) !== '' ||
                  getMultilanguageValue(pdf, key as keyof Languages) !==
                    null ? (
                    getMultilanguageValue(editPdf, key as keyof Languages) ==
                    true ? (
                      <Box
                        sx={{
                          position: 'fixed',
                          bottom: 20,
                          left: '50%',
                          transform: 'translate(-50%,0)',
                        }}
                      >
                        <Fab
                          variant="extended"
                          color="primary"
                          onClick={(e) => {
                            setMultilanguageValue(
                              false,
                              setEditPdf,
                              key as keyof Languages
                            );
                          }}
                        >
                          <Clear sx={{ mr: 1 }} />
                          {t('cancel')}
                        </Fab>
                      </Box>
                    ) : (
                      <Stack
                        sx={{
                          position: 'fixed',
                          bottom: 20,
                          left: '50%',
                          transform: 'translate(-50%,0)',
                        }}
                        direction={'row'}
                        spacing={1}
                      >
                        <Fab
                          variant="extended"
                          color="primary"
                          onClick={(e) => {
                            setMultilanguageValue(
                              true,
                              setEditPdf,
                              key as keyof Languages
                            );
                          }}
                        >
                          <Create sx={{ mr: 1 }} />
                          {t('change_document')}
                        </Fab>

                        {getMultilanguageValue(
                          updatePdf,
                          key as keyof Languages
                        ) ? (
                          <Fab
                            variant="extended"
                            color="secondary"
                            onClick={(e) => {
                              setMultilanguageValue(
                                false,
                                setUpdatePdf,
                                key as keyof Languages
                              );
                            }}
                          >
                            <Clear sx={{ mr: 1 }} />
                            {t('cancel')}
                          </Fab>
                        ) : null}
                      </Stack>
                    )
                  ) : null
                  //   <Fab
                  //     variant="extended"
                  //     color="primary"
                  //     sx={{
                  //       position: 'fixed',
                  //       bottom: 20,
                  //       left: '50%',
                  //       transform: 'translate(-50%,0)',
                  //     }}
                  //   >
                  //     <Upload sx={{ mr: 1 }} />
                  //     Upload
                  //   </Fab>
                }
              </TabsContainer.TabPanel>
            );
          })}
      </TabsContainer>
    </Box>
  );
}

import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { loadingAtom } from '../../../../state/loading';
import { checkoutSessionFeedbackApi } from '../../../../api/pricing';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../reusableComponents/Button/Button';
import useStyles from './styles';
import myteam from '../../../../res/undraw_maker_launch_re_rq81.svg';
import { useTranslation } from 'react-i18next';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const FailedCheckout = () => {
  const { t } = useTranslation();
  const query = useQuery();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useRecoilState(loadingAtom);
  const [data, setData] = useState<null | { customerName: 'string' }>(null);
  const classes = useStyles();

  useEffect(() => {
    setIsLoading(true);

    let sessionId = query.get('session_id');

    if (sessionId) {
      checkoutSessionFeedbackApi(sessionId)
        .then((d) => {
          setIsLoading(false);
          setData(d.data);
        })
        .catch((e) => {
          setIsLoading(false);
          console.log(e);
          navigate('/');
        });
    } else {
      setIsLoading(false);
      navigate('/');
    }
  }, []);

  return (
    <Box>
      {data !== null ? (
        <Box className={classes.heroBox}>
          <Grid container spacing={6} className={classes.gridContainer}>
            <Grid item xs={12} md={7}>
              <Typography
                variant="h3"
                fontWeight={700}
                className={classes.title}
              >
                {t('sorry')} {data.customerName}!
              </Typography>

              <Typography variant="h6" gutterBottom>
                {t('seems_that_something_went_wrong')}
              </Typography>

              <Button
                variant="contained"
                color="primary"
                sx={{ width: '200px', fontSize: '16px', mt: 2 }}
                onClick={() => {
                  navigate('/register');
                }}
              >
                {t('try_again_uppercase')}
              </Button>

              <Typography variant="body1" gutterBottom mt={5}>
                {t('need_help_our_support_team_is_ready_to_assit_you')}
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                href="mailto:alejandro.hernandez@soflopsoftware.com"
                size="small"
              >
                {t('contact_support')}
              </Button>
            </Grid>
            <Grid item xs={12} md={5}>
              <img src={myteam} alt="My Team" className={classes.largeImage} />
            </Grid>
          </Grid>
        </Box>
      ) : null}
    </Box>
  );
};

import React, { useEffect, useState } from 'react';
import { TabsContainer } from '../../../../../reusableComponents/Tabs/Tabs';
import { useRecoilState } from 'recoil';
import { adminAtom } from '../../../../../state/adminAtom';
import { Add, ArrowBack, Create, Edit, MoreHoriz } from '@mui/icons-material';
import {
  Box,
  Divider,
  Fab,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import DraggableList from '../../../../../reusableComponents/Draggable/DraggableList';
import { ProductEditable } from '../../../Pieces/ProductEditable/ProductEditable';
import { loadingAtom } from '../../../../../state/loading';
import firebase from '../../../../../services/firebase';
import { toast } from 'react-toastify';
import { dialogAtom } from '../../../../../state/dialogAtom';
import { DropResult } from '@hello-pangea/dnd';
import { ProductBase } from '../../../../../models/product';
import { reorder } from '../../../../../reusableComponents/Draggable/helpers';
import { Button } from '../../../../../reusableComponents/Button/Button';
import { LanguageGeneric, Languages } from '../../../../../models/languages';
import { unstable_usePrompt, useBlocker, useNavigate } from 'react-router-dom';
import { MenuCreate } from '../../../../../models/menu';
import { CategoryItem } from '../CategoryItem/CategoryItem';
import type {
  unstable_Blocker as Blocker,
  unstable_BlockerFunction as BlockerFunction,
} from 'react-router-dom';
import { Dialog } from '../../../../../reusableComponents/Dialog/Dialog';
import { chooseMultilanguageValue } from '../../../../../services/multiLanguague';
import { useTranslation } from 'react-i18next';
import { AddMenu } from '../../../Modals/AddMenu/AddMenu';
import { UpdateMenuName } from '../../../Modals/UpdateMenuName/UpdateMenuName';

export const MenuNative = () => {
  const { t } = useTranslation();
  const [adminState, setAdminState] = useRecoilState(adminAtom);
  const [isLoading, setIsLoading] = useRecoilState(loadingAtom);
  const [dialogState, setDialogState] = useRecoilState(dialogAtom);

  const [products, setProducts] = useState<any[]>([]);
  const [updateMenu, setUpdateMenu] = useState(false);
  const navigate = useNavigate();

  let shouldBlock = React.useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => {
      return (
        adminState.toUpdate === true &&
        currentLocation.pathname !== nextLocation.pathname
      );
    },
    [adminState.toUpdate]
  );
  let blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return (
      adminState.toUpdate === true &&
      currentLocation.pathname !== nextLocation.pathname
    );
  });

  let _listener:
    | firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
    | undefined
    | (() => void) = undefined;

  let _listenProducts: undefined | (() => void) = undefined;

  const onDragEnd = (
    { destination, source }: DropResult,
    productsFiltered: ProductBase[]
  ) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(
      productsFiltered,
      source.index,
      destination.index
    ).map((e, i) => ({ ...e, index: i }));
    const itemsID = newItems.map((e) => e.id);

    let newProducts = [...products].filter(
      (value) => !itemsID.includes(value.id)
    );

    setAdminState((state) => ({
      ...state,
      products: newProducts.concat(newItems),
      productsToUpdate: itemsID,
      toUpdate: true,
    }));

    setProducts(newProducts.concat(newItems));
  };

  useEffect(() => {
    _listener = firebase
      .firestore()
      .collection('business')
      .doc(adminState.businessData?.id)
      .collection('menus')
      .doc(adminState.menuData?.id as string)
      .onSnapshot(
        (doc) => {
          let data = {
            ...doc.data(),
            id: doc.id,
          } as MenuCreate & { id?: string };
          setAdminState((state) => ({
            ...state,
            menuData: data,
          }));
        },
        (e) => {
          console.log(e);
        }
      );

    _listenProducts = firebase
      .firestore()
      .collection('business')
      .doc(adminState.businessData?.id)
      .collection('menus')
      .doc(adminState.menuData?.id as string)
      .collection('products')
      .orderBy('index')
      .onSnapshot(
        (snap) => {
          let arr: any[] = [];

          snap.forEach((doc) => {
            let data = doc.data();
            data.id = doc.id;

            arr.push(data);
          });

          setProducts(arr);
        },
        (e) => {
          console.log(e);
        }
      );

    return () => {
      if (_listener !== undefined) {
        if (typeof _listener == 'function') {
          _listener(); //Clean up the listener
        }
      }

      if (_listenProducts !== undefined) {
        if (typeof _listenProducts == 'function') {
          _listenProducts(); //Clean up the listener
        }
      }
    };
  }, []);

  return (
    <Box
      sx={{
        pt: '64px',
      }}
    >
      {updateMenu ? (
        <UpdateMenuName
          close={() => {
            setUpdateMenu(false);
          }}
          forwardUpdate={(obj) => {
            setAdminState((state) => ({
              ...state,
              menuData: {
                ...state.menuData,
                ...obj,
              } as MenuCreate,
              toUpdate: true,
            }));
          }}
          reload={() => {}}
        />
      ) : null}
      <Dialog
        {...dialogState}
        open={blocker.state === 'blocked'}
        onConfirm={() => {
          blocker.proceed?.();
        }}
        cancel={() => {
          blocker.reset?.();
        }}
        title="Unsaved changes"
        sentiment="warning"
      >
        <Box>{t('sure_you_want_to_go_back_without_saving')}</Box>
      </Dialog>
      <Button
        variant="text"
        startIcon={<ArrowBack />}
        onClick={() => {
          navigate('/admin/panel/menu');
        }}
      >
        {t('back')}
      </Button>
      <Typography textAlign={'center'} variant="h4" mb={3} fontWeight={'bold'}>
        {chooseMultilanguageValue(
          adminState.menuData?.name as LanguageGeneric<string>,
          adminState.language as keyof Languages,
          adminState.language as keyof Languages
        )}

        <IconButton
          sx={{ ml: 2 }}
          disableRipple
          onClick={() => {
            setUpdateMenu(true);
          }}
        >
          <Create />
        </IconButton>
      </Typography>

      <Box
        sx={{
          boxSizing: 'border-box',
        }}
      >
        <TabsContainer
          editableTabs
          tabs={adminState.menuData?.sections
            ?.map((e: any, i: number) => {
              return {
                label:
                  e[adminState.language] !== ''
                    ? e[adminState.language]
                    : e[
                        Object.keys(e).filter(
                          (key: any) => e[key] !== '' && key !== 'id'
                        )[0]
                      ],
                key: i,
                onEdit: () => {
                  setAdminState((state) => ({
                    ...state,
                    sectionData: e,
                    addSection: true,
                  }));
                },
              } as any;
            })
            .concat([
              {
                label: t('add'),
                key: 'add-section',
                icon: <Add />,
                onClick: () => {
                  setAdminState((state) => ({ ...state, addSection: true }));
                },
              },
            ])}
        >
          {adminState.menuData?.sections?.map((e: any, i: number) => (
            <TabsContainer.TabPanel key={i}>
              <Stack spacing={4}>
                {adminState.menuData?.categories
                  ?.filter((value) => value.linkedSection === e.id)
                  .map((el, catIndex) => (
                    <Stack spacing={2}>
                      <CategoryItem
                        edit={() => {
                          setAdminState((state) => ({
                            ...state,
                            categoryData: el,
                            addCategory: true,
                          }));
                        }}
                        moveDownward={
                          // prettier-ignore
                          catIndex <
                          ((adminState.menuData?.categories?.filter((value) => value.linkedSection === e.id).length as number) - 1)
                            ? () => {
                              let categoriesReordered = reorder([...adminState.menuData?.categories?.filter((value) => value.linkedSection === e.id) as any[]], catIndex, catIndex + 1)
                              let completeCategories = [
                                ...(adminState.menuData?.categories as any[]),
                              ].filter((value) => value.linkedSection !== e.id).concat(categoriesReordered)

                              setAdminState(state => ({
                                ...state,
                                menuData:{
                                    ...state.menuData,
                                    categories:completeCategories
                                } as MenuCreate,
                                toUpdate:true
                              }))
                            }
                            : undefined
                        }
                        moveUpward={
                          catIndex > 0
                            ? () => {
                                let categoriesReordered = reorder(
                                  [
                                    ...(adminState.menuData?.categories?.filter(
                                      (value) => value.linkedSection === e.id
                                    ) as any[]),
                                  ],
                                  catIndex,
                                  catIndex - 1
                                );
                                let completeCategories = [
                                  ...(adminState.menuData?.categories as any[]),
                                ]
                                  .filter(
                                    (value) => value.linkedSection !== e.id
                                  )
                                  .concat(categoriesReordered);

                                setAdminState((state) => ({
                                  ...state,
                                  menuData: {
                                    ...state.menuData,
                                    categories: completeCategories,
                                  } as MenuCreate,
                                  toUpdate: true,
                                }));
                              }
                            : undefined
                        }
                        categoryName={
                          el[adminState.language as keyof Languages] !== ''
                            ? el[adminState.language as keyof Languages]
                            : el[
                                Object.keys(el).filter(
                                  (key: any) =>
                                    el[key as keyof Languages] !== '' &&
                                    key !== 'id' &&
                                    key !== 'linkedSection'
                                )[0] as keyof Languages
                              ]
                        }
                        key={el.id}
                      />

                      {true !== true ? (
                        <Stack
                          direction={'row'}
                          justifyContent={'space-between'}
                          alignItems={'center'}
                          spacing={1}
                        >
                          <Typography variant="h5">
                            {el[adminState.language as keyof Languages] !== ''
                              ? el[adminState.language as keyof Languages]
                              : el[
                                  Object.keys(el).filter(
                                    (key: any) =>
                                      el[key as keyof Languages] !== '' &&
                                      key !== 'id' &&
                                      key !== 'linkedSection'
                                  )[0] as keyof Languages
                                ]}
                          </Typography>
                          <IconButton
                            onClick={() => {
                              setAdminState((state) => ({
                                ...state,
                                categoryData: el,
                                addCategory: true,
                              }));
                            }}
                          >
                            <MoreHoriz />
                          </IconButton>
                        </Stack>
                      ) : null}

                      {
                        <DraggableList
                          id={el.id}
                          items={products
                            .filter(
                              (value: any) =>
                                value.section === e.id &&
                                value.category === el.id
                            )
                            .map((e: any, i) => {
                              return {
                                index: i,
                                id: e.id,
                                element: (
                                  <Box key={i}>
                                    <ProductEditable
                                      imageUrl={e.imageUrl}
                                      available={e.available}
                                      id={e.id}
                                      live={e.live}
                                      options={e.options}
                                      price={e.price}
                                      productDescription={e.productDescription}
                                      productName={e.productName}
                                      key={i}
                                      setProductData={() => {
                                        setAdminState((state) => ({
                                          ...state,
                                          productData: e,
                                          addProduct: true,
                                        }));
                                      }}
                                      handleLiveChange={(d) => {
                                        let base: any[] = [
                                          ...adminState.products.map((e) =>
                                            JSON.parse(JSON.stringify(e))
                                          ),
                                        ];
                                        base[i].live = d;

                                        setAdminState((state) => ({
                                          ...state,
                                          products: base,
                                        }));
                                        setIsLoading(true);
                                        firebase
                                          .firestore()
                                          .collection('products')
                                          .doc(e.id)
                                          .update({
                                            live: d,
                                            updated: new Date(),
                                          })
                                          .then(() => {
                                            setIsLoading(false);
                                            toast.info(t('live_state_changed'));
                                          })
                                          .catch((e) => {
                                            console.log(e);
                                            setIsLoading(false);
                                            toast.error(
                                              t('something_went_wrong')
                                            );
                                            let base: any[] = [
                                              ...adminState.products.map((e) =>
                                                JSON.parse(JSON.stringify(e))
                                              ),
                                            ];
                                            base[i].live = !d;

                                            setAdminState((state) => ({
                                              ...state,
                                              products: base,
                                            }));
                                          });
                                      }}
                                      handleAvailableChange={(d) => {
                                        setIsLoading(true);
                                        let base: any[] = [
                                          ...products.map((e) =>
                                            JSON.parse(JSON.stringify(e))
                                          ),
                                        ];
                                        base[i].available = d;

                                        setProducts(base);

                                        firebase
                                          .firestore()
                                          .collection('business')
                                          .doc(adminState.businessData?.id)
                                          .collection('menus')
                                          .doc(adminState.menuData?.id)
                                          .collection('products')
                                          .doc(e.id)
                                          .update({
                                            available: d,
                                            updated: new Date(),
                                          })
                                          .then(() => {
                                            setIsLoading(false);
                                            toast.info(
                                              t('availability_changed')
                                            );
                                          })
                                          .catch((e) => {
                                            console.log(e);
                                            toast.error(
                                              t('something_went_wrong')
                                            );
                                            let base: any[] = [
                                              ...adminState.products,
                                            ];
                                            base[i].available = !d;

                                            setAdminState((state) => ({
                                              ...state,
                                              products: base,
                                            }));
                                            setIsLoading(false);
                                          });
                                      }}
                                      handleDelete={() => {
                                        console.log(e);
                                        setDialogState({
                                          open: true,
                                          onConfirm: () => {
                                            setIsLoading(true);

                                            try {
                                              if (e.imageUrl !== '') {
                                                try {
                                                  firebase
                                                    .storage()
                                                    .refFromURL(e.imageUrl)
                                                    .delete();
                                                } catch (e) {
                                                  console.log(e);
                                                  toast.error(
                                                    t('something_went_wrong')
                                                  );
                                                }
                                              }

                                              let db = firebase.firestore();

                                              var sfDocRef = db
                                                .collection('business')
                                                .doc(
                                                  `${adminState.businessData?.id}`
                                                );

                                              // Uncomment to initialize the doc.
                                              // sfDocRef.set({ population: 0 });

                                              return db
                                                .runTransaction(
                                                  (transaction) => {
                                                    // This code may get re-run multiple times if there are conflicts.
                                                    return transaction
                                                      .get(sfDocRef)
                                                      .then((sfDoc) => {
                                                        if (!sfDoc.exists) {
                                                          toast.error(
                                                            t(
                                                              'business_doesnt_exist'
                                                            )
                                                          );
                                                        } else {
                                                          var newItems =
                                                            ((
                                                              sfDoc.data() as any
                                                            ).products ===
                                                            undefined
                                                              ? 1
                                                              : (
                                                                  sfDoc.data() as any
                                                                ).products) - 1;
                                                          transaction.update(
                                                            sfDocRef,
                                                            {
                                                              products:
                                                                newItems,
                                                            }
                                                          );

                                                          transaction.delete(
                                                            db
                                                              .collection(
                                                                'business'
                                                              )
                                                              .doc(
                                                                adminState
                                                                  .businessData
                                                                  ?.id
                                                              )
                                                              .collection(
                                                                'menus'
                                                              )
                                                              .doc(
                                                                adminState
                                                                  .menuData?.id
                                                              )
                                                              .collection(
                                                                'products'
                                                              )
                                                              .doc(e.id)
                                                          );
                                                        }

                                                        // Add one person to the city population.
                                                        // Note: this could be done without a transaction
                                                        //       by updating the population using FieldValue.increment()
                                                      });
                                                  }
                                                )
                                                .then(() => {
                                                  setIsLoading(false);
                                                  toast.info(
                                                    t('product_deleted')
                                                  );

                                                  let base = [
                                                    ...adminState.products,
                                                  ];

                                                  let index = 0;

                                                  for (
                                                    let i = 0;
                                                    i < base.length;
                                                    i++
                                                  ) {
                                                    if (base[i].id === e.id) {
                                                      index = i;
                                                      break;
                                                    }
                                                  }
                                                  base.splice(index, 1);

                                                  setAdminState((state) => ({
                                                    ...state,
                                                    products: base,
                                                  }));
                                                })
                                                .catch((error) => {
                                                  console.log(error);
                                                  setIsLoading(false);

                                                  toast.error(
                                                    t('something_went_wrong')
                                                  );
                                                });
                                            } catch (e) {
                                              console.log(e);
                                              toast.error(
                                                t('something_went_wrong')
                                              );

                                              setIsLoading(false);
                                            }
                                          },
                                          title: t(
                                            'sure_you_want_to_delete_this_product'
                                          ),
                                          sentiment: 'error',
                                          children: <div></div>,
                                          setOpen: (o: boolean) => {
                                            setDialogState((s) => ({
                                              ...s,
                                              open: o,
                                            }));
                                          },
                                        });
                                      }}
                                    />
                                  </Box>
                                ),
                              };
                            })}
                          onDragEnd={(result) =>
                            onDragEnd(
                              result,
                              products.filter(
                                (value: any) =>
                                  value.section === e.id &&
                                  value.category === el.id
                              )
                            )
                          }
                        />
                      }

                      <Box>
                        <Button
                          onClick={() => {
                            setAdminState((state) => ({
                              ...state,
                              automaticCategory: el.id,
                              automaticSection: e.id,
                              addProduct: true,
                            }));
                          }}
                          startIcon={<Add />}
                        >
                          {t('add_product_uppercase')}
                        </Button>
                      </Box>
                    </Stack>
                  ))}

                {
                  <Box mt={3} display={'flex'} justifyContent={'center'}>
                    <Divider
                      sx={{
                        width: '50%',
                      }}
                    >
                      <Button
                        variant="outlined"
                        startIcon={<Add />}
                        onClick={() => {
                          setAdminState((state) => ({
                            ...state,
                            automaticSection: e.id,
                            addCategory: true,
                          }));
                        }}
                      >
                        {t('add_category')}
                      </Button>
                    </Divider>
                  </Box>
                }
              </Stack>
            </TabsContainer.TabPanel>
          ))}
        </TabsContainer>
      </Box>
      <Stack
        sx={{
          position: 'fixed',
          bottom: 20,
          left: '50%',
          transform: 'translate(-50%,0)',
        }}
        direction={'row'}
        spacing={1}
      >
        <Fab
          variant="extended"
          color="primary"
          onClick={(e) => {
            setAdminState((state) => ({ ...state, addSection: true }));
          }}
        >
          <Add sx={{ mr: 1 }} />
          {t('add_section')}
        </Fab>
      </Stack>
    </Box>
  );
};

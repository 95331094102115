import React, { useEffect } from 'react';
import {
  Button as ButtonMUI,
  Dialog as DialogMUI,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import { Button } from '../Button/Button';
import { styled } from '@mui/material';
import { InfoOutlined, Warning, WarningOutlined } from '@mui/icons-material';

export type DialogProps = {
  children: React.ReactNode;
  title: string;
  open: boolean;
  setOpen: Function;
  onConfirm: Function;
  cancel?: Function;
  sentiment?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
};

const CustomDialogStyled = styled(DialogMUI)(({ theme }) => {
  return {
    '.MuiPaper-root': {
      borderRadius: 'var(--border-radius)',
    },
  };
});

export const Dialog = (props: DialogProps) => {
  const { title, children, open, setOpen, onConfirm, sentiment, cancel } =
    props;

  return (
    <CustomDialogStyled
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
      PaperProps={{
        elevation: 0,
      }}
    >
      <DialogTitle id="confirm-dialog">
        <Stack direction={'row'} justifyContent={'center'}>
          {sentiment === 'primary' ? (
            <InfoOutlined color={sentiment} />
          ) : sentiment === 'secondary' ? (
            <InfoOutlined color={sentiment} />
          ) : (
            <WarningOutlined color={sentiment} />
          )}
        </Stack>
        {title}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          color={sentiment}
          variant="text"
          disableElevation
          disableRipple
          onClick={() => {
            setOpen(false);
            if (cancel) {
              cancel();
            }
          }}
        >
          No
        </Button>
        <Button
          variant="contained"
          color={sentiment}
          disableElevation
          disableRipple
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
        >
          Yes
        </Button>
      </DialogActions>
    </CustomDialogStyled>
  );
};
